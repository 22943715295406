import { Routes } from '@angular/router';

import { LoginActivitiesComponent } from './loginactivities/loginactivities.component';
import { LeaveActivitiesComponent } from './leaveactivities/leaveactivities.component';


export const LogsRoutes: Routes = [

    {
        path: '',
        children: [
          {
                path: 'loginactivities',
                component: LoginActivitiesComponent
            }, {
                path: 'leaveactivities',
                component: LeaveActivitiesComponent
            }
        ]
    }
];
