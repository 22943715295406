import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';


import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCalendar } from '@angular/material/datepicker';


import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';



@Component({
    selector: 'app-masterlog',
    templateUrl: 'masterlog.component.html',
    styleUrls: ['masterlog.component.css']
})
export class MasterlogComponent {
    myphoto: any;

    resultsLength = 0;
    bIsEdit: boolean = false;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    displayedColumns: string[] = ['m_StaffID','m_StaffName', 'm_Time', 'm_StaffID_Concern', 'm_ConcernTable', 'm_IP', 'm_Changes'];
    exampleDatabase: ExampleHttpDao | null;
    data: MasterlogRow[] = [];
    isLoadingResults = true;
    globalSearch: string = "";
    response: string = "";
    constructor(
        public dialogRef: MatDialogRef<MasterlogComponent>,
        @Inject(MAT_DIALOG_DATA) public dataDialog: DialogData,
        private httpClient: HttpClient,
        public dialog: MatDialog,
        public http: Http,
         
        public global: GlobalProvider) {
        this.bIsEdit = this.IsEdit();
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.dataDialog.email + "&dummy=" + this.global.iImageRandamize;

    }
    ngOnInit() {
        this.exampleDatabase = new ExampleHttpDao(this.httpClient);
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    }
    ngAfterViewInit() {
        this.global.globalSearch = "";
        this.Merge();
    }
    Merge() {
        this.response = "Please wait...";
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    this.response = "Please wait...";
                    return this.exampleDatabase!.getRepoIssues(
                        this.sort.active, this.sort.direction, this.paginator.pageIndex,
                        this.globalSearch, getCookie('login_profile'),
                        this.dataDialog.staffconcern);
                }),
                map(data => {
                    // Flip flag to show that loading has finished.
                    this.isLoadingResults = false;
                    this.response = "";
                    this.resultsLength = data.total_count;

                    return data.items;
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    return observableOf([]);
                })
            ).subscribe(data => this.data = data);
    }
    GetPhoto() {
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.dataDialog.email + "&dummy=" + this.global.iImageRandamize;//Date.now().toString();
    }

    IsEdit() {
        if (this.IsSA()) return true;
        var menukey = getCookie('menukey');
        if (menukey.indexOf("u4-2") > -1) return true;
        return false;
    }
    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }
    onNoClick(dataOut: DialogData): void {
        this.dialogRef.close(dataOut);
    }
    onYesClick(dataIn: DialogData): void {
        this.dialogRef.close(dataIn);
    }

    ngOnDestroy() {

    }
    ShowChanges(str) {
        if (str.length > 45) {
            Swal.fire({
                title: "...",
                html: str,
                buttonsStyling: false,
            /*confirmButtonClass: "btn btn-info"*/
            });//.catch(swal.noop)
        }
    }
}
export class ExampleHttpDao {
    constructor(private http: HttpClient) { }

    getRepoIssues(sort: string, order: string, page: number, search: string, profile: string, staff_concern:string): Observable<MasterlogResponse> {
        const href = 'http://' + APIDomain + '/accessmanager/GetMaterLog';
        const requestUrl = `${href}?q=q&sort=${sort}&order=${order}&page=${page + 1}&search=${search}&profile=${profile}&staff_concern=${staff_concern}`;
        //console.log(requestUrl);
        return this.http.get<MasterlogResponse>(requestUrl);
    }
}
export interface DialogData {
    pop_Head: string;
    email: string;
    staffid: string;
    staffconcern: string;
}
export interface MasterlogResponse {
    items: MasterlogRow[];
    total_count: number;
}

export interface MasterlogRow {
    m_id: number;
    m_StaffID: string;
    m_StaffName: string;
    m_StaffID_Concern: string;
    m_IP: string;
    m_ConcernTable: string;
    m_Changes: string;
}