
import {distinctUntilChanged, debounceTime,  catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';

import {
    DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette, MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';


import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCalendar } from '@angular/material/datepicker';
import { MatTabChangeEvent } from '@angular/material/tabs';


import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Profile } from 'selenium-webdriver/firefox';


import 'rxjs/Rx';

//___________________________________________________
@Component({
    selector: 'payscale_master.component',
    templateUrl: 'payscale_master.component.html',
    styleUrls: ['payscale_master.component.css'],
    providers: [
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class Payscale_MasterComponent {
    //status: string = "";
    options: string[] = ['One', 'Two', 'Three'];
    myphoto: any;
    selectedFile: File;
    selected_row: string;
    response: string;
    lastAction: string = "";
    sel_id: number = 0;;
    public postResponse: PostResponse;
    timeNow: number;
    force: string = "0";
    newKey: string = "";
    bIsEdit: boolean = true;
    bActiveTab: number = 0;
    public payslipMaster: PayslipMaster;
    deviceChanged: Subject<string> = new Subject<string>();
    deviceChangedSubscription: Subscription = null;
    filteredLedgers: Observable<any[]>;
    selectedStaffItem: PayLedger = new PayLedger("", 0);
    constructor(
        public dialogRef: MatDialogRef<Payscale_MasterComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PayslipData,
        public http: Http,
        public dialog: MatDialog,
         
        private datePipe: DatePipe,
        public global: GlobalProvider) {
        this.timeNow = Date.now();
        this.postResponse = {} as PostResponse;
        this.payslipMaster = {} as PayslipMaster;
        //-------------------------------
        this.bIsEdit = this.IsEdit();
        if (this.bIsEdit && !this.IsSA()) {
            if (!this.data.allowdelete) this.bIsEdit = false;
        }
        //-------------------------------
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.data.email + "&dummy=" + this.global.iImageRandamize;
    }
    ngOnInit() {

        this.PayslipMaster('', '', -1);
        //-------------------------------Auto dropdown
        this.deviceChangedSubscription =
            this.deviceChanged.pipe(
                debounceTime(100), // wait 300ms after the last event before emitting last event
                distinctUntilChanged(),) // only emit if value is different from previous value
                .subscribe(model => {
                    //this.model = model;
                    this.filteredLedgers = this.searchDevices(model, getCookie('login_profile'));
                });
        this.filteredLedgers = this.searchDevices("", getCookie('login_profile'));
        //-------------------------------Auto dropdown END
    }
    GetProfileInfo(key) {
        var val = getCookie(key);
        if (val === null) return "";
        return val;
    }
    IsEdit() {
        if (this.IsSA()) return true;
        var menukey = getCookie('menukey');
        //if (menukey.indexOf("a0-1") > -1) return true;
        if (menukey.indexOf("p1-2") > -1) return true;
        return false;
    }
    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }
    IsAdmin() {
        return getCookie("menukey").indexOf("a0-1") > -1;
    }
    IsSAemail(mail) {
        return getCookie('login_profile') === mail;
    }
    onNoClick(dataOut: PayslipData): void {
        dataOut.action = this.lastAction;
        this.dialogRef.close(dataOut);
    }
    onYesClick(dataIn: PayslipData): void {
        /*
        if (dataIn.pop_Head === 'Shift') {
            if (!this.IsValidTime(dataIn.pop_StartTime)) {
                this.response = "Invalid Start Time (HH:SS)";
                return;
            }
            if (!this.IsValidTime(dataIn.pop_EndTime)) {
                this.response = "Invalid End Time (HH:SS)";
                return;
            }
        }
        */
        this.dialogRef.close(dataIn);
    }
    ngOnDestroy() {
        if (this.deviceChangedSubscription != null) this.deviceChangedSubscription.unsubscribe();
    }
    GetNow() {
        return this.timeNow;
    }
    Help(key) {
        Swal.fire({
            title: "Name is empty",
            html: "Ledger Name: <br>Based On: <br>Amount:",
            buttonsStyling: false,
        /*confirmButtonClass: "btn btn-success"*/
        });//.catch(swal.noop)
    }
    Warn(html) {
        Swal.fire({
            title: "Name is empty",
            html: html,
            buttonsStyling: false,
        /*confirmButtonClass: "btn btn-success"*/
        });//.catch(swal.noop)
    }
    trackByIndex(index: number, obj: any): any {
        return index;
    }
    OnRowChange(id) {
        if (this.sel_id !== 0) {
            if (this.sel_id != id) {
                this.SetStatus("Row should be saved individually");
            }
        }
        this.sel_id = id;
    }
    last_mode: string = "";
    last_crdr: string = "";
    last_idx: string = "";
    PayslipMaster_confirm(mode, crdr, idx) {
        this.last_mode = mode;
        this.last_crdr = crdr;
        this.last_idx = idx;
        Swal.fire({
            title: '',
            html: "Do you want to remove this ledger from this 'Payscale'?",
        /*type: 'warning',*/
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Remove it!'
        }).then((result) => {
            if (result.value) {
                this.PayslipMaster(this.last_mode, this.last_crdr, this.last_idx);
            }
        })
    }
    PayslipMaster(mode, crdr, idx) {
        var moveto = "";
        this.last_mode = "";
        this.last_crdr = "";
        this.last_idx = "";
        if (mode === "save") {
            this.last_mode = mode;
            this.last_crdr = crdr;
            this.last_idx = idx;
            this.sel_id = 0;


            if (crdr === 'cr') {
                if (this.payslipMaster.rows_rate_earn[idx].ledger.name.length === 0) {
                    this.Swal('ledgerEmpty');
                    return;
                }
                if ((this.payslipMaster.rows_rate_earn[idx].amount.length === 0) &&
                    (this.payslipMaster.rows_rate_earn[idx].basedon.length === 0)) {
                    this.Swal('valuesEmpty');
                    return;
                }


                if (this.payslipMaster.rows_rate_earn[idx].basedon.length > 0) {
                    if (this.payslipMaster.rows_rate_earn[idx].amount.indexOf("%") === -1) {
                        this.Swal('percentageYes');
                        return;
                    }
                } else {
                    if (this.payslipMaster.rows_rate_earn[idx].amount.indexOf("%") > -1) {
                        this.Swal('percentageNo');
                        return;
                    }
                }
            } else if (crdr === 'cro') {
                if (this.payslipMaster.rows_rate_earn_o[idx].ledger.name.length === 0) {
                    this.Swal('ledgerEmpty');
                    return;
                }
                if ((this.payslipMaster.rows_rate_earn_o[idx].amount.length === 0) &&
                    (this.payslipMaster.rows_rate_earn_o[idx].basedon.length === 0)) {
                    this.Swal('valuesEmpty');
                    return;
                }


                if (this.payslipMaster.rows_rate_earn_o[idx].basedon.length > 0) {
                    if (this.payslipMaster.rows_rate_earn_o[idx].amount.indexOf("%") === -1) {
                        this.Swal('percentageYes');
                        return;
                    }
                } else {
                    if (this.payslipMaster.rows_rate_earn_o[idx].amount.indexOf("%") > -1) {
                        this.Swal('percentageNo');
                        return;
                    }
                }
            }
            else if (crdr === 'dr') 
            {
                if (this.payslipMaster.rows_rate_deduct[idx].ledger.name.length === 0) {
                    this.Swal('ledgerEmpty');
                    return;
                }
                if (this.payslipMaster.rows_rate_deduct[idx].basedon.indexOf("Auto (custom)") === -1) {
                    if ((this.payslipMaster.rows_rate_deduct[idx].amount.length === 0) &&
                        (this.payslipMaster.rows_rate_deduct[idx].basedon.length === 0)) {
                        this.Swal('valuesEmpty');
                        return;
                    }
                    if (this.payslipMaster.rows_rate_deduct[idx].basedon.length > 0) {
                        if (this.payslipMaster.rows_rate_deduct[idx].amount.indexOf("%") === -1) {
                            this.Swal('percentageYes');
                            return;
                        }
                    } else {
                        if (this.payslipMaster.rows_rate_deduct[idx].amount.indexOf("%") > -1) {
                            this.Swal('percentageNo');
                            return;
                        }
                    }
                }
            } else if (crdr === 'dro') {
                if (this.payslipMaster.rows_rate_deduct_o[idx].ledger.name.length === 0) {
                    this.Swal('ledgerEmpty');
                    return;
                }
                if (this.payslipMaster.rows_rate_deduct_o[idx].basedon.indexOf("Auto (custom)") === -1) {
                    if ((this.payslipMaster.rows_rate_deduct_o[idx].amount.length === 0) &&
                        (this.payslipMaster.rows_rate_deduct_o[idx].basedon.length === 0)) {
                        this.Swal('valuesEmpty');
                        return;
                    }
                    if (this.payslipMaster.rows_rate_deduct_o[idx].basedon.length > 0) {
                        if (this.payslipMaster.rows_rate_deduct_o[idx].amount.indexOf("%") === -1) {
                            this.Swal('percentageYes');
                            return;
                        }
                    } else {
                        if (this.payslipMaster.rows_rate_deduct_o[idx].amount.indexOf("%") > -1) {
                            this.Swal('percentageNo');
                            return;
                        }
                    }
                }
            }
        }else if (mode === "move") {
            if (crdr === 'cr') {
                if (this.payslipMaster.rows_rate_earn[idx].ledger.name.length === 0) {
                    this.Swal('ledgerEmpty');
                    return;
                }
                moveto = "earn";
            } else if (crdr === 'cro') {
                if (this.payslipMaster.rows_rate_earn_o[idx].ledger.name.length === 0) {
                    this.Swal('ledgerEmpty');
                    return;
                }
                moveto = "earn";
            } else if (crdr === 'dr') {
                if (this.payslipMaster.rows_rate_deduct[idx].ledger.name.length === 0) {
                    this.Swal('ledgerEmpty');
                    return;
                }
                moveto = "deduct";
            } else if (crdr === 'dro') {
                if (this.payslipMaster.rows_rate_deduct_o[idx].ledger.name.length === 0) {
                    this.Swal('ledgerEmpty');
                    return;
                }
                moveto = "deduct";
            }

            this.force = "0";
        }
        else {
            this.force = "0";
        }

        this.response = "Please wait...";
        this.http.post("http://" + APIDomain + "/AccessManager/PayslipMaster",
            {
                "profile": getCookie('login_profile'),
                "name": this.data.name,
                "key": this.data.key,
                "startdate": this.data.startdate,
                "mode": mode,
                "crdr": crdr,
                "moveto": moveto,
                "force":this.force,
                "CTC": this.payslipMaster.CTC,
                "row":  this.GetPayslipRow(idx,crdr)
            }
        ).subscribe(result => {
            //this.payslipMaster = result.json() as PayslipMaster;
            var payslip = result.json() as PayslipMaster;
            this.SetStatus(payslip.result);

            this.bIsEdit = this.IsEdit();
            //if (this.bIsEdit && !this.IsSA()) {
              //  if (!payslip.allowdelete) this.bIsEdit = false;
            //}
            if (payslip.allowdelete!==1) this.bIsEdit = false;
            if (payslip.result === "Accounts ledger is not available") {
                Swal.fire({
                    title: 'No Accounts Ledger',
                    html: "Accounts ledger is not available with this name.<br>Do you want to create a new Accounts Ledger and proceed?" +
                        "<br><i>[Please take care of spelling and importance of this Ledger]<i>",
                /*type: 'warning',*/
                    showCancelButton: true,
                   /* confirmButtonClass: 'btn btn-success',*/
                /*cancelButtonClass: 'btn btn-danger',*/
                    confirmButtonText: 'Yes, Go ahead!',
                    buttonsStyling: false
                }).then((result) => {
                    if (result.value) {
                        this.force = "1";
                        this.PayslipMaster(this.last_mode, this.last_crdr, this.last_idx);
                    } else {
                        this.payslipMaster = payslip;
                        this.force = "0";
                    }
                });
            } else {
                this.payslipMaster = payslip;
                this.force = "0";
            }
        }, error => {
            console.error(error);
            this.response = error;
        }
        );
    }
    GetPayslipRow(idx: number, crdr: string) {

        if (idx == -1) return null;
        if (crdr === 'cr') return this.payslipMaster.rows_rate_earn[idx];
        else if (crdr === 'cro') return this.payslipMaster.rows_rate_earn_o[idx];
        else if (crdr === 'dr') return this.payslipMaster.rows_rate_deduct[idx];
        else if (crdr === 'dro') return this.payslipMaster.rows_rate_deduct_o[idx];
        else if (crdr === 'earn') {
            return this.payslipMaster.rows_earn[idx];
        }
        else if (crdr === 'deduct') return this.payslipMaster.rows_deduct[idx];
        return null;
    }
    Swal(key) {
        var title = "", html = "";
        if (key === "") {
            title = "<span style='font-size:large'>Payscale Master</span>";
            html = '<div style="text-align:left">' +
                'There are 2 options after entering the ledger name...<br>' +
                '<ul>' +
                '<li>Calculate the values based on another ledger. In this case, select the other ledger from then dropdown and mention the percentage [%]</li>' +
                '<li>Enter the fixed value. In this case, leave the drop down ledger empty</li>' +
                '</ul>' +
                '<hr/>' +
                '<i class="material-icons" style="color:#666">expand_less</i> Moves the ledger up<br>' +
                '<i class="material-icons" style="color:#666">expand_more</i> Moves down<br>' +
                '<i class="material-icons" style="color:#666">close</i> Delete the concern ledger<br>' +
                '<i class="material-icons" style="color:#666">save</i> Save when done with every row<br>' +
                '<i class="material-icons" style="color:#666">play_arrow</i> Move the ledger to the payslip colums' +
                '</div>';
        } else if (key === "percentageYes") {
            title = "<span style='font-size:large'>Payscale Master</span>";
            html = '<div style="text-align:left">' +
                'If the calculation is based on another ledger, please use the value in percentage. Ex. 50% [Leave dropdown box empty for fixed charges]' +
                '</div>';
        } else if (key === "percentageNo") {
            title = "<span style='font-size:large'>Payscale Master</span>";
            html = '<div style="text-align:left">' +
                'If its a fixed amount, please don\'t use percentage. Or, choose a ledger from dropdown and use percentage.' +
                '</div>';
        } else if (key === "ledgerEmpty") {
            title = "<span style='font-size:large'>Payscale Master</span>";
            html = '<div style="text-align:left">' +
                'ledger Name is empty.' +
                '</div>';
        } else if (key === "valuesEmpty") {
            title = "<span style='font-size:large'>Payscale Master</span>";
            html = '<div style="text-align:left">' +
                'You need to enter a fixed value or <br>a value based on another ledger.<br>Both are empty now.' +
                '</div>';
        }
        Swal.fire({
            title: title,
            text: "Help",
            html: html,
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i>',
            confirmButtonAriaLabel: 'Thumbs up, great!',
        });//.catch(swal.noop)
    }
    SetLedgerType(command, tab, idx) {
        this.last_mode = command;
        this.last_crdr = tab;
        this.last_idx = idx;
        var html = "";
        var Paymode = 0;
        if (tab === "cro") {
            Paymode = this.payslipMaster.rows_rate_earn_o[idx].ledger.paymode;
        } else {
            Paymode = this.payslipMaster.rows_rate_earn[idx].ledger.paymode;
        }

        if (Paymode === 2) {
            html = "<i class='material-icons' style = 'color:#ccc;zoom:2;'>touch_app</i><br>" +
                "Current Status is...<br>"+
                "<span style='color:blue'><b>Physical presence not required to get paid</b></span><br>" +
                "<hr> " +
                "Would you like to change this status to...<br><span style='color:red'>Physical presence required?</span>";
            //                "<span style='font-size:small;'>(OFFs, Leaves, Holidays also will be paid)</span>" +
        } else {
            html = "<i class='material-icons' style = 'color:blue;zoom:2;'>touch_app</i><br>" +
                "Current Status is...<br>" +
                "<span style='color:blue'><b>Physical presence required to get paid</b></span><br>" +
                "<hr> " +
                "Would you like to change this status to...<br><span style='color:red'>Physical presence not required?</span>";
            //                "<span style='font-size:small;'>(OFFs, Leaves, Holidays will NOT be paid)</span>" +
        }
            // Don't pay on leaves
            Swal.fire({
                title: '',
                html: html,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Change it!'
            }).then((result) => {
                if (result.value) {
                    this.force = "1";
                    this.PayslipMaster(this.last_mode, this.last_crdr, this.last_idx);
                }
            })
    }
    SetEdit(unix:number) {
        var date = new Date((unix-19800) * 1000);
        this.newKey = this.getDateString(date);
    }
    GetUnixTimeOf_newKey() {
        this.newKey.trim();
        var dt = this.global.IsDate(this.newKey);
        if (dt===null) {
            this.Warn("Invalid date");
            return 0;
        }
        /*
        if (this.newKey.length < 10) {
            this.Warn("Invalid date");
            return 0;
        }
        var st=this.newKey.substring(6, 10) + '-' +
            this.newKey.substring(3, 5) + '-' +
            this.newKey.substring(0, 2);
        
        var st = dt;
        st += " 00:00:00";

        var dt = new Date(st);
            
        this.newKey.trim();
        if (isNaN(dt.getTime())) {
            this.Warn("Invalid date");
            return 0;
        }
        */
        return dt.getTime() / 1000;
    }
    UpdateEffectiveDate() {
        //  01234567890123456789
        //  30-03-2019 18:43:05
        var unix = this.GetUnixTimeOf_newKey() + 19800;


        this.response = "Please wait...";
        this.http.post("http://" + APIDomain + "/AccessManager/UpdateEffectiveDate",
            {
                "profile": getCookie('login_profile'),
                "email": getCookie('login_email'),
                "name": this.data.name,
                "key": this.data.key,
                "newkey": unix
            }
        ).subscribe(result => {
            this.postResponse = result.json() as PostResponse;
            this.SetStatus(this.postResponse.result);
            
            if (this.postResponse.status) {
                this.data.key = this.postResponse.iParam1;
                this.newKey = "";
            } else {
                this.newKey = "";
                Swal.fire({
                    title: "Sorry!!! Failed",
                    html: this.postResponse.result,
                    buttonsStyling: false,
                /*confirmButtonClass: "btn btn-success"*/
                });//.catch(swal.noop)
            }
        }, error => {
            console.error(error);
            this.response = error;
            }
        );
    }
    SetStatus(mess) {
        this.response = mess;
        setTimeout(() => { this.response = ""; }, 5000);
    }
    getDateString(dt) {
        var ret = "";
        var dd = dt.getDate();
        var mm = dt.getMonth() + 1;
        var yyyy = dt.getFullYear();
        var HH = dt.hours
        if (dd < 10) {
            ret += `0${dd}-`;
        } else {
            ret += `${dd}-`;
        }
        if (mm < 10) {
            ret += `0${mm}-`;
        } else {
            ret += `${mm}-`;
        }
        ret += yyyy;
        /*
        var hours = "0" + dt.getHours();
        var minutes = "0" + dt.getMinutes();
        var seconds = "0" + dt.getSeconds();
        ret += ' ' + hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        */
        return ret;
    }
    KeyPressDevice(value: string) {
        //console.log("KeyPress:"+value);
        this.filteredLedgers = null;
        this.deviceChanged.next(value);
    }
    ShowFreeStaffs() {
        this.filteredLedgers = this.searchDevices("", getCookie('login_profile'));
    }
    searchDevices(search: string, profile: string): Observable<PayLedger[]> {
        let apiURL = `${'http://' + APIDomain + '/accessmanager/GetPayscaleLedgers'}`; //&media=music&limit=20&callback=JSONP_CALLBACK
        const params = new URLSearchParams();
        params.set('search', search);
        params.set('profile', profile);
        params.set('activetab', this.bActiveTab + "");
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');

        const options = new RequestOptions({
            headers: headers,
            responseType: ResponseContentType.Json,
            params: params,
            withCredentials: false
        });
        console.log(apiURL + "?" + params);
        return this.http.post(apiURL, {}, options).pipe(
            map(res => {
                return res.json().sarLedgers.map(item => {
                    return new PayLedger(
                        item.Name,
                        item.paymode
                    );
                });
            }));
    }
    OnSelStaffChange(oStaff: PayLedger) {
        this.selectedStaffItem = oStaff;
    }
}
export interface PostResponse {
    status: boolean;
    result: string;
    iParam1: number;
}
//------------------------------------------
export interface PayslipMaster {
    status: boolean;
    result: string;
    name: string;
    CTC: string;
    allowdelete: number;
    ledgers: PaySlipLedger[];
    rows_rate_earn: PayslipRow[];
    rows_rate_earn_o: PayslipRow[];
    rows_rate_deduct: PayslipRow[];
    rows_rate_deduct_o: PayslipRow[];
    rows_earn: PayslipRow[];
    rows_deduct: PayslipRow[];
}
export interface PaySlipLedger {
    name: string;
    paymode: number;
}
export interface PayslipRow {
    m_id: number;
    ledger: PaySlipLedger;
    basedon: string;
    amount: string;
}
//------------------------------------------
export interface PayslipData {
    profile: string;
    name: string;
    key: number;
    email: string;
    action: string;
    startdate: string;
    allowdelete: number;
}

class ReportingToItem {
    constructor(
        public Name: string,
        public Roll: string,
        public Base: string,
        public Email: string,
    ) { }
}
class PayLedger {
    constructor(
        public Name: string,
        public paymode:number
    ) { }

}