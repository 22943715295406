import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';


import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCalendar } from '@angular/material/datepicker';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { PayscaleComponent } from './payscale.component';
import { DomSanitizer } from '@angular/platform-browser'
import { Payslip_SettlementComponent } from '../../payroll/payslip_settlement/payslip_settlement.component';


@Component({
    selector: 'app-payscales',
    templateUrl: './payscales.component.html',
    styleUrls: ['./payscales.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class PayscalesComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    //public onProfileResponse: OnProfileResponse;
    public payscalesResponse: PayscalesResponse;
    selected_m_id: any;
    selectedItem: string;
    selected_row_email: string;
    showoptions: string; 
    resultsLength: string="";
    payscaleItem: PayscaleItem;
    expandedElement: any;
    edipandedElement: any;
    allowListElement: any;
    bIsEdit: boolean;
    butStatus: number;
    selectedgrp: string = "All";
    newpayscale: string = "";

    //___________________________Material

    //private _signalrObservable = null;
    private _searchObservable = null;
    timeZoneOffset: string;
    
    response: string="";

    /*-------------------------Have to be replaced by online query later
    sarDesignations: string[] = [null,'Trainee', 'ECG Analyst Trainee', 'ECG Analyst', 'Sr.ECG Analyst',
        'Floor Supervisor', 'Team Leader', 'Manager', 'Sr.Manager'];
    sarRolls: string[] = [null, 'Techs', 'Supervisor', 'Team Leader', 'Manager', 'Admin', 'Super Admin'];
    sarTeams: string[] = [null, 'LEADERSHIP', 'CENTURY', 'MSA', 'ACT-IR/ELITE', 'PHILIPS-LW',
        'ROZINN-ACT EX', 'DMS-AUS', 'PCS-CA', 'AMI-USA', 'HW-myPatch', 'CLINICAL EDUCATION',
        'CLINICAL OPERATION/BD','CUSTOMER SUPPORT-DOMESTIC','BIOMEDICAL SALES/SERVICE',
        'FIELD SUPPORT', 'HRD & ADMINSTRATION','FINANCE & ACCOUNTS','INFORMATION TECHNOLOGY',
        'CEO OFFICE','VENDOR STAFF-SECURITY','VENDOR STAFF-HOUSE KEEPING'];
    //-------------------------Have to be replaced by online query later END
    */
    
    displayedColumns: string[] = ['m_Name', 'm_Amount', 'm_Key', 'm_CreatedBy', 'm_CreatedTime', 'm_ViewLog'];
    
    exampleDatabase: ExampleHttpDao | null;
    data: PayscaleItem[] = [];

    isLoadingResults = true;
    isRateLimitReached = false;
    

    list = [
        { "name": "some name 1", ID: "D1" },
        { "name": "some name 2", ID: "D2" }
    ]

    public postResponse: PostResponse;
    //public fixedArrayResponse: FixedArrayResponse;

    globalSearch: string = "";
    bFirstRun: boolean = false;
    m_MenuKey: string = "";
    //___________________________Material END
    constructor(public http: Http, private router: Router, private httpClient: HttpClient,
        public dialog: MatDialog,private sanitizer: DomSanitizer,
          public global: GlobalProvider) {

        if (getCookie("login_profile").length === 0) {
            this.router.navigateByUrl('/pages/login');
            return;
        }
        
        this.payscalesResponse = {} as PayscalesResponse;
        //this.fixedArrayResponse = {} as FixedArrayResponse;

        this.selected_m_id = "";
        this.expandedElement = "";
        this.edipandedElement = "";
        this.allowListElement = "";

        this.butStatus = 0;
        this.payscaleItem = {} as any;


        this.m_MenuKey = getCookie('menukey');
        this.bIsEdit = this.IsEdit();        
    }
    ngAfterViewInit() {
        this.global.globalSearch = "";

        this.Merge("","");
        this.bFirstRun = true;
    }
    IsAdmin() {
        return getCookie("menukey").indexOf("a0-1") > -1;
    }

    ngOnInit() {
        //if (this.global.sarDesignations.length === 0) this.LoadFixedArrays();
        //this.OnListClk("");
        this._searchObservable = this.global.currentMessage.subscribe(search => {
            if (search != null) {
                if (this.bFirstRun) {
                    //alert(typeof search);
                    this.showoptions = "3";
                    this.globalSearch = search;
                    this.paginator.pageIndex = 0
                    this.Merge("", "");
                }
            }
        });
        /*
                this._signalrObservable = this._signalrService.borrowerChangedObservable
                    .subscribe((obj: SubObj) => {
                        if (obj.comm == 1) {
                            if (obj.str.substring(0, 2) === "{G") { // reload
                                //this.LoadTrips();
                            }
                        }
                    });
        */
        //_________________________________
        this.timeZoneOffset = (new Date().getTimezoneOffset() * 60).toString();
        //__________________________________Material
        this.exampleDatabase = new ExampleHttpDao(this.httpClient);
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        //__________________________________Material END
        this.showoptions = "1";
    }
    transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    merge_mode: string = "";
    merge_value: string = "";
    Merge(mode, value) {
        this.merge_mode = mode;
        this.merge_value = value;
        if (mode === "new") {
            if (value.length === 0) {
                this.response = "New name is empty";
                return;
            }
        }
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    var mode_ = this.merge_mode;
                    var value_ = this.merge_value;
                    this.merge_mode = "";
                    this.merge_value = "";
                    return this.exampleDatabase!.getRepoIssues(
                        this.sort.active, this.sort.direction, this.paginator.pageIndex,
                        this.globalSearch, getCookie('login_profile'),
                        getCookie('login_staffid'),
                        this.showoptions,mode_,value_
                    );
                }),
                map((data:PayscalesResponse) => {
                    // Flip flag to show that loading has finished.
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data.total_count;
                    this.response = data.result;
                    this.newpayscale = "";
                    return data.items;
                }),
                catchError((err) => {
                    alert(err.message)
                    this.isLoadingResults = false;
                    // Catch if the GitHub API has reached its rate limit. Return empty data.
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe((data:PayscaleItem[]) => this.data = data);
    }
    ngOnDestroy() {
        //if (this._signalrObservable != null) this._signalrObservable.unsubscribe();
        if (this._searchObservable != null) this._searchObservable.unsubscribe();
    }

    GetListSelectedColor(item): any {
        if (item === this.selectedItem)
            return { 'background-color': '#ddd', 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'border': '0.5px solid #b4b4b4', 'white-space': 'nowrap' };
        else return { 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'white-space': 'nowrap' };
    }

    

    //----------------------------------------------
    OpenHistory(row: PayscaleItem) {
        this.payscaleItem = row;
        const dialogRef = this.dialog.open(PayscaleComponent, {
            width: '65%',
            data: {
                result: "",
                payscaleItem: this.payscaleItem
            }
        });
        dialogRef.afterClosed().subscribe((result: DialogData) => {
            this.response = "";
            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.response === "reload") {
                    this.Merge("","");
                }
            }
        });
    }


    OnUpdate(mode) {

        this.response = "Please wait...";
        const params = new URLSearchParams();
        params.append('mode', mode);
        params.append('profile', getCookie('login_profile'));
        params.append('email', getCookie('login_email'));
        this.http.post("http://" + APIDomain + "/accessmanager/Update_Staffs", params
        ).subscribe(result => {
            this.postResponse = result.json() as PostResponse;
            if (this.postResponse.status) {
                this.sort.direction = "desc";
                this.Merge("","");
                this.response = this.postResponse.result;
            } else {
                this.response = this.postResponse.result;
            }
        }, error => {
            this.response = "Failed: " + this.postResponse.result;
        }
        );
        if (mode === "new" || mode === "newstaff") {
            this.global.globalSearch = "";
            this.globalSearch = "";
            this.Merge("","");
        }

    }
    IsEdit() {
        if (this.IsSA()) return true;
        if (this.m_MenuKey.indexOf("p1-2") > -1) return true;
        return false;
    }
    IsAllowed(key1, key2) {
        if (this.m_MenuKey.indexOf(key1) > -1) return true;
        if (this.m_MenuKey.indexOf(key2) > -1) return true;
        return false;
    }
    IsSAemail(mail) {
        return getCookie('login_profile') === mail;
    }
    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }
    IsLWD(dt: Date) {
        if (!this.IsDate(dt)) return false;
        let dt1 = eval((dt + "").replace(/\/Date\((\d+)\)\//gi, "new Date($1)"));
        //console.error(new Date().getTime() + "___" + dt1.getTime());
        if ((new Date()) > dt1) return true;
        return false;
    }
    IsDate(dt: Date) {
        return dt.toString().indexOf("Date(-") === -1;
    }
    GetMrs(mrs) {
        if (mrs === "0") return "Mr.";
        else if (mrs === "1") return "Ms.";
        else if (mrs === "10") return "Dr.";
        else if (mrs === "11") return "Dr.";
        return "";
    }
    OnRadio(e) {
        this.Merge("","");
    }
    CardColor(row: PayscaleItem) {
        /*
        if (row.m_ApprovedBy3.length > 0) {
            return { 'color': '#b3b300' };
        } else {
            if (row.m_ApprovedBy1.length > 0) {
                return { 'color': '#c00' };
            }            
        }
        */
        return { 'color': '#ececec' };
    }
    
    OnPayslip(email, staffid) {
        //this.staffItem.m_Email = email;
        const dialogRef = this.dialog.open(Payslip_SettlementComponent, {
            width: '70%',
            data: {
                result: "",
                payscaleItem: this.payscaleItem
            }
        });
        dialogRef.afterClosed().subscribe((result: DialogData) => {
            this.response = "";
            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.response === "delete") {
                    this.Merge("","");
                }
            }
        });
    }
    
}
export interface DialogData {
    payscaleItem: PayscaleItem;
    response: string;
}

export interface ListRow {
    imei: string;
    regno: string;
    group: string;
    check: boolean;
}



export interface PostResponse {
    status: boolean;
    result: string;
}
export interface PayscalesResponse {
    status: boolean;
    result: string;
    total_count: string;
    reload: boolean;
    items: PayscaleItem;
}
export interface PayscaleItem {
    m_id: number;
    m_Name: string;
    m_Key: number;
    m_CreatedBy: string;
    m_CreatedTime: number;
    m_UpdatedBy: string;
    m_UpdatedTime: number;
    m_CTC: number;
    allowdelete: number;
}

export interface FixedArrayResponse {
    status: boolean;
    result: string;
    sarTitles: string[];
    sarRolls: string[];
    sarTeams: string[];
    sarBases: string[];
    sarBands: string[];
    sarGrades: string[];
    sarBanks: string[];
    sarRosterOptions: string[];
}
class ReportingToItem {
    constructor(
        public Name: string,
        public Roll: string,
        public Base: string,
        public Email: string,
    ) { }
}
/** An example database that the data source uses to retrieve data for the table. */
export class ExampleHttpDao {
    constructor(private http: HttpClient) { }

    getRepoIssues(sort: string, order: string, page: number, search: string,
        profile: string, staffid: string, showoptions: string, mode: string, value: string): Observable<PayscalesResponse> {
        const href = "http://" + APIDomain + '/accessmanager/GetPayscales';
        const requestUrl =
            `${href}?q=q&sort=${sort}&order=${order}&page=${page + 1}&search=${search}&profile=${profile}&showoptions=${showoptions}&mode=${mode}&value=${value}&staffid=${staffid}`;
        //console.log(requestUrl);
        return this.http.get<PayscalesResponse>(requestUrl);
    }
}
