
import {filter,  debounceTime } from 'rxjs/operators';
import { Component, OnInit, /*Renderer2,*/ ViewChild, ElementRef, Directive } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { SignalrService } from '../../signalr.service';
import { GlobalProvider, APIDomain } from '../../providers/global/global';
import { Http, URLSearchParams, RequestOptions, Headers, ResponseContentType } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { OpenProfileEditor } from '../../rostermanager/staffs/profile-editor';
import { MessageComponent } from '../../messages/message/message.component';


const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare var $: any;
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private _router: Subscription;
    subject: Subject<any> = new Subject();
    searchValue: string = "";
    @ViewChild('app-navbar-cmp') button: any;
    message: string;

    constructor(location: Location, /*private renderer: Renderer2,*/
        private element: ElementRef, private router: Router,
        private _signalrService: SignalrService,
        
        public dialog: MatDialog,
        public http: Http,
        public global: GlobalProvider) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            $(".navbar.navbar-expand-lg.navbar-absolute").css("width", "81%");
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');
                $(".navbar.navbar-expand-lg.navbar-absolute").css("width", "94%");
                misc.sidebar_mini_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }
    hideSidebar() {
        const body = document.getElementsByTagName('body')[0];
        const sidebar = document.getElementsByClassName('sidebar')[0];

        if (misc.hide_sidebar_active === true) {
            setTimeout(function () {
                body.classList.remove('hide-sidebar');
                misc.hide_sidebar_active = false;
            }, 300);
            setTimeout(function () {
                sidebar.classList.remove('animation');
            }, 600);
            sidebar.classList.add('animation');

        } else {
            setTimeout(function () {
                body.classList.add('hide-sidebar');
                // $('.sidebar').addClass('animation');
                misc.hide_sidebar_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    ngOnInit() {
        // Subscribe to the subject, which is triggered with each keyup
        // When the debounce time has passed, we add a validator and update the form control to check validity

        this.subject
            .pipe(debounceTime(500))
            .subscribe(() => {
                this.global.changeMessage(this.searchValue);
            }
        );

        this.PendingMessages();
        //__________________________________________________________________________________
        this.global.currentMessage.subscribe(message => this.message = message)
        this.listTitles = ROUTES.filter(listTitle => listTitle);

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            const $layer = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }
        });
    }
    IsAdmin() {
        return getCookie("menukey").indexOf("a0-1") > -1;
    }
    PendingMessages() {
        this.global.messageLeavesCount = 0;
        this.global.messageTimesCount = 0;
        let params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('email', getCookie('login_email'));
        //alert(params);
        this.http.post("http://" + APIDomain + "/accessmanager/GetPendingMessages", params
        ).subscribe(
            result => {
                var messageResponse = result.json() as MessageResponse;
                this.global.messageLeavesCount = messageResponse.retLeaves;
                this.global.messageTimesCount = messageResponse.retTimes;
            }, error => {
                //this.SetStatus("Err2-" + error);
            }
        );
    }

    onResize(event) {
      if ($(window).width() > 991) {
        return false;
      }
      return true;
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        const body = document.getElementsByTagName('body')[0];

        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        if (this.mobile_menu_visible == 1) {
            // $('html').removeClass('nav-open');
            body.classList.remove('nav-open');
            if ($layer) {
                $layer.remove();
            }

            setTimeout(function() {
                $toggle.classList.remove('toggled');
            }, 400);

            this.mobile_menu_visible = 0;
        } else {
            setTimeout(function() {
                $toggle.classList.add('toggled');
            }, 430);

            var $layer = document.createElement('div');
            $layer.setAttribute('class', 'close-layer');


            if (body.querySelectorAll('.main-panel')) {
                document.getElementsByClassName('main-panel')[0].appendChild($layer);
            }else if (body.classList.contains('off-canvas-sidebar')) {
                document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
            }

            setTimeout(function() {
                $layer.classList.add('visible');
            }, 100);

            $layer.onclick = function() { //asign a function
              body.classList.remove('nav-open');
              this.mobile_menu_visible = 0;
              $layer.classList.remove('visible');
              setTimeout(function() {
                  $layer.remove();
                  $toggle.classList.remove('toggled');
              }, 400);
            }.bind(this);

            body.classList.add('nav-open');
            this.mobile_menu_visible = 1;

        }
    }

    getTitle() {
        let titlee: any = this.location.prepareExternalUrl(this.location.path());
        for (let i = 0; i < this.listTitles.length; i++) {
            if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
                return this.listTitles[i].title;
            } else if (this.listTitles[i].type === "sub") {
                for (let j = 0; j < this.listTitles[i].children.length; j++) {
                    let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
                    if (subtitle === titlee) {
                        return this.listTitles[i].children[j].title;
                    }
                }
            }
        }
        return 'Dashboard';
    }
    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }
    OnSignout() {
        this.SignOut(
            getCookie('login_profile'),
            getCookie('login_email'),
            getCookie('login_name'),
            getCookie('login_username'),
            getCookie('login_staffid')
        );

        //alert("RobinJ 2");
        //this.router.navigateByUrl('/pages/login');
    }
    SignOut(profile, email, name, user, staffid) {
        console.log("Trying to signout...");
        this.http.post("http://" + APIDomain + "/tim/SignOut",
            {
                "profile": profile,
                "email": email,
                "name": name,
                "user": user,
                "staffid": staffid
            }
        ).subscribe(
            result => {
                console.log("Signout server response...");
                this.global.EraseAll();
                setCookie('login_name', "");
                setCookie('login_email', "");
                setCookie('login_profile', "");
                setCookie('login_staffid', "");
                setCookie('login_password', "");
                setCookie('login_username', "");
                setCookie('menukey', "");
                this._signalrService.StopConnection();
                console.log("Signing out[1].");
                /*
                this.router.navigate(['/pages/login'])
                    .then(() => {
                        window.location.reload();
                    });*/
                window.location.href = "/pages/login";
                //this.router.navigate(['/pages/login']);
                //this.router.navigateByUrl(['/pages/login']);
                console.log("Signing out[2]");
            }, error => {
                console.log("Signout server response ERROR");
                //this.SetStatus("Err2-" + error);
                this.global.EraseAll();
                setCookie('login_name', "");
                setCookie('login_email', "");
                setCookie('login_profile', "");
                setCookie('login_staffid', "");
                setCookie('login_password', "");
                setCookie('login_username', "");
                setCookie('menukey', "");
                this._signalrService.StopConnection();
                console.log("Signing out[3].");
                //this.router.navigateByUrl('/pages/login');
                window.location.href = "/pages/login";
                /*
                this.router.navigate(['/pages/login'])
                    .then(() => {
                        window.location.reload();
                    });*/
                console.log("Signing out[4]");
            }
        );
    }
    GotoNow() {
        this.router.navigateByUrl('/rostermanager/now');
    }
    onSearchChange(searchValue: string)
    {
        console.log(searchValue);
        this.searchValue = searchValue;
        this.subject.next();
    }
    OnClear() {
        this.global.globalSearch = "";
        this.searchValue=""
        //console.log("Search:"+this.searchValue);
        this.subject.next();
    }

    OnProfile() {
        const dialogRef = this.dialog.open(OpenProfileEditor, {
            width: '70%',
            data: {
                pop_Head: "", pop_InputName: '', pop_PlaceHolder: "",
                pop_StartTime: '', pop_EndTime: '', pop_Roster: "",
                staffItem: getCookie('login_staffid'),
                email: getCookie('login_email')
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result === null) {

            } else if (typeof result === "undefined") {

            } else {

            }
        });
    }
    //-------------------------
    Test() {
        this.OpenMessage("CHC0546");
    }
    OpenMessage(staffid) {
        //string pay_session = "pay_" + staffid + "_" + iYearName + "_" + (iMonthName - 1);
        //var session = "pay_" + staffid + "_" + this.iYearActive + "_" + (this.iMonthActive + 1);
        var session = "pay_CHC0546_2019_5";

        const dialogRef = this.dialog.open(MessageComponent, {
            minWidth: '50em',
            width: '70%',
            data: {
                session: session
            }
        });
        dialogRef.afterClosed().subscribe((result) => {

            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {

                }
            }
        });
    }
}
export interface MessageResponse {
    status: boolean;
    result: string;
    retLeaves: number;
    retTimes: number;
}
