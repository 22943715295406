import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
//import { set as setCookie, get as getCookie } from 'es-cookie';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { SignalrService, Info } from '../signalr.service';
import { Head_Name, Head_Domain, APIDomain, MeterBox_Name, GlobalProvider } from '../providers/global/global';
import { Http, URLSearchParams, RequestOptions, Headers, ResponseContentType } from '@angular/http';
import { OpenProfileEditor } from '../rostermanager/staffs/profile-editor';
import { MatDialog } from '@angular/material/dialog';
declare const $: any;

import * as Cookies from 'es-cookie';
import { ItMaintenanceService } from 'app/mis/it_maintenance/it-maintenance.service';

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    iconname: string;
    collapse?: string;
    children?: ChildrenItems[];
    key: string;
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    key: string;
}

//Menu Items
//export const ROUTES: RouteInfo[] = [
export const ROUTES: RouteInfo[] = [
    {
        path: 'Dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'dashboard',
        iconname: '',
        key: 'd0-'
    }, {
        path: '/map',
        title: 'Map',
        type: 'link',
        icontype: 'place',
        iconname: '',
        key: 'm0-'
    }, {
        path: '/',
        title: 'Messages',
        type: 'sub',
        icontype: 'message',
        iconname: '',
        collapse: 'Messages',
        children: [
            { path: '/messages/leaves', title: 'Leave Requests', ab: '.', key: 'g1-' },
            { path: '/messages/times', title: 'Time Approvals', ab: '.', key: 'g2-' },
            { path: '/messages/latelogins', title: 'Late Approvals', ab: '.', key: 'g2-' },
        ],
        key: 'g0-'
    }, {
        path: '/',
        title: 'HR',
        type: 'sub',
        icontype: 'person',
        iconname: '',
        collapse: 'users',
        children: [
            { path: '/rostermanager/staffs', title: 'User Profiles', ab: '.', key: 'u1-' },
            { path: '/hr_reports/attendance', title: 'Attendance', ab: '.', key: 'h3-' },
            { path: '/payroll/payscales', title: 'Payscales', ab: '.', key: 'p1-' },
            { path: '/rostermanager/usersmobile', title: 'Mobile Users', ab: '.', key: 'u2-' },
        ],
        key: 'u0-'

    }, {
        path: '/',
        title: 'Operation',
        type: 'sub',
        icontype: 'build',
        iconname: '',
        collapse: 'hr_reports',
        children: [
            { path: '/hr_reports/rosters', title: 'Manage Rosters', ab: '.', key: 'x1-' },
            { path: '/hr_reports/activities', title: 'Shift vs Activities', ab: '.', key: 'h1-' },
            /*{ path: '/hr_reports/production', title: 'Production', ab: '.', key: 'h4-' },*/
            { path: '/hr_reports/payroll_setup', title: 'Payroll Setup', ab: '.', key: 'h2-' },
        ],
        key: 'h0-'
    }, {
        path: '/',
        title: 'Accounts',
        type: 'sub',
        icontype: 'credit_card',
        iconname: '',
        collapse: 'accounts',
        children: [
            { path: '/accounts/ledgers', title: 'Ledgers', ab: '.', key: 'b1-' },
            { path: '/accounts/statement', title: 'Statements', ab: '.', key: 'b2-' },
            /*
            { path: 'statement_pf', title: 'Statements PF', ab: '.', key: 'b2-' },
            { path: 'statement_esic', title: 'Statements ESIC', ab: '.', key: 'b2-' },
            */
        ],
        key: 'b0-'
        /*
    }, {
        path: '/payroll',
        title: 'Payroll',
        type: 'sub',
        icontype: 'event_note',
        iconname: '',
        collapse: 'payscales',
        children: [
            { path: 'payscales', title: 'Payscales', ab: '.', key: 'p1-' },
        ],
        key: 'p0-'
        */
    }, {
        path: '/',
        title: MeterBox_Name,
        type: 'sub',
        icontype: 'desktop_mac',
        iconname: '',
        collapse: 'rostermanager',
        children: [
            { path: '/rostermanager/terminals', title: 'Terminals', ab: '.', key: 's4-' },
            { path: '/rostermanager/terminalActivities', title: 'Activities', ab: '.', key: 's5-' },
            /*{ path: 'staffActivities', title: 'Staff Activities', ab: '.', key: 's3-' },*/
        ],
        key: 's0-'
    }, {
        path: '/',
        title: 'Logs',
        type: 'sub',
        icontype: 'event_note',
        iconname: '',
        collapse: 'Logs',
        children: [
            { path: '/logs/loginactivities', title: 'Login Activities', ab: '.', key: 'n1-' },
            { path: '/logs/leaveactivities', title: 'Leave Activities', ab: '.', key: 'n2-' },
        ],
        key: 'n0-'
    }, {
        path: '/',
        title: 'Master Tables',
        type: 'sub',
        icontype: 'line_weight',
        iconname: '',
        collapse: 'master',
        children: [
            { path: '/master/master', title: 'Master Tables', ab: '.', key: 't1-' },
            { path: '/master/biodevices', title: 'Bio-Devices', ab: '.', key: 't2-' }
        ],
        key: 't0-'
    }, {
        path: '/',
        title: 'MIS',
        type: 'sub',
        icontype: 'filter_none',
        iconname: '',
        collapse: 'mis',
        children: [
            { path: '/mis/leave_credits', title: 'Leave Credits', ab: '.', key: 'o1-' },
            { path: '/mis/hr', title: 'Bonus Accrued', ab: '.', key: 'o2-' },
            { path: '/mis/salary_report', title: 'Summary', ab: '.', key: 'o3-' },
            { path: '/mis/pulse_issues', title: 'Pulse Issues', ab: '.', key: 'o4-' },
            { path: '/mis/it_maintenance', title: 'IT Maintenance', ab: '.', key: 'o6-' },
        ],
        key: 'o0-'
    },/* {
        path: '/accessmanager',
        title: 'Access Manager',
        type: 'sub',
        icontype: 'person',
        iconname: 'accessmanager32',
        collapse: 'accessmanager',
        children: [
            { path: 'terminals', title: 'Terminals', ab: '.' },
            { path: 'staffs', title: 'Staffs', ab: '.' },
            { path: 'terminalActivities', title: 'Terminal Activities', ab: '.' },
            { path: 'staffActivities', title: 'Staff Activities', ab: '.' }
        ]
    },*/{
        path: '/',
        title: 'Logistics',
        type: 'sub',
        icontype: 'local_shipping',
        iconname: '',
        collapse: 'logistics',
        children: [
            { path: '/logistics/clients', title: 'Clients', ab: '.', key: 'l1-' },
            { path: '/logistics/staffs', title: 'Staffs', ab: '.', key: 'l2-' },
            { path: '/logistics/parcelbooking', title: 'Parcel Bookings', ab: '.', key: 'l3-' },
            { path: '/logistics/bookinghistory', title: 'Booking History', ab: '.', key: 'l4-' }
        ],
        key: 'l0-'
    }, {
        path: '/',
        title: 'fleet',
        type: 'sub',
        icontype: 'local_taxi',
        iconname: '',
        collapse: 'fleet',
        children: [
            { path: '/fleet/vehicles', title: 'Vehicles', ab: '.', key: 'f1-' },
            { path: '/fleet/drivers', title: 'Drivers', ab: '.', key: 'f2-' },
            { path: '/fleet/devices', title: 'Devices', ab: '.', key: 'f3-' },
            { path: '/fleet/groups', title: 'Groups', ab: '.', key: 'f4-' },
            { path: '/fleet/tariffs', title: 'Tariffs', ab: '.', key: 'f5-' },
        ],
        key: 'f0-'
    }, {
        path: '/',
        title: 'fleet Reports',
        type: 'sub',
        icontype: 'event_note',
        iconname: '',
        collapse: 'fleetreports',
        children: [
            { path: '/fleetreports/trips', title: 'Trips', ab: '.', key: 'r1-' }
        ],
        key: 'r0-'
    }, {
        path: '/downloads',
        title: 'Downloads',
        type: 'link',
        icontype: 'cloud_download',
        iconname: '',
        key: 'w0-'
    }
];

@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    Head_Name: string;
    Head_Domain: string;
    public menuItems: any[];
    login_name: string;
    myphoto: any;
    menukey: string;
    Staffid: string = null;
    ngitmaintain: boolean = false;
    constructor(private router: Router,
        public sc: ItMaintenanceService,
        private _signalrService: SignalrService,
        public global: GlobalProvider,
        public dialog: MatDialog,
        public http: Http) {
        this.menukey = "";

        this.Head_Name = Head_Name;
        this.Head_Domain = Head_Domain;
        this.Staffid = Cookies.get('login_staffid')
        this.login_name = Cookies.get("login_name");
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            Cookies.get('login_profile') + "_" + Cookies.get('login_email') + "&dummy=" + Date.now().toString();
    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };
    ngOnInit() {
        this.LoadMenu();
        // Expand by default, if menu is less
        if (this.menuItems.length < 4) $('.collapse').collapse()
        this.checkItmaintenance();
    }

    //#region Periyasamy

    menukeyshowhide(key: any): boolean {
        if (key == 'o4-') {
            // console.log("Pulse Issues");
        }
        var a = (this.menukey.indexOf(key + '1') > -1 || this.menukey.indexOf(key + '2') > -1)
        return a;
    }
    //#endregion

    LoadMenu() {
        //this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.menukey = Cookies.get("menukey");

        var menuKeyLoc = this.menukey;
        console.log(menuKeyLoc);

        ROUTES[0].path = this.global.DefaultDashboard;
        console.log(this.global.DefaultDashboard);
        this.menuItems = ROUTES.filter(
            function (r) {

                //  a0-0,a0-1,a0-2,a0-9
                //var x = false;//r.title == 'My Roster';
                //if (r.title == 'My Roster') x = true;
                //alert(that.global.menuKey);
                //alert(this.key.indexOf(r.title))
                if (menuKeyLoc.indexOf(r.key) === -1) {
                    return false;
                } // Key not pressent. So, don't show
                if (menuKeyLoc.indexOf(r.key + "9") > -1) {
                    return false;// Key hide present, so don't show
                }
                if (menuKeyLoc.indexOf(r.key + "0") > -1) {
                    return false;
                }
                //if (menuKeyLoc.indexOf(r.key + "1") === -1) return true;
                //if (menuKeyLoc.indexOf(r.key + "2") === -1) return true;
                //if (x) {
                //  x = r.options['menuItem'] != undefined;
                //if (x && place) x = r.options[place] != undefined;
                //}
                return true;
            }
        );
        console.log(this.menuItems);
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
    OnProfile() {
        const dialogRef = this.dialog.open(OpenProfileEditor, {
            width: '70%',
            data: {
                pop_Head: "", pop_InputName: '', pop_PlaceHolder: "",
                pop_StartTime: '', pop_EndTime: '', pop_Roster: "",
                staffItem: Cookies.get('login_staffid'),
                email: Cookies.get('login_email')
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result === null) {

            } else if (typeof result === "undefined") {

            } else {

            }
        });
    }
    OnSignout() {

        this.SignOut(
            Cookies.get('login_profile'),
            Cookies.get('login_email'),
            Cookies.get('login_name'),
            Cookies.get('login_username'),
            Cookies.get('login_staffid')
        );


        // this.router.navigateByUrl('/pages/login');
    }
    SignOut(profile, email, name, user, staffid) {
        this.http.post("http://" + APIDomain + "/tim/SignOut",
            {
                "profile": profile,
                "email": email,
                "name": name,
                "user": user,
                "staffid": staffid
            }
        ).subscribe(
            result => {
                //Start Testing Sivaguru M on 16-03-2024
                this.clearvalues();
                window.location.href = "/pages/login";
                // this.router.navigateByUrl('/pages/login');
                //this.router.navigateByUrl("http://" + APIDomain + "");
                //End Testing
            }, error => {
                //this.SetStatus("Err2-" + error);//previously commented
                //Start Testing Sivaguru M on 16-03-2024
                this.clearvalues();
                window.location.href = "/pages/login";
                //this.router.navigateByUrl("http://" + APIDomain + "");
                //End Testing
            }
        );
    }

    async clearvalues() {
        this.global.EraseAll();
        setCookie('login_name', "");
        setCookie('login_email', "");
        setCookie('login_profile', "");
        setCookie('login_staffid', "");
        setCookie('login_password', "");
        setCookie('login_username', "");
        setCookie('menukey', "");
        this._signalrService.StopConnection();
    }

    checkItmaintenance() {
        var obj = {
            staffid: Cookies.get('login_staffid')
        }
        this.sc.checkItmaintancesc(obj).subscribe((res: any) => {
            console.log(res);
          
            if (res.Status == true) {
                if (res.Data == true) {
                    this.ngitmaintain = true;
                }
            }
            else {
                this.ngitmaintain = false;
            }
        })
    }

}
