
import {distinctUntilChanged, debounceTime,  catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';


import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCalendar } from '@angular/material/datepicker';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import Swal from 'sweetalert2';
import { PayslipComponent } from '../payslip/payslip.component';
import { Payscale_MasterComponent } from '../payscale_master/payscale_master.component';
import { MessageComponent } from '../../messages/message/message.component';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-payslip_settlement',
    templateUrl: 'payslip_settlement.component.html',
    styleUrls: ['payslip_settlement.component.css']
})
export class Payslip_SettlementComponent {
    myphoto: any;
    iYear: number;
    iMonth: number;
    iYearNow: number;
    iMonthNow: number;
    datespan: string;
    chkReleaseBonus: boolean = false;
    leaveEditChk: boolean = false;
    selectedFile: File;
    selected_row: string;
    response: string;
    staffTypes: string[] = ["Trainee", "OnRole"];
    lastAction: string = "";
    public postResponse: PostResponse;
    //selected_row_email: string;
    bIsEdit: boolean = false;
    public payslipSettlement: PayslipSettlement;
    public loadLeaveDataResponse: LoadLeaveDataResponse;
    m_MenuKey: string = "";
    selectedgrp: string = "All";
    updatestatus: string = "";
    bViewSelected: boolean = false;
    leaveDays: string = "";
    leaveDescription: string = "";
    month_name: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    sarReleaseDays: BreakItem[] = [];
    m_ReleaseDay: BreakItem = { "key": "", "value": "" };
    filteredLedgers: Observable<any[]>;
    filteredAddLedgers: Observable<any[]>;
    deviceChangedSubscription: Subscription = null;
    deviceChanged: Subject<string> = new Subject<string>();
    deviceChangedAddSubscription: Subscription = null;
    deviceAddChanged: Subject<string> = new Subject<string>();
    LedName: string = "";
    LedType: string = "";
    LedAmount: string = "";
    constructor(
        public dialogRef: MatDialogRef<Payslip_SettlementComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialog: MatDialog,
        private datePipe: DatePipe,
        public http: Http,
        private ref: ChangeDetectorRef,
         
        public global: GlobalProvider) {
        
        this.postResponse = {} as PostResponse;
        this.payslipSettlement = {} as PayslipSettlement;
        //this.payslipSettlement.part1 = {} as PayslipSettlementPart;
        //this.payslipSettlement.part2 = {} as PayslipSettlementPart;
        //this.payslipSettlement.parts = {} as PayslipSettlementPart[];
        //this.payslipSettlement.parts[0] = {} as PayslipSettlementPart;
        
        this.payslipSettlement.m_ApprovedBy1 = "";
        this.payslipSettlement.m_ApprovedBy2 = "";
        this.payslipSettlement.m_ApprovedBy3 = "";
        this.m_MenuKey = getCookie('menukey');
        this.loadLeaveDataResponse = {} as LoadLeaveDataResponse;

        this.bIsEdit = this.IsEdit();
        //-------------------------------
        this.GetActiveMonth();
        
        this.iYearNow = this.iYear;
        this.iMonthNow = this.iMonth;
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.data.email + "&dummy=" + this.global.iImageRandamize;

        this.sarReleaseDays.push(
            { "key": "", "value": "Hold" },
            { "key": "Hold", "value": "Hold" },
            { "key": "7th", "value": "07-05-2019" },
            { "key": "20th", "value": "20-05-2019" },
        );
    }
    ngOnInit() {
        //-------------------------------Auto dropdown
        this.deviceChangedSubscription =
            this.deviceChanged.pipe(
                debounceTime(100), // wait 300ms after the last event before emitting last event
                distinctUntilChanged(),) // only emit if value is different from previous value
                .subscribe(model => {
                    //this.model = model;
                    this.filteredLedgers = this.searchDevices(model, getCookie('login_profile'),"GetPayscaleLedgers");
                });
        this.filteredLedgers = this.searchDevices("", getCookie('login_profile'), "GetPayscaleLedgers");
        //-------------------------------Auto dropdown
        this.deviceChangedAddSubscription =
            this.deviceAddChanged.pipe(
                debounceTime(100), // wait 300ms after the last event before emitting last event
                distinctUntilChanged(),) // only emit if value is different from previous value
                .subscribe(model => {
                    //this.model = model;
                    this.filteredAddLedgers = this.searchDevices(model, getCookie('login_profile'), "GetPayscaleAddLedgers");
                });
        this.filteredAddLedgers = this.searchDevices("", getCookie('login_profile'), "GetPayscaleAddLedgers");
        //-------------------------------Auto dropdown END
        this.PayslipSettlement("");
    }
    GetPhoto() {
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.data.email + "&dummy=" + this.global.iImageRandamize;//Date.now().toString();
    }

    Active() {
        this.GetActiveMonth();    
        this.PayslipSettlement("");
    }
    GetActiveMonth() {
        var startDt = this.GetAttnStart();
        const dtNow = new Date();
        var dtStart = new Date(dtNow.getFullYear(), dtNow.getMonth(), startDt);
        dtStart.setDate(dtStart.getDate() - 1);
        
        if (dtStart > dtNow) {
            dtStart.setMonth(dtStart.getMonth() - 1);
        }
        this.iYear = dtStart.getFullYear();
        this.iMonth = dtStart.getMonth(); // Zero Index

        //m_Dt.setDate(m_Dt.getDate() - 1);
        //this.iYear = m_Dt.getFullYear();
        //this.iMonth = m_Dt.getMonth();

        /*
        const today = new Date();
        this.iYear = today.getFullYear();
        this.iMonth = today.getMonth();
        if (today.getDate() < 26) {
            this.iMonth--;
            if (this.iMonth < 0) {
                this.iMonth = 11;
                this.iYear--;
            }
        }
        this.iMonth++;
        */
    }
    Forward() {
        this.iMonth++;
        /*
        if (this.iMonth > 12) {
            this.iMonth = 1;
            this.iYear++;
        }
        */
        if (this.iMonth > 11) {// Issue fixed on 7th March, 2020 after Jan Payslip was not visible
            this.iMonth = 0;
            this.iYear++;
        }
        this.PayslipSettlement("");
    }
    Reverse() {
        this.iMonth--;
        /*
        if (this.iMonth < 1) {
            this.iMonth = 12;
            this.iYear--;
        }
        */
        if (this.iMonth < 0) {// Issue fixed on 7th March, 2020 after Jan Payslip was not visible
            this.iMonth = 11;
            this.iYear--;
        }
        this.PayslipSettlement("");
    }
    IsEdit() {
        if (this.IsSA()) return true;
        var menukey = getCookie('menukey');
        //if (menukey.indexOf("a0-1") > -1) return true;
        if (menukey.indexOf("u4-2") > -1) return true;
        return false;
    }

    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }
    IsSAemail(mail) {
        return getCookie('login_profile') === mail;
    }
    IsAdmin() {
        return getCookie("menukey").indexOf("a0-1") > -1;
    }
    GetAttnStart() {
        if (getCookie('attnstartdate') == null || getCookie('attnstartdate').length === 0) {
            return 1;
        }
        var x = parseInt(getCookie('attnstartdate'));
        if (!isNaN(x)) return x;
        return 1;
    }
    GetDateSpan() {
        var startDt = this.GetAttnStart();
        var m_DtSelected = new Date(this.iYear, this.iMonth, startDt);
        m_DtSelected.setMonth(m_DtSelected.getMonth() - 1);

        var m_DtSelectedTo = new Date(this.iYear, this.iMonth, startDt);
        m_DtSelectedTo.setDate(m_DtSelectedTo.getDate() - 1);

        try {
            this.datespan = this.datePipe.transform(m_DtSelected, "dd-MM-yyyy") +
                " - " +
                this.datePipe.transform(m_DtSelectedTo, "dd-MM-yyyy");
        } catch (x) {
            this.datespan = "";
        }

        

        /*
        var y0 = this.iYear;
        var m0 = this.iMonth - 1;
        if (m0 < 1) { y0--; m0 = 12; }
        this.datespan = "26/" + m0 + "/" + y0 + " - " + "25/" + this.iMonth + "/" + this.iYear;
        */
    }
    Swal(title, html) {
        Swal.fire({
            title: title,
            text: "Please select a Roster to remove",
            html: html,
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i>',
            confirmButtonAriaLabel: 'Thumbs up, great!',
        });//.catch(swal.noop)
    }
    Payslip(email,staffid,preview) {
        if (!this.payslipSettlement.m_ApprovedBy2 ||
            this.payslipSettlement.m_ApprovedBy2.length === 0) {
            var title = "<span style='font-size:large'>Sorry!!! </span>";
            var html = 'You can preview only after attendance is approved by <b>HR</b> and <b>Production</b> for the selected month';
            this.Swal(title, html);
            return;
        }
        const dialogRef = this.dialog.open(PayslipComponent, {
            minWidth: '50em',
            width: '50%',
            data: {
                email: email, staffid: staffid, preview: preview,
                year: this.iYear, month: this.iMonth + 1, releasebonus: this.chkReleaseBonus
            }
        });
        dialogRef.afterClosed().subscribe((result) => {

            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {

                }
            }
        });
    }
    PayslipSettlement(mode: string) {
        if (mode === 'approve') {
            if (!this.IsAllowed('u8-2', 'u8-2')) {
                var title = "<span style='font-size:large'>Sorry!!! </span>";
                var html = 'You have no permission for this action';
                this.Swal(title, html);
                return;
            }
            if (this.payslipSettlement.m_ApprovedBy2 == null ||
                this.payslipSettlement.m_ApprovedBy2.length === 0) {
                var title = "<span style='font-size:large'>Sorry!!! </span>";
                var html = 'You can do this only after <b>HR</b> and <b>Production</b> approvals';
                this.Swal(title, html);
                return;
            }
            Swal.fire({
                title: 'Final Approval',
                html: "Accounts ledgers will be updated for payment.<br>Are you sure?",
            /*type: 'warning',*/
                showCancelButton: true,
               /* confirmButtonClass: 'btn btn-success',*/
            /*cancelButtonClass: 'btn btn-danger',*/
                confirmButtonText: 'Yes, Go ahead!',
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    this.GoForApproval(mode,'');
                }
            });
        } else if (mode === 'cancel') {
            Swal.fire({
                title: 'CAUTION',
                html: "All attandence approvals will be revoked.<br>Accounts ledgers will be revered.<br>Can't UNDO. <br>Are you sure?",
            /*type: 'warning',*/
                showCancelButton: true,
               /* confirmButtonClass: 'btn btn-success',*/
            /*cancelButtonClass: 'btn btn-danger',*/
                confirmButtonText: 'Yes, Go ahead!',
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    this.GoForApproval(mode,'');
                }
            });
        } else {
            this.GoForApproval(mode,'');
        }
    }
    GoForApproval(mode, addled) {
        if (mode === "addpayslip") {
            var str = "";
            if (this.LedName.length === 0) str = "Ledger name is empty";
            if (this.LedType.length === 0) str = "Please select type of ledger";
            if (this.LedAmount.length === 0) str = "Amount is empty";
            var x = parseInt(this.LedAmount);
            if (isNaN(x) || x === 0) str = "Amount is empty";
            if (str) {
                Swal.fire({
                    title: str,
                    buttonsStyling: false,
                /*confirmButtonClass: "btn btn-success"*/
                });//.catch(swal.noop)
                return;
            }
        }

        this.GetDateSpan();
        this.response = "Please wait...";
        this.http.post("http://" + APIDomain + "/accessmanager/PayslipSettlement",
            {
                profile: getCookie('login_profile'),
                email: getCookie('login_email'),
                loginstaffid: getCookie('login_staffid'),
                mode: mode,
                staffid: this.data.staffid,
                staffemail: this.data.email,
                yearName: this.iYear,
                monthName: this.iMonth+1,
                ReleaseDay: this.m_ReleaseDay.value,
                ledName: this.LedName,  // additional ledgers
                ledType: this.LedType,  //  
                ledAmount: this.LedAmount,  //
                addled: addled, // additional ledger to be deleted, if asked for
                releasebonus: this.chkReleaseBonus,
            }
        ).subscribe(
            result => {
                this.response = "";
                this.payslipSettlement = result.json() as PayslipSettlement;
                this.m_ReleaseDay.value = this.payslipSettlement.m_FundsReleaseDate;

                if (this.payslipSettlement.result.length > 0) {
                    Swal.fire({
                        title: this.payslipSettlement.result,
                        buttonsStyling: false,
                    /*confirmButtonClass: "btn btn-success"*/
                    });//.catch(swal.noop)
                } else {
                    this.LedName = "";
                    this.LedType = "";
                    this.LedAmount = "";
                }
            }, error => {
                this.response = error;
            }
        );
    }
    OnLeaveEdit(x) {
    }


    onNoClick(dataOut: DialogData): void {
        dataOut.pop_Head = this.lastAction;
        this.dialogRef.close(dataOut);
    }
    onYesClick(dataIn: DialogData): void {
        if (dataIn.pop_Head === 'Shift') {
            if (!this.IsValidTime(dataIn.pop_StartTime)) {
                this.response = "Invalid Start Time (HH:SS)";
                return;
            }
            if (!this.IsValidTime(dataIn.pop_EndTime)) {
                this.response = "Invalid End Time (HH:SS)";
                return;
            }
        }
        this.dialogRef.close(dataIn);
    }
    IsValidTime(timeString: string): boolean {
        //var pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;   // HH:MM:SS
        var pattern = /^([0-9]|[012][0-9]):[0-5][0-9]$/;   // HH:MM:SS
        if (!timeString.match(pattern)) return false;
        return true;
    }
    ngOnDestroy() {
        if (this.deviceChangedSubscription != null) this.deviceChangedSubscription.unsubscribe();
        if (this.deviceChangedAddSubscription != null) this.deviceChangedAddSubscription.unsubscribe();
    }
    PaySlipMaster(name, key,startdate) {

        const dialogRef = this.dialog.open(Payscale_MasterComponent, {
            minWidth: '50em',
            width: '90%',
            data: {
                pop_Head: "", pop_InputName: '', pop_PlaceHolder: "",
                pop_StartTime: '', pop_EndTime: '', pop_Roster: "",
                name: name, key: key, startdate: startdate
            }
        });
        dialogRef.afterClosed().subscribe((result) => {

            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {

                }
            }
        });
    }
    OnReleaseDay(e) {
        this.m_ReleaseDay = e;
    }
    IsAllowed(key1, key2) {
        if (this.m_MenuKey.indexOf(key1) > -1) return true;
        if (this.m_MenuKey.indexOf(key2) > -1) return true;
        return false;
    }
    KeyPressAddLedger(value: string) {
        //console.log("KeyPress:"+value);
        this.filteredAddLedgers = null;
        this.deviceAddChanged.next(value);
    }
    KeyPressDevice(value: string) {
        //console.log("KeyPress:"+value);
        this.filteredLedgers = null;
        this.deviceChanged.next(value);
    }
    ShowAddLedgers() {
        this.filteredAddLedgers = this.searchDevices("", getCookie('login_profile'), "GetPayscaleAddLedgers");
    }
    ShowFreeStaffs() {
        this.filteredLedgers = this.searchDevices("", getCookie('login_profile'), "GetPayscaleLedgers");
    }
    searchDevices(search: string, profile: string,func:string): Observable<PayLedger[]> {
        let apiURL = `${'http://' + APIDomain + '/accessmanager/' + func + ''}`;
        const params = new URLSearchParams();
        params.set('search', search);
        params.set('profile', profile);

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        console.log("GetPayscaleLedgers");
        const options = new RequestOptions({
            headers: headers,
            responseType: ResponseContentType.Json,
            params: params,
            withCredentials: false
        });
        console.log(apiURL + "?" + params);
        return this.http.post(apiURL, {}, options).pipe(
            map(res => {
                return res.json().sarLedgers.map(item => {
                    return new PayLedger(
                        item.Name,
                        item.paymode,
                        item.Type,
                        item.Amount
                    );
                });
            }));
    }
    GoToProfile() {
        this.data.pop_Head = "loadprofile";
        this.dialogRef.close(this.data);
    }
    OpenMessage(staffid) {
        this.ref.detach();
        //string pay_session = "pay_" + staffid + "_" + iYearName + "_" + (iMonthName - 1);
        var session = "pay_" + staffid + "_" + this.iYear + "_" + (this.iMonth);

        const dialogRef = this.dialog.open(MessageComponent, {
            minWidth: '50em',
            width: '70%',
            data: {
                session: session
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.ref.reattach();
            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {

                }
            }
        });
    }
}
export interface DialogData {
    session: string;
    response: string;
    pop_Head: string;
    pop_Roster: string;
    pop_InputName: string;
    pop_PlaceHolder: string;
    pop_StartTime: string;
    pop_EndTime: string;
    email: string;
    staffid: string;
}
export interface AllowedListResponse {
    status: boolean;
    result: string;
    groups: string[];
    rows: ListRow[];
}
export interface ListRow {
    imei: string;
    regno: string;
    group: string;
    check: boolean;
}
export interface GetStaffsResponse {
    status: boolean;
    result: string;
    total_count: number;
    items: StaffItem[];
}

export interface StaffItem {
    m_id: number;
    m_StaffID: string;
    m_FName: string;
    m_Activity: string;
    m_ActivityTime: string;
    m_IP: string;
    m_Lat: number;
    m_Lng: number;
    m_WorkTime: string;
    m_Username: string;
    m_ReasonHead: string;
    m_ReasonNote: string;
    m_Email: string;
    m_Mobile: string;
    m_Designation: string;
    m_Roll: string;
    m_Team: string;
    m_Base: string;
    m_Type: string;
    m_ReportToFunctional: string;
    m_ReportToAdministrative: string;
    m_MenuKey: string;
    m_Band: string;
    m_Grade: string;
    m_Mrs: string;
    m_ViewSelected: number;
    m_PayscaleName: string;
    m_PayscaleKey: number;
    m_PayscaleStartDate: number;

}

export interface PostResponse {
    status: boolean;
    result: string;
}
interface LoadLeaveDataResponse {
    status: boolean;
    result: string;
    StaffName: string;
    Mrs: string;
    LeaveStatus: LeaveItem[];
    leaves: Leaves;
}
interface LeaveItem {
    Code: string;
    Status: number;
}
interface Leaves {
    CL: Leave;
    SL: Leave;
    PL: Leave;
    APL: Leave;
    COff: Leave;
    AWOff: Leave;
    LOP: Leave;
    ALOP: Leave;
    MatL: Leave;
    PatL: Leave;
}
interface Leave {
    used: number;
    pending: number;
    max: number;
}
interface GetLeaveHistoryResponse {
    status: boolean;
    result: string;
    rows: LeaveHistoryRow[];
}
interface LeaveHistoryRow {
    time: string;
    dt: string;
    description: string;
    status: number;
    pending: number;
    credit: number;
    used: number;
}
interface PayslipSettlementPart {
    m_DateStart: number;
    m_DateEnd: number;
    m_PayscaleName: string;
    m_PayscaleKey: number;
    m_PayscaleStartDate: number;

    m_WorkingDays: number;
    m_OFFs: number;
    m_Leaves: number;
    m_ALOPs: number;
    m_LOPs: number;
    m_LateSeconds: number;
    m_LOPBasedOnDelay: number;
    m_ActualWorkingDays: number;
    m_DaysToBePaidTotal: number;
    m_RosterOptions: string;
    m_RosterOptionsResult: string;
    m_BonusTableReleaseVoucher: number;

}
interface PayslipSettlement {
    status: boolean;
    result: string;

    m_ApprovedBy1: string;
    m_ApprovedByTime1: string;
    m_ApprovedBy2: string;
    m_ApprovedByTime2: string;
    m_ApprovedBy3: string;
    m_ApprovedByTime3: string;
    m_ApprovedBy4: string;
    m_ApprovedByTime4: string;
    parts: PayslipSettlementPart[];
    //part1: PayslipSettlementPart;
    //part2: PayslipSettlementPart;

    ExistsInList: string;
    m_FundsReleaseDate: string;
    addLedgers: PayLedger[];
    m_BonusTableReleaseVoucher: number;
}

interface BreakItem {
    key: string;
    value: string;
}
class PayLedger {
    constructor(
        public Name: string,
        public paymode: number,
        Type: string,
        Amount: number
    ) { }

}