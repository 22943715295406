import { Component, OnInit } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCalendar } from '@angular/material/datepicker';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PayslipComponent } from './payslip/payslip.component';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.scss']
})
export class PayrollComponent implements OnInit {

    constructor(
        public dialog: MatDialog
    ) { }

      ngOnInit() {
    }
    PaySlip() {
        const dialogRef = this.dialog.open(PayslipComponent, {
            minWidth: '50em',
            width: '50%',
            data: {
                profile: "", email: '', action: ""
            }
        });
        dialogRef.afterClosed().subscribe((result) => {

            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {

                }
            }
        });
        
    }
}
