
import {filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { SignalrService } from './signalr.service';
import { GlobalProvider, HubObject } from './providers/global/global';
//import { set as setCookie, get as getCookie } from 'es-cookie';
import { set as setCookie, get as getCookie } from 'es-cookie';
//import { HotkeysService, Hotkey } from 'angular2-hotkeys';
//import { timer } from 'rxjs/observable/timer';
@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
    private _signalrHubObjectObservable = null;
    private _router: Subscription;
    subscribe: any;
    constructor(
        private router: Router,
        private _signalrService: SignalrService,
        public global: GlobalProvider
        /*,
        private _hotkeysService: HotkeysService*/
        ) {
        this.subscribe = null;//'meta+shift+g',alt+shift+s
        /*
        this._hotkeysService.add(new Hotkey('f4', (event: KeyboardEvent): boolean => {
            console.log('Typed hotkey');
            alert('Typed hotkey');
            return false; // Prevent bubbling
        }));
        */
        /*
        if (getCookie("login_email").length === 0) {
            this.router.navigateByUrl('/pages/login');
        } else {
            this.router.navigateByUrl('/dashboard_accessmanager');
        }*/
        
    }

    ngOnInit() {
        this.global.UpdateDomain();
        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            const body = document.getElementsByTagName('body')[0];
            const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
            if (body.classList.contains('modal-open')) {
                body.classList.remove('modal-open');
                modalBackdrop.remove();
            }

            this._signalrService.startConnection();
            if (!getCookie("login_email")) {
            //if (getCookie("login_email").length === 0) {
                //this.router.navigateByUrl('/pages/login');
            } else {
                //this.router.navigateByUrl('/dashboard');
            }
        });
        this._signalrHubObjectObservable = this._signalrService.hubObjectChangedObservable
            .subscribe((obj: HubObject) => {

                if (obj.Mode === "leaves") {
                    this.global.messageLeavesCount = obj.lData;
                    this.playAudio();
                } else if (obj.Mode === "times") {
                    this.global.messageTimesCount = obj.lData;
                    this.playAudio();
                }
            });
            
        this.router.navigateByUrl('/pages/login');//This is the starting of the application commented by Sivaguru M CHC1704 on 16-03-2024
    }
    playAudio() {
        let audio = new Audio();
        audio.src = "./assets/wav/received.wav";
        audio.load();
        audio.play();
    }
    ngOnDestroy() {
        if (this._signalrHubObjectObservable != null) this._signalrHubObjectObservable.unsubscribe();
    }
}
