import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';

import {
    DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette, MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCalendar } from '@angular/material/datepicker';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

//___________________________________________________
@Component({
    selector: 'payslip.component',
    templateUrl: 'payslip.component.html',
    styleUrls: ['payslip.component.css'],
    providers: [
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class PayslipComponent {
    //status: string = "";
    myphoto: any;
    selectedFile: File;
    selected_row: string;
    response: string;
    lastAction: string = "";
    public postResponse: PostResponse;
    public loadPayslip: LoadPayslip;
    timeNow: number;
    bIsEdit: boolean = false;
    hidePrint: boolean = false;
    iYear: number;
    iMonth: number;
    constructor(
        public dialogRef: MatDialogRef<PayslipComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PayslipData,
        public http: Http,
         
        private datePipe: DatePipe,
        public global: GlobalProvider) {
            this.timeNow =Date.now();
        this.postResponse = {} as PostResponse;
        this.loadPayslip = {} as LoadPayslip;
            //-------------------------------

            //-------------------------------
            this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
                getCookie('login_profile') + "_" + this.data.email + "&dummy=" + this.global.iImageRandamize;
    }
    ngOnInit() {
        this.iYear = Number(this.data.year);
        this.iMonth = Number(this.data.month);
        this.LoadPayslip();
    }
    GetProfileInfo(key) {
        var val = getCookie(key);
        if (val === null) return "";
        return val;
    }
    Reverse() {
        this.iMonth--;
        if (this.iMonth < 1) {
            this.iMonth = 12;
            this.iYear--;
        }
        this.LoadPayslip();
    }
    Active() {
        this.iMonth = 0;
        this.iYear = 0;
        this.LoadPayslip();
    }
    Forward() {
        this.iMonth++;
        if (this.iMonth > 12) {
            this.iMonth = 1;
            this.iYear++;
        }
        this.LoadPayslip();
    }
    LoadPayslip() {
        if (typeof (this.loadPayslip.PageNo) === 'undefined') this.loadPayslip.PageNo = 1;
        this.response = "Please wait...";
        this.http.post("http://" + APIDomain + "/AccessManager/LoadPayslip",
            {
                "profile": getCookie('login_profile'),
                "email": this.data.email,
                "staffid": this.data.staffid,
                "year": this.iYear,
                "month": this.iMonth,
                "preview": this.data.preview,
                "releasebonus": this.data.releasebonus,
                "pagerequested": this.loadPayslip.PageNo + "",
                "attnstartdate": getCookie('attnstartdate') ? "1" : getCookie('attnstartdate'),
                "menukey": getCookie('menukey') == null ? "" : getCookie('menukey')
            }
        ).subscribe(result => {
            this.loadPayslip = result.json() as LoadPayslip;
            console.log(this.loadPayslip);

            this.response = this.loadPayslip.result;
            this.iYear = this.loadPayslip.iYear;
            this.iMonth = this.loadPayslip.iMonth;
            if (this.loadPayslip.status) {

            } else {

            }
        }, error => {
            console.error(error);
            this.response = error;
        }
        );
    }
    onNoClick(dataOut: PayslipData): void {
        dataOut.action = this.lastAction;
        this.dialogRef.close(dataOut);
    }
    onYesClick(dataIn: PayslipData): void {
        /*
        if (dataIn.pop_Head === 'Shift') {
            if (!this.IsValidTime(dataIn.pop_StartTime)) {
                this.response = "Invalid Start Time (HH:SS)";
                return;
            }
            if (!this.IsValidTime(dataIn.pop_EndTime)) {
                this.response = "Invalid End Time (HH:SS)";
                return;
            }
        }
        */
        this.dialogRef.close(dataIn);
    }
    ngOnDestroy() {

    }
    
    GetNow() {
        return this.timeNow;
    }
    ToPDFNew() {
        var params = "profile=" + getCookie('login_profile') +
            "&email=" + this.data.email +
            "&staffid=" + this.data.staffid +
            "&year=" + this.iYear +
            "&month=" + this.iMonth +
            "&preview=" + this.data.preview +
            "&releasebonus=false" + //this.data.releasebonus +
            "&pagerequested=" + this.loadPayslip.PageNo + "" +
            "&attnstartdate=" + (getCookie('attnstartdate') ? "1" : getCookie('attnstartdate')) +
            "&menukey=" + (getCookie('menukey') == null ? "" : getCookie('menukey'));
        window.open("http://" + APIDomain + "/AccessManager/LoadPayslipPDF?" + params, '_blank');
        /*
        this.response = "Please wait...";
        this.http.get("http://" + APIDomain + "/AccessManager/LoadPayslipPDF",
            {
                
            }
        ).subscribe(result => {
            console.log("Eugene Success");
            console.log(result);

        }, error => {
                console.log("Eugene Error");
            console.error(error);
            this.response = error;
        }
        );*/
        
    }

    ToPDF() {
        if (!this.hidePrint) {
            this.hidePrint = true;
            setTimeout(() => { this.ToPDF(); }, 500);
            return;
        }
        var data = document.getElementById('contentToPDF');
        html2canvas(data).then(canvas => {
            // Few necessary setting options  
            var imgWidth = 212;//208;
            var pageHeight = 295;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            const contentDataURL = canvas.toDataURL('image/png')
            //let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
            let pdf = new jspdf('l', 'mm', [350, 600]);
            /*
            var pdf = new jspdf({
                orientation: 'landscape',
                unit: 'in',
                format: [4, 2]
            })
            */
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
            pdf.save('MYPdf.pdf'); // Generated PDF   
            this.hidePrint = false;
        });
    }
    TogglePage() {
        if (this.loadPayslip.Pages > 1) {
            this.loadPayslip.PageNo++;
            if (this.loadPayslip.PageNo > this.loadPayslip.Pages)
                this.loadPayslip.PageNo = 1;
        }
        this.LoadPayslip();
    }
}
export interface PostResponse {
    status: boolean;
    result: string;
}
export interface LoadPayslip {
    status: boolean;
    result: string;
    profile: string;
    email: string;
    original: number;
    //payscaleName: string;
    //payscaleKey: number;
    m_PayscaleName: string;
    m_PayscaleKey: number;
    m_PayscaleStartDate: number;
    staffid: string;
    name: string;
    band: string;
    grade: string;
    team: string;
    designation: string;
    epf_uan: string;
    sb_acc: string;
    m_Bank: string;
    m_Branch: string;
    CTC: string;

    m_WorkingDays: number;
    m_OFFs: number;
    m_Leaves: number;
    m_ALOPs: number;
    m_LOPs: number;
    m_LateSeconds: number;
    m_LOPBasedOnDelay: number;
    m_ActualWorkingDays: number;
    m_DaysToBePaidTotal: number;
    m_RosterOptions: string;
    m_RosterOptionsResult: string;

    iYear: number;
    iMonth: number;
    sMonth: string;
    ratesCr: PayLedger[];
    deductsDr: PayLedger[];
    earns: PayLedger[];
    deducts: PayLedger[];

    m_DateStart: number;
    m_DateEnd: number;
    m_CrTot: number;
    m_DrTot: number;
    m_EarnsTot: number;
    m_DeductsTot: number;
    m_CreatedTime: string;
    PageNo: number;
    Pages: number;
    NetPayWords: string;
    m_VchNo: number;
}
export interface PayLedger {
    Name: string;
    Amount: string;
    m_Security: number;
}
export interface PayslipData {
    profile: string;
    email: string;
    staffid: string;
    action: string;
    year: string;
    month: string;
    preview: string;
    releasebonus: boolean;
}

class ReportingToItem {
    constructor(
        public Name: string,
        public Roll: string,
        public Base: string,
        public Email: string,
    ) { }
}