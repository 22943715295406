import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIDomain } from 'app/providers/global/global';

@Injectable({
  providedIn: 'root'
})
export class ItMaintenanceService {

  apiurl = "http://" + APIDomain;


  public uploadfile='/documents/SourceFileUpload';
  public checkItmaintance='/Tim/CheckSourceMenu';



  constructor(private http:HttpClient) { }

  uploadfilesc(obj){
    return this.http.post(this.apiurl + this.uploadfile,obj, {
      reportProgress: true,
      observe: 'events'
    });  
  }

  checkItmaintancesc(obj){
    return this.http.post(this.apiurl + this.checkItmaintance,obj);
  }

  
}
