import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';


import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';


import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';



import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { AllowedListComponent } from './allowedlist.component';
import { OpenProfileEditor } from './profile-editor';
import { LeavesComponent } from './leaves.component';

@Component({
    selector: 'permission-editor',
    templateUrl: 'permission-editor.html',
    styleUrls: ['permission-editor.css']
})
export class OpenPermissionEditor {
    //status: string = "";
    myphoto: any;
    selectedFile: File;
    selected_row: string;
    response: string;
    menukey_a: number[] = [];   //  Admin
    menukey_b: number[] = [];  //  Accounts
    menukey_c: number[] = [];  //  Calendar
    menukey_d: number[] = [];   //  Dashboard
    menukey_f: number[] = [];   //  Fleet
    menukey_g: number[] = [];  //  Messages
    menukey_h: number[] = [];  //  HR Reports
    menukey_l: number[] = [];   //  Logistics
    menukey_m: number[] = [];   //  Map
    menukey_o: number[] = [];  //  Leave & OT
    menukey_p: number[] = [];  //  Payscales
    menukey_r: number[] = [];   //  Fleet Reporters
    menukey_s: number[] = [];  //  MeterBox
    menukey_t: number[] = [];  //  Settings
    menukey_u: number[] = [];   //  Users
    menukey_w: number[] = [];  //  Downloads
    menukey_x: number[] = [];   //  Rosters
    menukey_z: number[] = [];   //  Assorted
    
    lastAction: string = "";
    public loadPermissionData: LoadPermissionData;
    bIsEdit: boolean;
    sarTeamsPermissions: TeamPermissions[] = [];
    headSelected: string = "";
    ActiveTab: number = 0;
    globalstate: number = 0;
    constructor(
        public dialogRef: MatDialogRef<OpenPermissionEditor>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public http: Http,
        
        public global: GlobalProvider) {
        this.loadPermissionData = {} as LoadPermissionData;
        this.bIsEdit = this.IsEdit();
        //-------------------------------
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.data.staffItem.m_Email + "&dummy=" + this.global.iImageRandamize;
        
    }
    ngOnInit() {
        //this.DecodeMenuKey(this.data.staffItem.m_MenuKey);
        this.LoadPermissionData("","",false);
    }
    onNoClick(dataOut: DialogData): void {
        dataOut.pop_Head = this.lastAction;
        this.dialogRef.close(dataOut);
    }
    IsSAemail(mail) {
        return getCookie('login_profile') === mail;
    }
    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }

    IsEdit() {
        if (this.IsSA()) return true;
        var menukey = getCookie('menukey');
        //if (menukey.indexOf("a0-1") > -1) return true;
        if (menukey.indexOf("u5-2") > -1) return true;
        return false;
    }
    LoadPermissionData(head,selected,status) {
        this.headSelected = head;
        this.response = "Please wait...";
        this.http.post("http://" + APIDomain + "/Accessmanager/LoadPermissionData", 
            {
                "profile": getCookie('login_profile'),
                "email": this.data.staffItem.m_Email,
                "staffid": this.data.staffItem.m_StaffID,
                "head": head,
                "selected": selected,
                "status": status
            }
        ).subscribe(result => {
            this.loadPermissionData = result.json() as LoadPermissionData;
            if (this.loadPermissionData.status) {
                this.response = "";
                this.data.staffItem.m_MenuKey = this.loadPermissionData.sParam1;

                this.DecodeMenuKey(this.loadPermissionData.sParam1);

            } else {
                this.response = this.loadPermissionData.result;
                console.log("false-" + this.response);
                alert("Failed-" + this.loadPermissionData.result);
            }
        }, error => {
            console.error(error);
            this.response = error;
            alert("FAILED-" + error);
        }
        );
    }
    OnUpdateSecurity() {
        this.response = "Please wait...";
        var qs = "";
        const params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('email', this.data.staffItem.m_Email);//this.userItem.m_Email);
        params.append('securitystring', this.GetSecurityString());

        this.http.post("http://" + APIDomain + "/Tim/Update_Security_staff" + qs, params
        ).subscribe(result => {
            this.loadPermissionData = result.json() as LoadPermissionData;
            if (this.loadPermissionData.status) {
                this.response = "Done";
                this.data.staffItem.m_MenuKey = this.loadPermissionData.result;
                //this.Merge();
                //alert(this.loadPermissionData.result)
            } else {
                this.response = this.loadPermissionData.result;
                console.log("false-" + this.response);
                alert("Failed-" + this.loadPermissionData.result);
            }
            
        }, error => {
            console.error(error);
            this.response = error;
            alert("FAILED-" + error);
        }
        );
    }
    DecodeMenuKey(key) {
        for (var i = 0; i < 1; i++) this.menukey_a[i] = 0;
        for (var i = 0; i < 1; i++) this.menukey_d[i] = 0;
        for (var i = 0; i < 1; i++) this.menukey_m[i] = 0;
        for (var i = 0; i < 1; i++) this.menukey_c[i] = 0;
        for (var i = 0; i < 3; i++) this.menukey_g[i] = 0;
        for (var i = 0; i < 1; i++) this.menukey_o[i] = 0;
        for (var i = 0; i < 10; i++) this.menukey_u[i] = 0;
        for (var i = 0; i < 2; i++) this.menukey_x[i] = 0;
        for (var i = 0; i < 6; i++) this.menukey_s[i] = 0;
        for (var i = 0; i < 5; i++) this.menukey_h[i] = 0;
        for (var i = 0; i < 2; i++) this.menukey_t[i] = 0;
        for (var i = 0; i < 3; i++) this.menukey_b[i] = 0;
        for (var i = 0; i < 2; i++) this.menukey_p[i] = 0;
        for (var i = 0; i < 6; i++) this.menukey_f[i] = 0;
        for (var i = 0; i < 2; i++) this.menukey_r[i] = 0;
        for (var i = 0; i < 6; i++) this.menukey_l[i] = 0;
        for (var i = 0; i < 1; i++) this.menukey_w[i] = 0;
        for (var i = 0; i < 7; i++) this.menukey_z[i] = 0;

        for (var i = 0; i < 1; i++) { var pos = key.indexOf("a" + i + "-"); if (pos > -1) { this.menukey_a[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_a[i] = 9; } }
        for (var i = 0; i < 1; i++) { var pos = key.indexOf("d" + i + "-"); if (pos > -1) { this.menukey_d[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_d[i] = 9; } }
        for (var i = 0; i < 1; i++) { var pos = key.indexOf("m" + i + "-"); if (pos > -1) { this.menukey_m[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_m[i] = 9; } }
        for (var i = 0; i < 1; i++) { var pos = key.indexOf("c" + i + "-"); if (pos > -1) { this.menukey_c[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_c[i] = 9; } }
        for (var i = 0; i < 3; i++) { var pos = key.indexOf("g" + i + "-"); if (pos > -1) { this.menukey_g[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_g[i] = 9; } }
        for (var i = 0; i < 1; i++) { var pos = key.indexOf("o" + i + "-"); if (pos > -1) { this.menukey_o[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_o[i] = 9; } }
        for (var i = 0; i < 10; i++) { var pos = key.indexOf("u" + i + "-"); if (pos > -1) { this.menukey_u[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_u[i] = 9; } }
        for (var i = 0; i < 2; i++) { var pos = key.indexOf("x" + i + "-"); if (pos > -1) { this.menukey_x[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_x[i] = 9; } }
        for (var i = 0; i < 6; i++) { var pos = key.indexOf("s" + i + "-"); if (pos > -1) { this.menukey_s[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_s[i] = 9; } }
        for (var i = 0; i < 5; i++) { var pos = key.indexOf("h" + i + "-"); if (pos > -1) { this.menukey_h[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_h[i] = 9; } }
        for (var i = 0; i < 2; i++) { var pos = key.indexOf("t" + i + "-"); if (pos > -1) { this.menukey_t[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_t[i] = 9; } }
        for (var i = 0; i < 3; i++) { var pos = key.indexOf("b" + i + "-"); if (pos > -1) { this.menukey_b[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_b[i] = 9; } }
        for (var i = 0; i < 2; i++) { var pos = key.indexOf("p" + i + "-"); if (pos > -1) { this.menukey_p[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_p[i] = 9; } }
        for (var i = 0; i < 6; i++) { var pos = key.indexOf("f" + i + "-"); if (pos > -1) { this.menukey_f[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_f[i] = 9; } }
        for (var i = 0; i < 2; i++) { var pos = key.indexOf("r" + i + "-"); if (pos > -1) { this.menukey_r[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_r[i] = 9; } }
        for (var i = 0; i < 6; i++) { var pos = key.indexOf("l" + i + "-"); if (pos > -1) { this.menukey_l[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_l[i] = 9; } }
        for (var i = 0; i < 1; i++) { var pos = key.indexOf("w" + i + "-"); if (pos > -1) { this.menukey_w[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_w[i] = 9; } }
        for (var i = 0; i < 7; i++) { var pos = key.indexOf("z" + i + "-"); if (pos > -1) { this.menukey_z[i] = isNaN(+key.substr(pos + 3, 1)) ? 0 : +key.substr(pos + 3, 1) } else { this.menukey_z[i] = 9; } }
    }

    GetSecurityString() {
        var sSec = "";
        for (var i = 0; i < 1; i++) { sSec += "a" + i + "-"; sSec += this.menukey_a[i]; sSec += ","; }
        for (var i = 0; i < 1; i++) { sSec += "d" + i + "-"; sSec += this.menukey_d[i]; sSec += ","; }
        for (var i = 0; i < 1; i++) { sSec += "m" + i + "-"; sSec += this.menukey_m[i]; sSec += ","; }
        for (var i = 0; i < 1; i++) { sSec += "c" + i + "-"; sSec += this.menukey_c[i]; sSec += ","; }
        for (var i = 0; i < 3; i++) { sSec += "g" + i + "-"; sSec += this.menukey_g[i]; sSec += ","; }
        for (var i = 0; i < 1; i++) 
            { 
                sSec += "o" + i + "-";
                sSec += this.menukey_o[i];
                sSec += ",";              
        
            }
        for (var i = 0; i < 10; i++) { sSec += "u" + i + "-"; sSec += this.menukey_u[i]; sSec += ","; }
        for (var i = 0; i < 2; i++) { sSec += "x" + i + "-"; sSec += this.menukey_x[i]; sSec += ","; }
        for (var i = 0; i < 6; i++) { sSec += "s" + i + "-"; sSec += this.menukey_s[i]; sSec += ","; }
        for (var i = 0; i < 5; i++) { sSec += "h" + i + "-"; sSec += this.menukey_h[i]; sSec += ","; }
        for (var i = 0; i < 2; i++) { sSec += "t" + i + "-"; sSec += this.menukey_t[i]; sSec += ","; }
        for (var i = 0; i < 3; i++) { sSec += "b" + i + "-"; sSec += this.menukey_b[i]; sSec += ","; }
        for (var i = 0; i < 2; i++) { sSec += "p" + i + "-"; sSec += this.menukey_p[i]; sSec += ","; }
        for (var i = 0; i < 6; i++) { sSec += "f" + i + "-"; sSec += this.menukey_f[i]; sSec += ","; }
        for (var i = 0; i < 2; i++) { sSec += "r" + i + "-"; sSec += this.menukey_r[i]; sSec += ","; }
        for (var i = 0; i < 6; i++) { sSec += "l" + i + "-"; sSec += this.menukey_l[i]; sSec += ","; }
        for (var i = 0; i < 1; i++) { sSec += "w" + i + "-"; sSec += this.menukey_w[i]; sSec += ","; }
        for (var i = 0; i < 7; i++) { sSec += "z" + i + "-"; sSec += this.menukey_z[i]; sSec += ","; }
        return sSec;
    }
    //  c- Sub item count(normally 2. empty,check,edit) size- Normally, position of the menu
    ClkBut_a(c, size) 
    { 
        if ((++this.menukey_a[c]) > size)
             this.menukey_a[c] = 0; 
            this.res(this.menukey_a[c]); 
        }
    ClkBut_b(c, size) { if ((++this.menukey_b[c]) > size) this.menukey_b[c] = 0; this.res(this.menukey_b[c]); }
    ClkBut_c(c, size) { if ((++this.menukey_c[c]) > size) this.menukey_c[c] = 0; this.res(this.menukey_c[c]); }
    ClkBut_d(c, size) { if ((++this.menukey_d[c]) > size) this.menukey_d[c] = 0; this.res(this.menukey_d[c]); }
    ClkBut_f(c, size) { if ((++this.menukey_f[c]) > size) this.menukey_f[c] = 0; this.res(this.menukey_f[c]); }
    ClkBut_g(c, size) { if ((++this.menukey_g[c]) > size) this.menukey_g[c] = 0; this.res(this.menukey_g[c]); }
    ClkBut_h(c, size) { if ((++this.menukey_h[c]) > size) this.menukey_h[c] = 0; this.res(this.menukey_h[c]); }
    ClkBut_l(c, size) { if ((++this.menukey_l[c]) > size) this.menukey_l[c] = 0; this.res(this.menukey_l[c]); }
    ClkBut_m(c, size) { if ((++this.menukey_m[c]) > size) this.menukey_m[c] = 0; this.res(this.menukey_m[c]); }
    ClkBut_o(c, size) { if ((++this.menukey_o[c]) > size) this.menukey_o[c] = 0; this.res(this.menukey_o[c]); }
    ClkBut_p(c, size) { if ((++this.menukey_p[c]) > size) this.menukey_p[c] = 0; this.res(this.menukey_p[c]); }
    ClkBut_fr(c, size) { if ((++this.menukey_r[c]) > size) this.menukey_r[c] = 0; this.res(this.menukey_r[c]); }
    ClkBut_s(c, size) { if ((++this.menukey_s[c]) > size) this.menukey_s[c] = 0; this.res(this.menukey_s[c]); }
    ClkBut_t(c, size) { if ((++this.menukey_t[c]) > size) this.menukey_t[c] = 0; this.res(this.menukey_t[c]); }
    ClkBut_u(c, size) { if ((++this.menukey_u[c]) > size) this.menukey_u[c] = 0; this.res(this.menukey_u[c]); }
    ClkBut_w(c, size) { if ((++this.menukey_w[c]) > size) this.menukey_w[c] = 0; this.res(this.menukey_w[c]); }
    ClkBut_x(c, size) { if ((++this.menukey_x[c]) > size) this.menukey_x[c] = 0; this.res(this.menukey_x[c]); }
    ClkBut_z(c, size) { if ((++this.menukey_z[c]) > size) this.menukey_z[c] = 0; this.res(this.menukey_z[c]); }
    
    
    
    

    res(c) {
        if (c === 0) this.SetStatus("Hides this menu to user");
        if (c === 1) this.SetStatus("Show contents to the user");
        if (c === 2) this.SetStatus("Allow user to edit the contents");
    }
    SetStatus(mess) {
        this.response = mess;
        setTimeout(() => { this.response = ""; }, 15000);
    }
    swal(key) {
        var title = "",html="";
        if (key === "attendance") {
            title = "<span style='font-size:large'>HR ... Attendance</span>";
            html = '<i class="material-icons">check_box_outline_blank</i> Individuals can see only their attendance</b><br>' +
                '<i class="material-icons" style="color:darkgreen">done</i> View attendance of <b>ALL</b><br>' +
                '<i class="material-icons" style="color:red">edit</i> Approve Attandance of <b>ALL</b>';
        }
        Swal.fire({
            title: title,
            text: "Please select a Roster to remove",
            html: html,
            showCloseButton: true,
            focusConfirm: false,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i>',
            confirmButtonAriaLabel: 'Thumbs up, great!',
        });//.catch(swal.noop)
    }
    TabChanged($event) {
        this.ActiveTab = $event.index;
        if ($event.index === 1) {
            //if (this.global.sarTeams.length === 0) this.LoadFixedArrays();
        }
    }
    OnHead(head) {
        this.LoadPermissionData(head,"",false);
    }
    ClkBut_G(event) {
        event.stopPropagation();
        this.LoadPermissionData(this.headSelected, "set_global", this.globalstate);
    }
    ClkBut(event, team: TeamPermissions) {
        event.stopPropagation();
        team.state++;
        if (team.state > 2) team.state = 0;
        this.LoadPermissionData(this.headSelected, team.Name, team.state);
    }
}

export interface DialogData {
    pop_Head: string;
    pop_Roster: string;
    pop_InputName: string;
    pop_PlaceHolder: string;
    pop_StartTime: string;
    pop_EndTime: string;
    staffItem: StaffItem;
}

export interface ListRow {
    imei: string;
    regno: string;
    group: string;
    check: boolean;
}
export interface GetStaffsResponse {
    status: boolean;
    result: string;
    total_count: number;
    items: StaffItem[];
}

export interface StaffItem {
    m_id: number;
    m_StaffID: string;
    m_FName: string;
    m_Activity: string;
    m_ActivityTime: string;
    m_IP: string;
    m_Lat: number;
    m_Lng: number;
    m_WorkTime: string;
    m_Username: string;
    m_ReasonHead: string;
    m_ReasonNote: string;
    m_Email: string;
    m_Mobile: string;
    m_Designation: string;
    m_Roll: string;
    m_Team: string;
    m_Base: string;
    m_Type: string;
    m_ReportToFunctional: string;
    m_ReportToAdministrative: string;
    m_MenuKey: string;
    m_Band: string;
    m_Grade: string;
    m_Mrs: string;
    m_ViewSelected: number;
    m_DOB: Date;
    m_DOJ: Date;
    m_DOA: Date;
    m_LWD: Date;
    m_Status: string;
    m_Lock: number;
    m_PayscaleName: string;
    m_PayscaleKey: number;
    m_PayscaleStartDate: number;
}

interface LoadPermissionData {
    status: boolean;
    result: string;
    iParam1: number;
    sParam1: string;
    sarTeamsPermissions: TeamPermissions[];
}
export interface FixedArrayResponse {
    status: boolean;
    result: string;
    sarTitles: string[];
    sarRolls: string[];
    sarTeams: string[];
    sarBases: string[];
    sarBands: string[];
    sarGrades: string[];
    sarBanks: string[];
    sarRosterOptions: string[];
    sarGroups: string[];
}
interface TeamPermissions {
    Name: string;
    state: number;
}
class ReportingToItem {
    constructor(
        public Name: string,
        public Roll: string,
        public Base: string,
        public Email: string,
    ) { }
}
/** An example database that the data source uses to retrieve data for the table. */
export class ExampleHttpDao {
    constructor(private http: HttpClient) { }

    getRepoIssues(sort: string, order: string, page: number, search: string, profile: string): Observable<GetStaffsResponse> {
        const href = "http://" + APIDomain + '/accessmanager/getstaffs';
        const requestUrl =
            `${href}?q=q&sort=${sort}&order=${order}&page=${page + 1}&search=${search}&profile=${profile}`;
        //console.log(requestUrl);
        return this.http.get<GetStaffsResponse>(requestUrl);
    }
}
