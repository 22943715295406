import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Http, URLSearchParams, RequestOptions, Headers, ResponseContentType } from '@angular/http';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatCalendar } from '@angular/material/datepicker';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { MatTableDataSource } from '@angular/material/table';

import {  Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';


import 'rxjs/Rx';
import { PostResponse } from '../../fleet/drivers/drivers.component';

@Component({
    selector: 'app-previewannualbonusReleased',
    templateUrl: './previewannualbonusReleased.component.html',
    styleUrls: ['./previewannualbonusReleased.component.css'],
})
export class PreviewAnnualBonusComponentReleased implements OnInit {
    filteredLedgers: Observable<any[]>;
    deviceChanged: Subject<string> = new Subject<string>();
    deviceChangedSubscription: Subscription = null;
    //selectedStaffItem: LedgerSearchItem = new LedgerSearchItem('0', '', '', '', '', '');
    chkShowAll: boolean = false;
    status: string = "";
    rosterOption: string = "";
    iEdit: number = 0;
    TimeOut: string = "";
    TimeIn: string = "";
    lastCommand: string = "";
    paytoModel: string = "";
    payfromModel: string = "";
    constructor(public http: Http, private router: Router,
        public dialogRef: MatDialogRef<PreviewAnnualBonusComponentReleased>,
        @Inject(MAT_DIALOG_DATA) public data: VoucherData,
        private httpClient: HttpClient,
        
        public dialog: MatDialog,
        public global: GlobalProvider) {
        
        if (getCookie("login_profile").length === 0) {
            this.router.navigateByUrl('/pages/login');
            return;
        }
        //this.rosterOption = this.data.rosteroption;
        this.iEdit = this.IsEdit();
    }
    ngAfterViewInit() {

        $("#inpAmount").focus();
    }
    ngOnInit() {
        document.body.classList.add('bg-img');
        this.LoadVoucher();
    }
    LoadVoucher() {

        this.status = "Please wait...";
        this.http.post("http://" + APIDomain + "/accessmanager/LoadBonusReportReleased",
            {
                profile: getCookie('login_profile'),
                vchno: this.data.vchno
            }
        ).subscribe(result => {
            var response = result.json() as PostResponse;
            if (response.status) {
                $("#BonusContent").html(response.result);
            } else {
                $("#BonusContent").html(response.result);
            }
        }, error => {
            alert("Error");
        }
        );
    }
    ngOnDestroy() {

    }
    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }
    IsEdit() {
        if (this.IsSA()) return 2;
        if (getCookie('menukey').indexOf("x1-2") > -1) return 2;
        if (getCookie('menukey').indexOf("x1-1") > -1) return 1;
        return 0;
    }
    IsAdmin() {
        return getCookie("menukey").indexOf("a0-1") > -1;
    }
    onNoClick(dataOut: VoucherData): void {
        //dataOut.return = "close";
        this.dialogRef.close(dataOut);
    }
    
}
export interface VoucherData {
    return: string;
    vchno: number;
    staffid: string;
    payto: string;
    paytoname: string;
    payfrom: string;
    amount: number;
    notes: string;
    year: number;
    month: number;
    sarBanks: string[];
    sarLedgers: string[];
}
interface VoucherDetails {
    status: boolean;
    result: string;
    vchno: number;
    staffid: string;
    //payto: string;
    paytoname: string;
    payfrom: string;
    amount: number;
    notes: string;
    sarLedgers: string[];
}
class LedgerSearchItem {
    constructor(
        public Name: string,
        public Type: string,
        public StaffID: string
    ) { }
}