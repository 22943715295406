import { Component, OnInit, Inject } from '@angular/core';
import { Http, URLSearchParams, RequestOptions } from '@angular/http';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { APIDomain } from '../../providers/global/global';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
    selector: 'app-createroster',
    templateUrl: './createroster.component.html',
    styleUrls: ['./createroster.component.css']
})
export class CreateRosterComponent implements OnInit {
    public onCreateRosterResponse: OnCreateRosterResponse;
    selected_rosterObject: MyRoster = null;
    selected_shiftObject: MyShift = null;
    selected_roster: string = "";
    selected_shift: string = "";
    isRateLimitReached = false;
    pop_RosterName: string;
    pop_Password: string;
    isLoadingResults = true;
    constructor(public http: Http, private router: Router,
        public dialog: MatDialog
        ) {
        this.onCreateRosterResponse = {} as OnCreateRosterResponse;
        if (getCookie("login_profile").length === 0) {
            this.router.navigateByUrl('/pages/login');
            return;
        }
    }
    ngOnInit() {
        this.selected_rosterObject = {} as MyRoster;
        //this.selected_roster = "Team A";

        this.LoadRosters(this.selected_roster, this.selected_shift,"load");
    }
    LoadRosters(roster: string, shift: string, mode: string) {
        var param: string = "";
        this.selected_roster = roster;
        this.selected_shift = shift;

        if (mode === "deleteroster" || mode ==="deleteshift") {
            this.openDialog(mode);
            return;
        }
        this.LoadRosters_Exec(mode,'');
    }
    LoadRosters_Exec(mode: string,pass:string) {
        this.isLoadingResults = true;
        this.onCreateRosterResponse.result = "...";

        const params = new URLSearchParams();
        params.append('roster', this.selected_roster.length === 0 ? '' : this.selected_roster);
        params.append('shift', this.selected_shift.length === 0 ? '' : this.selected_shift);
        params.append('newroster', document.getElementById("newRoster") === null ? '' : (<HTMLInputElement>document.getElementById("newRoster")).value);
        params.append('newshift', document.getElementById("newShift") === null ? '' : (<HTMLInputElement>document.getElementById("newShift")).value);
        params.append('mode', mode);
        params.append('pass', pass);
        params.append('profile', getCookie('login_profile'));
        //__________________Clear text boxes
        (<HTMLInputElement>document.getElementById("newRoster")).value = "";
        (<HTMLInputElement>document.getElementById("newShift")).value = "";

        this.http.post("http://" + APIDomain + "/accessmanager/ManageRoster", params
        ).subscribe(result => {
            this.isLoadingResults = false;
            this.onCreateRosterResponse = result.json() as OnCreateRosterResponse;
            if (this.onCreateRosterResponse.status) {
                this.selected_rosterObject = this.onCreateRosterResponse.SelectedRoster;
            } else {
                console.log("false-" + this.onCreateRosterResponse.result);
                alert("false-" + this.onCreateRosterResponse.result);
            }
        }, error => {
            console.error(error);
            this.isLoadingResults = false;
            alert("FAILED-" + error);
        }
        );
    }
    /*
    SelectedColorRoster(item): any {
        if ((this.selected_rosterObject!==null)&& (item === this.selected_rosterObject.m_Name))
            return {
                'background-color': '#ddd', 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'border': '0.5px solid #b4b4b4', 'white-space':'nowrap' };
        else return { 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'white-space': 'nowrap'  };
    }
    
    SelectedColorShift(item): any {
        if ((this.selected_shiftObject !== null) && (item === this.selected_shiftObject.m_Name))
            return {
                'background-color': '#ddd', 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'border': '0.5px solid #b4b4b4', 'white-space': 'nowrap'
            };
        else return { 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'white-space': 'nowrap' };
    }
    */
    OnUpdate(mode) {
        if (!this.IsValidTime(document.getElementById("m_StartTime")["value"])) {
            alert("Invalid 'Start Time' format. (HH:MM:SS)");
            return;
        }
        if (!this.IsValidTime(document.getElementById("m_EndTime")["value"])) {
            alert("Invalid 'End Time' format. (HH:MM:SS)");
            return;
        }
        this.onCreateRosterResponse.result = "...";
        const params = new URLSearchParams();
        params.append('mode', mode);
        params.append('name', document.getElementById("m_Name")["value"]);
        params.append('roster', this.selected_roster);
        params.append('shift', this.selected_shift);
        params.append('starttime', document.getElementById("m_StartTime")["value"]);
        params.append('endtime', document.getElementById("m_EndTime")["value"]);
        params.append('profile', getCookie('login_profile'));

        this.http.post("http://" + APIDomain + "/accessmanager/UpdateShift", params
        ).subscribe(result => {
            this.onCreateRosterResponse = result.json() as OnCreateRosterResponse;
            if (this.onCreateRosterResponse.status) {
                this.selected_rosterObject = this.onCreateRosterResponse.SelectedRoster;
            } else {
                console.log("false-" + this.onCreateRosterResponse.result + "___" );
            }
        }, error => {
            console.error(error);
            alert("FAILED-" + error);
        }
        );
    }
    IsValidTime(timeString: string): boolean  {
        var pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;   // HH:MM:SS
        if (!timeString.match(pattern)) return false;
        return true;
    }
    openDialog(mode: string): string {
        if (this.selected_roster.length === 0) {
            this.onCreateRosterResponse.result = "Nothing is selected";
            return;
        }
        this.pop_RosterName = this.selected_roster;
        this.pop_Password = "";
        const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
            width: '250px',
            data: { pop_RosterName: this.pop_RosterName, pop_Password: this.pop_Password }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === null) {
                this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                this.onCreateRosterResponse.result = "";
            }else{
                //alert('pop_RosterName-' + result.pop_RosterName);
                //alert('pop_Password-' + result.pop_Password);
                this.LoadRosters_Exec(mode, result.pop_Password);
            } 
        });
        return "";
    }
}
//___________________________________________________
@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: 'dialog-overview-example-dialog.html',
})
export class DialogOverviewExampleDialog {

    constructor(
        public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
//______________________________________________________
export interface DialogData {
    pop_RosterName: string;
    pop_Password: string;
}
//___________________________________________________
interface OnCreateRosterResponse {
    status: boolean;
    result: string;
    SelectedRoster: MyRoster;
    sarRosters: string[];
}
interface MyRoster {
    m_Name: string;
    myShifts: MyShift[];
}
interface MyShift {
    m_Name: string;
    m_StartTime: string;
    m_EndTime: string;
}