import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
//import { MaterialModule } from '../app.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { FlexLayoutModule } from '@angular/flex-layout';

import { LogsRoutes } from './logs.routing';

//import { RostersComponent } from './rosters/rosters.component';

import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { LoginActivitiesComponent } from './loginactivities/loginactivities.component';
import { LeaveActivitiesComponent } from './leaveactivities/leaveactivities.component';
import { MasterlogComponent } from './masterlog/masterlog.component';

import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
      RouterModule.forChild(LogsRoutes),
    FormsModule,
      MatSelectModule,
      MatProgressSpinnerModule,
      MatTableModule,
      MatPaginatorModule,
      MatIconModule,
      MatAutocompleteModule,
      MatDatepickerModule,
      MatRadioModule,
      MatDialogModule,
      MatTabsModule,
      MatSortModule,
      MatInputModule,
      MatButtonModule,
    ReactiveFormsModule
  ],
  declarations: [
      /*RostersComponent,
      CreateNewRoster,*/
      LoginActivitiesComponent,
      LeaveActivitiesComponent,
      MasterlogComponent
    ],
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }
    ],
    entryComponents: [LoginActivitiesComponent, LeaveActivitiesComponent,
        MasterlogComponent]
})

export class LogsModule {}
