import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';


import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { AllowedListComponent } from './allowedlist.component';
import { OpenProfileEditor } from './profile-editor';
import { LeavesComponent } from './leaves.component';
import { OpenPermissionEditor } from './permission-editor';
import { Payslip_SettlementComponent } from '../../payroll/payslip_settlement/payslip_settlement.component';
import { MasterlogComponent } from '../../logs/masterlog/masterlog.component';

@Component({
    selector: 'app-staffs',
    templateUrl: './staffs.component.html',
    styleUrls: ['./staffs.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class StaffsComponent implements OnInit {
    //public onProfileResponse: OnProfileResponse;

    selected_m_id: any;
    selectedItem: string;
    selected_row_email: string;
    showoptions: string = "Active";
    showalloption: boolean = false;
    cardSelected: number = 0;
    staffItem: StaffItem;
    expandedElement: any;
    edipandedElement: any;
    allowListElement: any;
    bIsEdit: boolean;
    butStatus: number;
    selectedgrp: string = "All";

    updatestatus: string = "";
    //___________________________Material

    //private _signalrObservable = null;
    private _searchObservable = null;
    timeZoneOffset: string;
    status: string;
    response: string;

    /*-------------------------Have to be replaced by online query later
    sarDesignations: string[] = [null,'Trainee', 'ECG Analyst Trainee', 'ECG Analyst', 'Sr.ECG Analyst',
        'Floor Supervisor', 'Team Leader', 'Manager', 'Sr.Manager'];
    sarRolls: string[] = [null, 'Techs', 'Supervisor', 'Team Leader', 'Manager', 'Admin', 'Super Admin'];
    sarTeams: string[] = [null, 'LEADERSHIP', 'CENTURY', 'MSA', 'ACT-IR/ELITE', 'PHILIPS-LW',
        'ROZINN-ACT EX', 'DMS-AUS', 'PCS-CA', 'AMI-USA', 'HW-myPatch', 'CLINICAL EDUCATION',
        'CLINICAL OPERATION/BD','CUSTOMER SUPPORT-DOMESTIC','BIOMEDICAL SALES/SERVICE',
        'FIELD SUPPORT', 'HRD & ADMINSTRATION','FINANCE & ACCOUNTS','INFORMATION TECHNOLOGY',
        'CEO OFFICE','VENDOR STAFF-SECURITY','VENDOR STAFF-HOUSE KEEPING'];
    //-------------------------Have to be replaced by online query later END
    */
    
    displayedColumns: string[];
    //displayedColumns: string[] = ['photo', 'm_Name', 'm_StaffID', 'm_Username', 'm_Mobile', 'm_Designation', 'm_Roll', 'm_Team', 'm_Base', 'edipand','expand'];
    exampleDatabase: ExampleHttpDao | null;
    data: StaffItem[] = [];
    sarStatus: string[] = [];
    sarStatus_local: string[] = ['Active','Trainee'];
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    list = [
        { "name": "some name 1", ID: "D1" },
        { "name": "some name 2", ID: "D2" }
    ]

    public postResponse: PostResponse;
    
    //public fixedArrayResponse: FixedArrayResponse;

    //globalSearch: string = "";
    bFirstRun: boolean = false;
    m_MenuKey: string = "";

    per_leave: boolean = false;
    per_security: boolean = false;
    per_profile: boolean = false;
    per_attendance: boolean = false;
    per_creditcard: boolean = false;
    //___________________________Material END
    constructor(public http: Http, private router: Router, private httpClient: HttpClient,
        public dialog: MatDialog,
        private ref: ChangeDetectorRef,
          public global: GlobalProvider) {
        if (getCookie('login_profile') == null || getCookie('login_profile').length === 0) {
            this.router.navigateByUrl('/pages/login');
            return;
        }

        if (this.global.CompileType === this.global.TYPE_GINGERTRACKER) {
            this.displayedColumns = ['photo', 'm_FName', 'm_StaffID', 'm_Username', 'Type', 'm_Email', 'm_Mobile', 'allowlist', 'edipand', 'expand'];
        } else if (this.global.CompileType === this.global.TYPE_GREYOFFICE) {
            this.displayedColumns = ['photo', 'm_FName', 'm_StaffID', 'm_Designation', 'm_Band', 'm_Grade', 'm_PayscaleName', 'm_Team', 'm_Base', 'expand', 'leaves', 'edipand', 'attendance', 'payslip'];
        } else {
            this.displayedColumns = ['photo', 'm_FName', 'm_StaffID', 'm_Designation', 'm_Band', 'm_Grade', 'm_PayscaleName', 'm_Team', 'm_Base', 'expand', 'leaves', 'edipand', 'attendance', 'payslip'];
        }
        //this.fixedArrayResponse = {} as FixedArrayResponse;

        this.selected_m_id = "";
        this.expandedElement = "";
        this.edipandedElement = "";
        this.allowListElement = "";

        this.butStatus = 0;
        this.staffItem = {} as any;
        this.staffItem.m_Email = "";
        this.staffItem.m_MenuKey = "";
        
        this.m_MenuKey = getCookie('menukey');
        this.bIsEdit = this.IsEdit();
        this.per_attendance = this.IsAllowed('h3-1', 'h3-2')
        this.per_leave = this.IsAllowed('u4-1', 'u4-2');
        
        this.per_security = this.IsAllowed('u5-1', 'u5-2')
        this.per_profile = this.IsAllowed('u6-1', 'u6-2')
        this.per_creditcard = this.IsAllowed('u8-1', 'u8-2')
    }

    ngAfterViewInit() {
        this.global.globalSearch = "";
        this.Merge();
        this.bFirstRun = true;
    }
    IsAdmin() {
        return getCookie("menukey").indexOf("a0-1") > -1;
    }

    ngOnInit() {
        this.cardSelected = 0;
        //if (!this.global.sarLoaded) this.LoadFixedArrays();
        //this.OnListClk("");
        this._searchObservable = this.global.currentMessage.subscribe(search => {
            if (search != null) {
                if (this.bFirstRun) {
                    this.paginator.pageIndex = 0
                    this.cardSelected = 0;
                    //this.globalSearch = search;
                    this.showoptions = "";
                    this.Merge();
                }
            }
        });
        /*
                this._signalrObservable = this._signalrService.borrowerChangedObservable
                    .subscribe((obj: SubObj) => {
                        if (obj.comm == 1) {
                            if (obj.str.substring(0, 2) === "{G") { // reload
                                //this.LoadTrips();
                            }
                        }
                    });
        */
        //_________________________________
        this.timeZoneOffset = (new Date().getTimezoneOffset() * 60).toString();
        this.status = "";
        //__________________________________Material
        this.exampleDatabase = new ExampleHttpDao(this.httpClient);
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        //__________________________________Material END
        //this.showoptions = "1";
    }

    Merge_(no) {
        if (this.showoptions === "Disputed" ||
            this.showoptions === "Relieved" ||
            this.showoptions === "Terminated" ||
            this.showoptions === "Last Worked") this.showalloption = false;
        if (no === 1) {
            this.global.globalSearch = "";
        }
        else if (no === 2) {
            this.global.globalSearch = "";
        }
        this.Merge();
    }
    Merge() {
        if (getCookie('login_profile') == null || getCookie('login_profile').length === 0) {
            this.router.navigateByUrl('/pages/login');
            return;
        }
        
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    var statuscount = this.sarStatus.length;

                    return this.exampleDatabase!.getRepoIssues(
                        this.sort.active, this.sort.direction, this.paginator.pageIndex,
                        this.global.globalSearch, getCookie('login_profile'),
                        this.showoptions, statuscount + "", this.cardSelected + "", (this.showalloption?"showall":"")
                    );
                }),
            map((data: GetStaffsResponse) => {
                    // Flip flag to show that loading has finished.
                    this.isLoadingResults = false;
                this.isRateLimitReached = false;
                this.status = data.result;
                if (data.sarStatus.length > 0) this.sarStatus = data.sarStatus;
                    this.resultsLength = data.total_count;

                    return data.items;
                }),
                catchError((err) => {
                    alert(err.message)
                    this.isLoadingResults = false;
                    // Catch if the GitHub API has reached its rate limit. Return empty data.
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.data = data);
    }
    ngOnDestroy() {
        //if (this._signalrObservable != null) this._signalrObservable.unsubscribe();
        if (this._searchObservable != null) this._searchObservable.unsubscribe();
    }
    /*
    OnListClk(m_id: string) {
        this.onProfileResponse.result = "Wait...";
        var qs = "";
        qs += "?m_id=" + m_id;
        qs += "&mode=" + "load";

        const params = new URLSearchParams();
        params.append('m_id', m_id);
        params.append('mode', "load");
        this.http.post(APIDomain + "/Tim/GetDriverProfiles" + qs, params
        ).subscribe(result => {
            this.onProfileResponse = result.json() as OnProfileResponse;
            if (this.onProfileResponse.status) {
                this.selectedItem = this.onProfileResponse.selectedProfile.m_Name;
            } else {
                console.log("false-" + this.onProfileResponse.result);
                alert("false-" + this.onProfileResponse.result);
            }
            this.myphoto = APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" + this.onProfileResponse.selectedProfile.m_StaffID + "&dummy=" + Date.now().toString();
        }, error => {
            console.error(error);
            alert("FAILED-" + error);
        }
            );

    }
    */
    GetListSelectedColor(item): any {
        if (item === this.selectedItem)
            return { 'background-color': '#ddd', 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'border': '0.5px solid #b4b4b4', 'white-space': 'nowrap' };
        else return { 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'white-space': 'nowrap' };
    }
    /*
    OnUpdate(mode) {
        if (mode === "delete") {
            if (!confirm("Are you sure to delete Staff ID: " + document.getElementById("m_StaffID")["value"])) {
                console.log("Implement delete functionality here");
                return;
            }
        }
        //alert(this.m_Name);
        //alert(document.getElementById("m_Name")["value"]);
        this.status = "Wait...";

        //var sFlagFall = Number(document.getElementById("m_FlagFall")["value"]) * 100;
        //var sDistanceCharge = Number(document.getElementById("m_DistanceCharge")["value"]) * 100;
        //var sWaitingCharge = Number(document.getElementById("m_WaitingCharge")["value"]) * 100;
        //var sSurcharge = Number(document.getElementById("m_Surcharge")["value"]) * 100;
        var qs = "";
        qs += "?m_id=" + document.getElementById("m_id")["innerHTML"];
        qs += "&m_StaffID=" + document.getElementById("m_StaffID")["value"];
        qs += "&mode=" + mode;
        qs += "&m_Name=" + document.getElementById("m_Name")["value"];
        qs += "&m_AddressLocal=" + document.getElementById("m_AddressLocal")["value"];
        qs += "&m_AddressHome=" + document.getElementById("m_AddressHome")["value"];
        qs += "&m_Country=" + document.getElementById("m_Country")["value"];
        qs += "&m_DeviceIMEI=" + document.getElementById("m_DeviceIMEI")["value"];
        qs += "&m_RegNo=" + document.getElementById("m_RegNo")["value"];
        qs += "&m_TaxiType=" + document.getElementById("m_TaxiType")["value"];
        

        const params = new URLSearchParams();
        params.append('m_Name', document.getElementById("m_Name")["value"]);
        params.append('m_Country', document.getElementById("m_Country")["value"]);

        params.append('mode', mode);

        this.http.post(APIDomain + "/Tim/GetDriverProfiles" + qs, params
        ).subscribe(result => {
            this.postResponse = result.json() as PostResponse;
            if (this.postResponse.status) {
                //this.selectedItem = this.postResponse.selectedProfile.m_Name;
                //this._signalrService.sendMessage("{I...}"); // Onlyfor Tariff instant update 
            } else {
                console.log("false-" + this.status + "___" + qs);
                //alert("false-" + this.onLoadResponse.result);
            }
            //this.myphoto = APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" + this.postResponse.selectedProfile.m_StaffID + "&dummy=" + Date.now().toString();
        }, error => {
            console.error(error);
            //alert("FAILED-" + error);
        }
            );
    }
    */

    GetPhoto(email) {
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + email + "&dummy=" + this.global.iImageRandamize;//Date.now().toString();
    }

    OnEdipand_xxx(edipandedElementLoc, row) {
        this.selected_m_id = row.m_id;
        if (edipandedElementLoc === row) return "";
        this.selected_row_email = row.m_Email;
        //this.staffItem.m_Email = row.m_Email;
        //alert(this.staffItem.m_Email);
        //this.userItem.m_Email = row.m_Email;
        //this.staffItem.m_MenuKey = row.m_MenuKey;
        //this.DecodeMenuKey(this.staffItem.m_MenuKey);
        //alert(row.m_MenuKey);
        //this.DecodeMenuKey(row.m_MenuKey);
        return row;
    }

    //---------------------------------------
    OnEdipand(expandedElement, row: StaffItem) {
        this.ref.detach();
        this.staffItem = row;
        const dialogRef = this.dialog.open(OpenPermissionEditor, {
            width: '80%',
            data: {
                pop_Head: "", pop_InputName: '', pop_PlaceHolder: "",
                pop_StartTime: '', pop_EndTime: '', pop_Roster: "",
                staffItem: this.staffItem
            }
        });

        dialogRef.afterClosed().subscribe((result: DialogData) => {
            this.ref.reattach();
            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {
                    this.Merge();
                }
            }
        });
    }
    //----------------------------------------------
    OnLeaves(row: StaffItem) {
        this.ref.detach();
        this.staffItem = row;
        const dialogRef = this.dialog.open(LeavesComponent, {
            width: '90%',
            data: {
                pop_Head: "", pop_InputName: '', pop_PlaceHolder: "",
                pop_StartTime: '', pop_EndTime: '', pop_Roster: "",
                staffItem: this.staffItem
            }
        });
        dialogRef.afterClosed().subscribe((result: DialogData) => {
            this.ref.reattach();
            this.response = "";
            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {
                    this.Merge();
                }
            }
        });
    }
    //----------------------------------------------
    OnAllowListExpand(allowListElementLoc, row: StaffItem) {
        this.ref.detach();
        this.staffItem = row;
        const dialogRef = this.dialog.open(AllowedListComponent, {
            width: '70%',
            data: {
                pop_Head: "", pop_InputName: '', pop_PlaceHolder: "",
                pop_StartTime: '', pop_EndTime: '', pop_Roster: "",
                staffItem: this.staffItem
            }
        });

        dialogRef.afterClosed().subscribe((result: DialogData) => {
            this.ref.reattach();
            this.response = "";
            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {
                    this.Merge();
                }
            }
        });
        /*
        this.selected_m_id = row.m_id;
        if (allowListElementLoc === row) return "";
        this.selected_row_email = row.m_Email;
        this.staffItem.m_Email = row.m_Email;
        this.staffItem.m_MenuKey = row.m_MenuKey;
        //this.userItem.m_MenuKey = row.m_MenuKey;
        //this.DecodeMenuKey(this.userItem.m_MenuKey);
        this.GetAllowList(row.m_Email, this.selectedgrp, "");
        return row;
        */
    }
    //----------------------------------------------
    OnExpand(expandedElement, row: StaffItem) {
        this.ref.detach();
        this.staffItem = row;
        const dialogRef = this.dialog.open(OpenProfileEditor, {
            width: '70%',
            data: {
                pop_Head: "", pop_InputName: '', pop_PlaceHolder: "",
                pop_StartTime: '', pop_EndTime: '', pop_Roster: "",
                staffItem: this.staffItem,
                email: this.staffItem.m_Email
            }
        });

        dialogRef.afterClosed().subscribe((result: DialogData) => {
            this.ref.reattach();
            this.response = "";

            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete" ||
                    result.pop_Head === "update" ||
                    result.pop_Head === "lock" ||
                    result.pop_Head === "unlock") {
                    this.Merge();
                }
                //alert(result.popHead + '______' + result.pop_RosterName);
                //alert('pop_Password-' + result.pop_Password);
                /*
                if (result.pop_InputName.length === 0) {
                    Swal.fire({
                        title: "Name is empty",
                        buttonsStyling: false,
                        confirmButtonClass: "btn btn-success"
                    });//.catch(swal.noop)
                } else {
                    //if (result.pop_Head === "Roster") this.LoadRosters("newroster", result);
                    //else if (result.pop_Head === "Shift") this.LoadRosters("newshift", result);
                    //else if (result.pop_Head === "RosterHead") this.LoadRosters("newhead", result);
                }
                */
            }
        });
    }
    ShowMasterLog(staff) {
        this.ref.detach();
        const dialogRef = this.dialog.open(MasterlogComponent, {
            width: '90%',
            data: {
                staffconcern: staff
            }
        });
        dialogRef.afterClosed().subscribe((result: DialogData) => {
            this.ref.reattach();
        });

    }
    OnExpand_xx(expandedElement, row) {
        /*
        this.response = "";
        if (expandedElement === row) return "";
        this.selected_row = row.m_StaffID;

        this.m_id = row.m_id;
        this.m_Name = row.m_Name;
        this.m_StaffID = row.m_StaffID;
        this.m_Username = row.m_Username;
        this.m_Mobile = row.m_Mobile;
        this.m_Email = row.m_Email;
        this.m_Designation = row.m_Designation;
        this.m_Roll = row.m_Roll;
        this.m_Team = row.m_Team;
        this.m_Base = row.m_Base;
        this.m_Type = row.m_Type;
        this.m_ReportToFunctional = row.m_ReportToFunctional;
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.m_Email + "&dummy=" + this.iImageRandamize;//Date.now().toString();
            */
        return row;
    }

    OnUpdate(mode) {

        this.response = "Please wait...";
        const params = new URLSearchParams();
        params.append('mode', mode);
        params.append('profile', getCookie('login_profile'));
        params.append('email', getCookie('login_email'));
        params.append('staffid', getCookie('login_staffid'));
        console.log("http://" + APIDomain + "/accessmanager/Update_Staffs?"+params);
        this.http.post("http://" + APIDomain + "/accessmanager/Update_Staffs", params
        ).subscribe(result => {
            this.postResponse = result.json() as PostResponse;
            if (this.postResponse.status) {
                this.sort.direction = "desc";
                
                this.Merge();
                this.response = this.postResponse.result;
            } else {
                this.response = this.postResponse.result;
            }
        }, error => {
            this.response = "Failed: " + this.postResponse.result;
        }
        );
        if (mode === "new" || mode === "newstaff") {
            this.global.globalSearch = "";
            //this.globalSearch = "";
            this.Merge();
        }

    }
    IsEdit() {
        if (this.IsSA()) return true;
        if (this.m_MenuKey.indexOf("u7-2") > -1) return true;
        return false;
    }
    IsAllowed(key1, key2) {
        if (this.m_MenuKey.indexOf(key1) > -1) return true;
        if (this.m_MenuKey.indexOf(key2) > -1) return true;
        return false;
    }
    GotoAttendance(row: StaffItem) {
        this.global.m_Team = row.m_Team;
        this.global.m_Staff = { "key": row.m_StaffID, "value": row.m_FName }
        this.router.navigateByUrl('/hr_reports/attendance');
    }
    IsSAemail(mail) {
        return getCookie('login_profile') === mail;
    }
    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }

    IsLWD(dt: Date) {
        //console.log("IsLWD???");
        if (!this.IsDate(dt)) return false;
        let dt1 = eval((dt + "").replace(/\/Date\((\d+)\)\//gi, "new Date($1)"));
        //console.error(new Date().getTime() + "___" + dt1.getTime());
        if ((new Date()) > dt1) return true;
        return false;
    }
    IsDate(dt: Date) {
        return dt.toString().indexOf("Date(-") === -1;
    }

    OnRadio(e) {
        this.Merge();
    }
    cardColor: object = null;
    CardColor(row: StaffItem) {
        this.cardColor = this.CardColor_(row);
        return this.cardColor;
    }
    CardColor_(row: StaffItem) {
        //console.log("GET CardColor???");
        if (row.m_ApprovedBy4.length > 0) {
            return { 'color': '#b3b300' };
        } else if (row.m_ApprovedBy3.length > 0) {
            return { 'color': '#f7b7e7' };
        }else if (row.m_ApprovedBy2.length > 0) {
            return { 'color': '#00d' };
        }else if (row.m_ApprovedBy1.length > 0) {
            return { 'color': 'orange' };
        }
        return { 'color': '#ececec' };
    }
    GetMrs(mrs) {
        //console.log("GET MRS???");
        if (mrs === "0") return "Mr.";
        else if (mrs === "1") return "Ms.";
        else if (mrs === "10") return "Dr.";
        else if (mrs === "11") return "Dr.";
        return "";
    }
    OnPayslip(email, staffid) {
        this.ref.detach();
        this.staffItem.m_Email = email;
        const dialogRef = this.dialog.open(Payslip_SettlementComponent, {
            width: '80%',
            data: {
                pop_Head: "", pop_InputName: '', pop_PlaceHolder: "",
                pop_StartTime: '', pop_EndTime: '', pop_Roster: "",
                email: email, staffid: staffid
            }
        });
        dialogRef.afterClosed().subscribe((result: DialogData) => {
            this.ref.reattach();
            this.response = "";
            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {
                    this.Merge();
                } else if (result.pop_Head === "loadprofile") {
                    this.OnExpand(null, this.staffItem);
                }
            }
        });
    }
}
export interface DialogData {
    pop_Head: string;
    pop_Roster: string;
    pop_InputName: string;
    pop_PlaceHolder: string;
    pop_StartTime: string;
    pop_EndTime: string;
    staffItem: StaffItem;
}

export interface ListRow {
    imei: string;
    regno: string;
    group: string;
    check: boolean;
}
export interface GetStaffsResponse {
    status: boolean;
    result: string;
    sarStatus: string[];
    total_count: number;
    items: StaffItem[];
}

export interface StaffItem {
    m_id: number;
    m_StaffID: string;
    m_FName: string;
    m_Activity: string;
    m_ActivityTime: string;
    m_IP: string;
    m_Lat: number;
    m_Lng: number;
    m_WorkTime: string;
    m_Username: string;
    m_ReasonHead: string;
    m_ReasonNote: string;
    m_Email: string;
    m_Mobile: string;
    m_Designation: string;
    m_Roll: string;
    m_Team: string;
    m_Base: string;
    m_Type: string;
    m_ReportToFunctional: string;
    m_ReportToAdministrative: string;
    m_MenuKey: string;
    m_Band: string;
    m_Grade: string;
    m_Mrs: string;
    m_ViewSelected: number;
    m_DOB: Date;
    m_DOJ: Date;
    m_DOA: Date;
    m_LWD: Date;
    m_LWDExpired: number;
    m_Status: string;
    m_Lock: number;
    m_ApprovedBy1: string;
    m_ApprovedBy2: string;
    m_ApprovedBy3: string;
    m_ApprovedBy4: string;
    m_PayscaleName: string;
    m_PayscaleKey: number;
    m_PayscaleStartDate: number;
}

export interface PostResponse {
    status: boolean;
    result: string;
}
export interface FixedArrayResponse {
    status: boolean;
    result: string;
    sarTitles: string[];
    sarRolls: string[];
    sarTeams: string[];
    sarBases: string[];
    sarBands: string[];
    sarGrades: string[];
    sarRosterOptions: string[];
}
class ReportingToItem {
    constructor(
        public Name: string,
        public Roll: string,
        public Base: string,
        public Email: string,
    ) { }
}
/** An example database that the data source uses to retrieve data for the table. */
export class ExampleHttpDao {
    constructor(private http: HttpClient) { }

    getRepoIssues(sort: string, order: string, page: number, search: string, profile: string, showoptions: string, statuscount: string, cardSelected: string, showalloption:string): Observable<GetStaffsResponse> {
        const href = "http://" + APIDomain + '/accessmanager/getstaffs';
        const requestUrl =
            `${href}?q=q&sort=${sort}&order=${order}&page=${page + 1}&search=${search}&profile=${profile}&showoptions=${showoptions}&statuscount=${statuscount}&cardselected=${cardSelected}&showalloption=${showalloption}`;
        //console.log(requestUrl);
        return this.http.get<GetStaffsResponse>(requestUrl);
    }
}
