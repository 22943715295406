import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';


import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCalendar } from '@angular/material/datepicker';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-leaves',
    templateUrl: 'leaves.component.html',
    styleUrls: ['leaves.component.css']
})
export class LeavesComponent {
    //status: string = "";
    myphoto: any;
    leaveEditChk: boolean = false;
    selectedFile: File;
    selected_row: string;
    response: string;
    staffTypes: string[] = ["Trainee", "OnRole"];
    lastAction: string = "";
    public postResponse: PostResponse;
    //selected_row_email: string;
    bIsEdit: boolean = false;
    public loadLeaveDataResponse: LoadLeaveDataResponse;
    public getLeaveHistoryResponse: GetLeaveHistoryResponse;
    selectedgrp: string = "All";
    updatestatus: string = "";
    bViewSelected: boolean = false;
    leaveDays: string = "";
    leaveDescription: string = "";
    constructor(
        public dialogRef: MatDialogRef<LeavesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public http: Http,
         
        public global: GlobalProvider) {
        this.postResponse = {} as PostResponse;
        this.loadLeaveDataResponse = {} as LoadLeaveDataResponse;
        this.getLeaveHistoryResponse = {} as GetLeaveHistoryResponse;
        this.bIsEdit = this.IsEdit();
        //-------------------------------
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.data.staffItem.m_Email + "&dummy=" + this.global.iImageRandamize;
    }
    ngOnInit() {
        this.LoadLeaveData();
    }
    GetPhoto() {
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.data.staffItem.m_Email + "&dummy=" + this.global.iImageRandamize;//Date.now().toString();
    }
    IsEdit() {
        if (this.IsSA()) return true;
        var menukey = getCookie('menukey');
        //if (menukey.indexOf("a0-1") > -1) return true;
        if (menukey.indexOf("u4-2") > -1) return true;
        return false;
    }

    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }
    IsSAemail(mail) {
        return getCookie('login_profile') === mail;
    }
    IsMale(mrs) {
        if (mrs === '1' || mrs === '11') return false;
        if (mrs === '0' || mrs === '10') return true;
        return true;
    }
    public GetString(key, stat) {
        var ret = this.GetStringSub(key, stat);
        if (ret < 10) return " " + ret; else return ret;
    }
    public GetStringSub(key, s) {
        var obj = this.loadLeaveDataResponse.leaves;
        if (typeof obj === 'undefined') return "";

        if (key === "CL") { if (s === 1) return obj.CL.sumCr; if (s === 2) return obj.CL.sumCr - obj.CL.sumDr - obj.CL.used - obj.CL.pending; if (s === 3) return obj.CL.sumDr + obj.CL.used; if (s === 4) return obj.CL.pending; }
        if (key === "SL") { if (s === 1) return obj.SL.sumCr; if (s === 2) return obj.SL.sumCr - obj.SL.sumDr - obj.SL.used - obj.SL.pending; if (s === 3) return obj.SL.sumDr + obj.SL.used; if (s === 4) return obj.SL.pending; }
        if (key === "PL") { if (s === 1) return obj.PL.sumCr; if (s === 2) return obj.PL.sumCr - obj.PL.sumDr - obj.PL.used - obj.PL.pending; if (s === 3) return obj.PL.sumDr + obj.PL.used; if (s === 4) return obj.PL.pending; }
        if (key === "APL") { if (s === 1) return obj.APL.sumCr; if (s === 2) return obj.APL.sumCr - obj.APL.sumDr - obj.APL.used - obj.APL.pending; if (s === 3) return obj.APL.sumDr + obj.APL.used; if (s === 4) return obj.APL.pending; }
        if (key === "LOP") { if (s === 1) return obj.LOP.sumCr; if (s === 2) return obj.LOP.sumCr - obj.LOP.sumDr - obj.LOP.used - obj.LOP.pending; if (s === 3) return obj.LOP.sumDr + obj.LOP.used; if (s === 4) return obj.LOP.pending; }
        if (key === "ALOP") { if (s === 1) return obj.ALOP.sumCr; if (s === 2) return obj.ALOP.sumCr - obj.ALOP.sumDr - obj.ALOP.used - obj.ALOP.pending; if (s === 3) return obj.ALOP.sumDr + obj.ALOP.used; if (s === 4) return obj.ALOP.pending; }
        if (key === "MatL") { if (s === 1) return obj.MatL.sumCr; if (s === 2) return obj.MatL.sumCr - obj.MatL.sumDr - obj.MatL.used - obj.MatL.pending; if (s === 3) return obj.MatL.sumDr + obj.MatL.used; if (s === 4) return obj.MatL.pending; }
        if (key === "PatL") { if (s === 1) return obj.PatL.sumCr; if (s === 2) return obj.PatL.sumCr - obj.PatL.sumDr - obj.PatL.used - obj.PatL.pending; if (s === 3) return obj.PatL.sumDr + obj.PatL.used; if (s === 4) return obj.PatL.pending; }

        return "";
    }
    LoadLeaveData() {
        this.response = "Please wait...";
        const today = new Date();
        var iYear = today.getFullYear();
        var iMonth = today.getMonth() + 1;
        var iDay = today.getDate();

        var timeZoneOffset = (new Date().getTimezoneOffset() * 60).toString();

        let params = new URLSearchParams();
        params.append('timezone', timeZoneOffset);
        params.append('profile', getCookie('login_profile'));
        params.append('year', iYear + "");
        params.append('month', iMonth + "");
        params.append('staffid', this.data.staffItem.m_StaffID);

        this.http.post("http://" + APIDomain + "/accessmanager/LoadLeaveData", params
        ).subscribe(
            result => {
                this.response = "";
                this.loadLeaveDataResponse = result.json() as LoadLeaveDataResponse;
                if (this.loadLeaveDataResponse.status) {

                } else {

                    console.error(this.loadLeaveDataResponse.result);
                }
            }, error => {
                this.response = error;
            }
        );
    }
    OnLeaveEdit(x) {
    }
    SelectedLeave: string = "";
    GetLeaveHistory(key: string) {
        this.SelectedLeave = key;
        const today = new Date();
        var iYear = today.getFullYear();
        const params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('staffid', this.data.staffItem.m_StaffID);
        params.append('year', iYear + "");
        params.append('key', key + "");

        //alert(params);
        this.http.post("http://" + APIDomain + "/accessmanager/GetLeaveHistory", params
        ).subscribe(result => {
            this.getLeaveHistoryResponse = result.json() as GetLeaveHistoryResponse;

            //if (this.onLoadShiftDetailsResponse.status) {
            //} else {
            //}
        }, error => {

            //alert("GetShiftActivityDetails Error-" + this.shiftActivityDetailsResponse.result);
        }
        );
    }

    onNoClick(dataOut: DialogData): void {
        dataOut.pop_Head = this.lastAction;
        this.dialogRef.close(dataOut);
    }
    onYesClick(dataIn: DialogData): void {
        if (dataIn.pop_Head === 'Shift') {
            if (!this.IsValidTime(dataIn.pop_StartTime)) {
                this.response = "Invalid Start Time (HH:SS)";
                return;
            }
            if (!this.IsValidTime(dataIn.pop_EndTime)) {
                this.response = "Invalid End Time (HH:SS)";
                return;
            }
        }
        this.dialogRef.close(dataIn);
    }
    IsValidTime(timeString: string): boolean {
        //var pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;   // HH:MM:SS
        var pattern = /^([0-9]|[012][0-9]):[0-5][0-9]$/;   // HH:MM:SS
        if (!timeString.match(pattern)) return false;
        return true;
    }
    ngOnDestroy() {

    }
    Update_LeaveAccounts(mode) {
        this.response = "Please wait...";
        const params = new URLSearchParams();
        params.append('mode', mode);
        params.append('profile', getCookie('login_profile'));
        params.append('email', getCookie('login_email'));
        params.append('description', this.leaveDescription);
        params.append('days', this.leaveDays);
        params.append('type', this.SelectedLeave);
        params.append('staffid', this.data.staffItem.m_StaffID);

        this.http.post("http://" + APIDomain + "/accessmanager/Update_LeaveAccounts", params
        ).subscribe(result => {
            this.postResponse = result.json() as PostResponse;
            if (this.postResponse.status) {
                this.LoadLeaveData();
                this.GetLeaveHistory(this.SelectedLeave);
                this.response = this.postResponse.result;
                this.leaveDescription = "";
                this.leaveDays = "";
            } else {
                this.response = this.postResponse.result;
            }
        }, error => {
            this.response = "Failed: " + this.postResponse.result;
        }
        );
    }
}
export interface DialogData {
    pop_Head: string;
    pop_Roster: string;
    pop_InputName: string;
    pop_PlaceHolder: string;
    pop_StartTime: string;
    pop_EndTime: string;
    staffItem: StaffItem;
}
export interface AllowedListResponse {
    status: boolean;
    result: string;
    groups: string[];
    rows: ListRow[];
}
export interface ListRow {
    imei: string;
    regno: string;
    group: string;
    check: boolean;
}
export interface GetStaffsResponse {
    status: boolean;
    result: string;
    total_count: number;
    items: StaffItem[];
}

export interface StaffItem {
    m_id: number;
    m_StaffID: string;
    m_FName: string;
    m_Activity: string;
    m_ActivityTime: string;
    m_IP: string;
    m_Lat: number;
    m_Lng: number;
    m_WorkTime: string;
    m_Username: string;
    m_ReasonHead: string;
    m_ReasonNote: string;
    m_Email: string;
    m_Mobile: string;
    m_Designation: string;
    m_Roll: string;
    m_Team: string;
    m_Base: string;
    m_Type: string;
    m_ReportToFunctional: string;
    m_ReportToAdministrative: string;
    m_MenuKey: string;
    m_Band: string;
    m_Grade: string;
    m_Mrs: string;
    m_ViewSelected: number;
    m_PayscaleName: string;
    m_PayscaleKey: number;
    m_PayscaleStartDate: number;
}

export interface PostResponse {
    status: boolean;
    result: string;
}
interface LoadLeaveDataResponse {
    status: boolean;
    result: string;
    StaffName: string;
    Mrs: string;
    LeaveStatus: LeaveItem[];
    leaves: Leaves;
}
interface LeaveItem {
    Code: string;
    Status: number;
}
interface Leaves {
    CL: Leave;
    SL: Leave;
    PL: Leave;
    APL: Leave;
    COff: Leave;
    AWOff: Leave;
    LOP: Leave;
    ALOP: Leave;
    MatL: Leave;
    PatL: Leave;
}
interface Leave {
    used: number;
    pending: number;
    sumCr: number;
    sumDr: number;
}
interface GetLeaveHistoryResponse {
    status: boolean;
    result: string;
    rows: LeaveHistoryRow[];
}
interface LeaveHistoryRow {
    time: string;
    dt: string;
    description: string;
    status: number;
    pending: number;
    credit: number;
    used: number;
}
