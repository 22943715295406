import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
//import { MaterialModule } from '../app.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { PayscaleEffectiveDatesComponent } from './staffs/payscaleeffectivedates.component';
import { RosterManagerRoutes } from './rostermanager.routing';

//import { RostersComponent } from './rosters/rosters.component';
import { StaffsComponent } from './staffs/staffs.component';
import { NowComponent } from './now/now.component';
import { UsersMobileComponent } from './usersmobile/usersmobile.component';
import { StaffActivitiesComponent } from './staffactivities/staffactivities.component';
import { TerminalsComponent } from './terminals/terminals.component';
import { TerminalActivitiesComponent } from './terminalactivities/terminalactivities.component';
import { CreateRosterComponent } from './createroster/createroster.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DialogOverviewExampleDialog } from './createroster/createroster.component';
//import { CreateNewRoster } from '../../hr_projects/rosters/rosters.component';
import { OpenProfileEditor } from './staffs/profile-editor';
import { OpenPermissionEditor } from './staffs/permission-editor';
import { AllowedListComponent } from './staffs/allowedlist.component';
import { LeavesComponent } from './staffs/leaves.component';

import { PayslipComponent } from '../payroll/payslip/payslip.component';
import { Payscale_MasterComponent } from '../payroll/payscale_master/payscale_master.component';
import { PayrollModule } from '../payroll/payroll.module';
//import { CreateNewRoster } from '../hr_reports/rosters/rosters.component';
import { LogsModule } from '../logs/logs.module';
//import { MatSelectModule } from '@angular/material/select';

import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

import { MasterlogComponent } from '../logs/masterlog/masterlog.component';
@NgModule({
  imports: [
    CommonModule,
      RouterModule.forChild(RosterManagerRoutes),
      FormsModule,
      MatSelectModule,
      MatProgressSpinnerModule,
      MatTableModule,
      MatPaginatorModule,
      MatIconModule,
      MatAutocompleteModule,
      MatDatepickerModule,
      MatRadioModule,
      MatDialogModule,
      MatTabsModule,
      MatSortModule,
      MatInputModule,
      MatButtonModule,
      /*MaterialModule,*/
      PayrollModule,
      ReactiveFormsModule,
      LogsModule
  ],
  declarations: [
      /*RostersComponent,
      CreateNewRoster,*/
      PayscaleEffectiveDatesComponent,
      StaffsComponent,
      NowComponent,
      UsersMobileComponent,
      TerminalActivitiesComponent,
      StaffActivitiesComponent,
      TerminalsComponent,
      DialogOverviewExampleDialog,
      CreateRosterComponent,
      OpenProfileEditor,
      OpenPermissionEditor,
      AllowedListComponent,
      LeavesComponent
    ],
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } }
    ],
    entryComponents: [DialogOverviewExampleDialog,
        PayslipComponent, Payscale_MasterComponent, /*CreateNewRoster,*/
        OpenProfileEditor, OpenPermissionEditor, AllowedListComponent,
        LeavesComponent, PayscaleEffectiveDatesComponent, MasterlogComponent]
})

export class RosterManagerModule {}
