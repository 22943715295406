import { Routes } from '@angular/router';

//import { RostersComponent } from './rosters/rosters.component';
import { StaffsComponent } from './staffs/staffs.component';
import { NowComponent } from './now/now.component';
import { TerminalsComponent } from './terminals/terminals.component';
import { StaffActivitiesComponent } from './staffactivities/staffactivities.component';
import { TerminalActivitiesComponent } from './terminalactivities/terminalactivities.component';
import { CreateRosterComponent } from './createroster/createroster.component';
import { UsersMobileComponent } from './usersmobile/usersmobile.component';

export const RosterManagerRoutes: Routes = [

    {
        path: '',
        children: [
          {
                path: 'staffs',
                component: StaffsComponent
            }, {
                path: 'usersmobile',
                component: UsersMobileComponent
            }, {
                path: 'now',
                component: NowComponent
            }, {
                path: 'terminals',
                component: TerminalsComponent
            }, {
                path: 'terminalActivities',
                component: TerminalActivitiesComponent
            }, {
                path: 'staffActivities',
                component: StaffActivitiesComponent
            }
        ]
    }
];
