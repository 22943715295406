import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';

import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCalendar } from '@angular/material/datepicker';

import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser'


@Component({
    selector: 'app-message',
    templateUrl: 'message.component.html',
    styleUrls: ['message.component.css']
})
export class MessageComponent {
    //status: string = "";
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public loadChatResponse: LoadChatResponse;
    displayedColumns: string[] = ['m_Name', 'm_Key','m_CreatedBy','m_CreatedTime','m_UpdatedBy','m_UpdatedTime', 'm_Delete', 'm_Edit'];

    
    selectedSession: string = "";
    myphoto: any;
    leaveEditChk: boolean = false;
    selectedFile: File;
    selected_row: string;
    response: string="";
    HeadNote: string = "";
    lastAction: string = "";
    resultsLength: string = "";
    public postResponse: PostResponse;
    //selected_row_email: string;
    bIsEdit: boolean = false;

    globalSearch: string = "";
    bFirstRun: boolean = false;
    showoptions: string=""; 
    selectedgrp: string = "All";
    updatestatus: string = "";
    bViewSelected: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<MessageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public http: Http,
        private httpClient: HttpClient,
        public dialog: MatDialog,
        private ref: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
         
        public global: GlobalProvider) {
            this.ref.detach();
            this.postResponse = {} as PostResponse;
            this.loadChatResponse = {} as LoadChatResponse;

            this.bIsEdit = this.IsEdit();
            //-------------------------------

    }
    ngOnInit() {
        //__________________________________Material

        //this.exampleDatabase = new ExampleHttpDao(this.httpClient);
        // If the user changes the sort order, reset back to the first page.
        //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        //__________________________________Material END
        //pay_CHC0546_2019_5
        this.selectedSession = this.data.session;
        var blks = this.selectedSession.split("_");
        if (blks.length > 3) {
            if (blks[0] === "pay") this.HeadNote = "Payslip Notes of  " + blks[1] +
                " ► " + (parseInt(blks[3])+1) + "/" + blks[2];
        }
        this.LoadChat('', this.selectedSession, '');
    }
    ngOnDestroy() {

        //if (this._signalrObservable != null) this._signalrObservable.unsubscribe();
        //if (this._searchObservable != null) this._searchObservable.unsubscribe();
    }
    ngAfterViewInit() {
        this.global.globalSearch = "";
        //this.response = "Please wait...";
        
        //this.Merge("", "");
        //this.bFirstRun = true;
    }

    IsEdit() {
        if (this.IsSA()) return true;
        var menukey = getCookie('menukey');
        //if (menukey.indexOf("a0-1") > -1) return true;
        if (menukey.indexOf("p1-2") > -1) return true;
        return false;
    }

    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }
    IsSAemail(mail) {
        return getCookie('login_profile') === mail;
    }

    
    OnLeaveEdit(x) {
    }


    onNoClick(dataOut: DialogData): void {
        dataOut.response = this.lastAction;
        this.dialogRef.close(dataOut);
    }
    onYesClick(dataIn: DialogData): void {
        if (dataIn.response === 'Shift') {
            
        }
        this.dialogRef.close(dataIn);
    }
    IsValidTime(timeString: string): boolean {
        //var pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;   // HH:MM:SS
        var pattern = /^([0-9]|[012][0-9]):[0-5][0-9]$/;   // HH:MM:SS
        if (!timeString.match(pattern)) return false;
        return true;
    }

    
    LoadChat(mode, session, mess) {
        let params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('email', getCookie('login_email'));
        params.append('mode', mode);
        params.append('mess', mess);
        params.append('session', session);
        
        //params.append('leavestatus', this.selectedLeaveStatus + "");
        params.append('selectedemailfrom', getCookie('login_email'));//this.selectedEmailFrom);
        params.append('selectedemailto', getCookie('login_email'));//this.selectedEmailTo);
        //params.append('mins', this.ReqMins + "");
        
        params.append('adminview', '');
            //this.adminView ? this.global.StaffSelected : '');
        
        params.append('reqfrom', "leave");
        console.log("http://" + APIDomain + "/accessmanager/LoadChat?" + params);
        this.response = "Please wait...";
        this.http.post("http://" + APIDomain + "/accessmanager/LoadChat", params
        ).subscribe(
            result => {
                this.loadChatResponse = result.json() as LoadChatResponse;

                this.SetStatus(this.loadChatResponse.result);
                document.getElementById("NewMessage").focus();
                $("#NewMessage").val("");
                //if (this.loadChatResponse.bReload)this.LoadMessages('', '');
                this.ref.detectChanges();
            }, error => {
                this.SetStatus("Err2-" + error);
                this.ref.detectChanges();
            }
            
        );
    }
    GetPhoto(email) {
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            email + "&dummy=";// + Date.now().toString();
    }
    transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    SetStatus(mess) {
        this.response = mess;
        setTimeout(() => { this.response = ""; }, 5000);
    }
    SendButton() {
        if ($("#NewMessage").val().toString().length === 0) {
            this.SetStatus("Please enter your message");
            return;
        }
        this.LoadChat("newmessage", this.selectedSession, $("#NewMessage").val());
        $("#NewMessage").val("");
    }
    onKeydown(event) {
        if (event.keyCode == 13) {
            this.SendButton();
        }
    }
}

export interface DialogData {
    response: string;
    session: string;
}
export interface AllowedListResponse {
    status: boolean;
    result: string;
    groups: string[];
    rows: ListRow[];
}
export interface ListRow {
    imei: string;
    regno: string;
    group: string;
    check: boolean;
}

export interface PostResponse {
    status: boolean;
    result: string;
}

interface LeaveItem {
    Code: string;
    Status: number;
}
interface LoadChatResponse {
    status: boolean;
    result: string;
    bReload: boolean;
    selectedSession: string;
    SenderDetails: string;
    messages: Message[];
}
interface Message {
    MySelf: number;
    sMessage: string;
    sTime: string;
    image: string;
    By: string;
}