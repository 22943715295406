import { Component, OnInit,ViewChild } from '@angular/core';
import { Http, URLSearchParams, RequestOptions } from '@angular/http';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj } from '../../providers/global/global';

import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {
    DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette, MAT_DATE_LOCALE
} from '@angular/material/core';


import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCalendar } from '@angular/material/datepicker';
import { MatTabChangeEvent } from '@angular/material/tabs';


import { PayslipComponent } from '../payslip/payslip.component';
import { PreviewAnnualBonusComponentReleased } from '../previewannualbonusReleased/previewannualbonusReleased.component';

@Component({
    selector: 'app-my_payslips',
    templateUrl: './my_payslips.component.html',
    styleUrls: ['./my_payslips.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    providers: [
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class MyPayslipsComponent implements OnInit {
    //public onProfileResponse: OnProfileResponse;
    selectedItem: string;
    m_Name: string;
    selectedFile: File;
    myphoto: any;
    m_DtFrom: Date;
    m_DtTo: Date;
    //___________________________Material
    status: string;
    //private _signalrObservable = null;
    private _searchObservable = null;
    timeZoneOffset: string;

    response: string;
    selected_m_ID: number;

    //displayedColumns: string[] = ['m_DateStart', 'm_DateEnd', 'm_Year', 'm_MonthName', 'm_CreatedTime', 'm_WorkingDays', 'm_PayscaleName', 'm_CrTot', 'm_EarnsTot', 'm_DeductsTot','m_NetPay','Payslip'];
    displayedColumns: string[] = ['m_DateStart', 'm_DateEnd', 'm_Year', 'm_MonthName', 'm_CreatedTime', 
'm_DaysToBePaidTotal', /*'m_EarnsTot', 'm_DeductsTot', */
'm_NetPay', 'Payslip'];
    exampleDatabase: ExampleHttpDao | null;
    data: MyPayslipRow[] = [];

    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    iImageRandamize: number = 1000;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    leaveActivityRow = {} as MyPayslipRow;
    public postResponse: PostResponse;
    globalSearch: string="";
    bFirstRun: boolean = false;
    //___________________________Material END
    constructor(public http: Http, private router: Router, private httpClient: HttpClient,
        private sanitizer: DomSanitizer,
        public dialog: MatDialog,
         public global: GlobalProvider) {
        if (getCookie('login_profile') == null || getCookie('login_profile').length === 0) {
            this.router.navigateByUrl('/pages/login');
            return;
        }

        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx";
    }
    ngAfterViewInit() {
        this._searchObservable = this.global.currentMessage.subscribe(search => {
            if (search != null) {
                if (this.bFirstRun) {
                    this.paginator.pageIndex = 0
                    this.globalSearch = search;
                    this.Merge();
                }
            }
        });

        //_________________________________
        this.status = "";
        //__________________________________Material
        this.exampleDatabase = new ExampleHttpDao(this.httpClient);
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        //__________________________________Material END
        //------------------onnginit end
        this.global.globalSearch = "";
        this.Merge();
        this.bFirstRun = true;
    }
    ngOnInit() {
        //this.OnListClk("");

    }
    //
    public getSantizeUrl(lat: string, lng: string) {
        var url = "https://www.google.com/maps/embed/v1/view?key=AIzaSyCqIDGJZXbP6ICWEQn69JA0EeuA1E0POzI&center=" + lat + "," + lng + "&zoom=14&maptype=roadmap";
        //var url = "http://maps.google.com/maps?q=" + lat + "," + lng + "&z=15&output=embed&key=AIzaSyCqIDGJZXbP6ICWEQn69JA0EeuA1E0POzI";
        //return this.sanitizer.bypassSecurityTrustUrl(url);
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    Merge() {

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.exampleDatabase!.getRepoIssues(
                        this.sort.active, this.sort.direction, this.paginator.pageIndex,
                        this.globalSearch, getCookie('login_profile'),
                        getCookie('login_staffid')
                    );
                }),
                map(data => {
                    // Flip flag to show that loading has finished.
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data.total_count;

                    return data.items;
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    // Catch if the GitHub API has reached its rate limit. Return empty data.
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.data = data);
    }
    ngOnDestroy() {
        //if (this._signalrObservable != null) this._signalrObservable.unsubscribe();
        if (this._searchObservable != null) this._searchObservable.unsubscribe();
    }

    GetListSelectedColor(item): any {
        if (item === this.selectedItem)
            return { 'background-color': '#ddd', 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'border': '0.5px solid #b4b4b4', 'white-space':'nowrap' };
        else return { 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'white-space': 'nowrap'};
    }


    GetAccess() {
        if (getCookie('login_profile') === getCookie('login_email')) return 2;
        var key = getCookie("menukey");
        //if (key.indexOf("a0-1") > -1) return 2;
        if (key.indexOf("s5-1") > -1) return 1;
        if (key.indexOf("s5-2") > -1) return 2;
        return 0;
    }
    PaySlip(row: MyPayslipRow) {
        const dialogRef = this.dialog.open(PayslipComponent, {
            minWidth: '50em',
            width: '50%',
            data: {
                email: getCookie("login_email"),
                staffid: row.m_StaffID, preview: 0,
                year: row.m_Year, month: row.m_Month, releasebonus:false
            }
        });
        dialogRef.afterClosed().subscribe((result) => {

            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {

                }
            }
        });
    }
    OnPreviewAnnualBonusReleased(vchno: string) {

        const dialogRef = this.dialog.open(PreviewAnnualBonusComponentReleased, {
            width: '30em',
            data: {
                return: "",
                vchno: vchno,
            }
        });
        /*dialogRef.afterClosed().subscribe((result: VoucherData) => {
            this.response = "";
            if (result === null) {
            } else if (typeof result === "undefined") {
            } else {
                if (result.return === "reload") {
                    //this.Merge();
                }
            }
        });*/
    }
}

export interface  MyPayslipsResponse {
    items: MyPayslipRow[];
    total_count: number;
}

export interface MyPayslipRow {
    m_id: number;
    m_Year: number;
    m_Month: number;
    m_DateStart: number;
    m_DateEnd: number;
    m_MonthName: string;
    m_CreatedTime: number;
    //m_WorkingDays: number;
    m_DaysToBePaidTotal: number;
    m_PayscaleName: string;
    m_CrTot: number;
    m_EarnsTot: number;
    m_DeductsTot: number;
    m_StaffID: string;
    m_VchNo: number;
    bHasBonus: boolean;
}
export interface PostResponse {
    status: boolean;
    result: string;
}

/** An example database that the data source uses to retrieve data for the table. */
export class ExampleHttpDao {
    constructor(private http: HttpClient) { }

    getRepoIssues(sort: string, order: string, page: number, search: string, profile: string, staffid:string): Observable<MyPayslipsResponse> {
        const href = 'http://' + APIDomain + '/accessmanager/GetMyPayslips';
        const requestUrl =
            `${href}?q=q&sort=${sort}&order=${order}&page=${page + 1}&search=${search}&profile=${profile}&staffid=${staffid}`;
        //console.log(requestUrl);
        return this.http.get<MyPayslipsResponse>(requestUrl);
    }
}