
import {distinctUntilChanged, debounceTime,  catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';

import {
    DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette, MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';


import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';



import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';


import { PayslipComponent } from '../../payroll/payslip/payslip.component';
import { Payscale_MasterComponent } from '../../payroll/payscale_master/payscale_master.component';
import { PayscaleEffectiveDatesComponent } from './payscaleeffectivedates.component';
import { Profile } from 'selenium-webdriver/firefox';
import { MasterlogComponent } from '../../logs/masterlog/masterlog.component';
import { DomSanitizer } from '@angular/platform-browser'


//___________________________________________________
@Component({
    selector: 'profile-editor',
    templateUrl: 'profile-editor.html',
    styleUrls: ['profile-editor.css'],
    providers: [
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class OpenProfileEditor {
    //status: string = "";
    myphoto: any;
    enableEdit: boolean = false;
    selectedFile: File;
    selectedFileType: string;
    selected_row: string;
    response: string="";

    //Starts Reordering the strings by Sivaguru M CHC1704 on 27-01-2024
    staffTypes: string[] = ["Active", "Disputed", "Relieved" , "Temporary"  , "Terminated" , "Trainee" ,""];
    //staffTypes: string[] = ["Active", "Disputed", "Terminated", "Relieved", "Trainee", "Temporary",""];
    AttendanceMethods: string[] = ["Administrative","Functional"];
    //AttendanceMethods: string[] = ["Functional", "Administrative"]; //, "Biometric", "Field"
    //Ends Reordering the strings
    AttendanceSources: string[];
    lastAction: string = "";
    public postResponse: PostResponse;
    public getStaffResponse: GetStaffResponse;
    public fixedArrayResponse: FixedArrayResponse;
    filteredReportingHeads: Observable<any[]>;
    ReportToAdminChanged: Subject<string> = new Subject<string>();
    reportToAdminChangedSubscription: Subscription = null;
    ReportToFuncChanged: Subject<string> = new Subject<string>();
    reportToFuncChangedSubscription: Subscription = null;
    bIsEdit: boolean = false;
    m_DOB: Date;
    m_DOJ: Date;
    m_DOA: Date;
    m_LWD: Date;
    mrs: string = "";
    m_Payscale: PayscalDropdownItem;
    ShowScan: boolean = false;
    oBank: Bank = { "m_Name": "", "m_AccountNo": "", "m_Branch": "","m_IFSC":""};
    //m_EPF_UAN: string = "";
    //m_IFSC: string = "";
    //m_Branch: string = "";
    srcScanImg: string = "";
    login_profile: string = "";
    login_email: string = "";
    login_staffid: string = "";

    show_pass: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<OpenProfileEditor>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public http: Http,
        public dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private ref: ChangeDetectorRef,
        private router: Router,
         
        private datePipe: DatePipe,
        public global: GlobalProvider) {
        this.login_profile = getCookie('login_profile');
        this.login_email = getCookie('login_email');
        this.login_staffid = getCookie('login_staffid');

            if (getCookie('login_profile') == null || getCookie('login_profile').length === 0) {
                this.router.navigateByUrl('/pages/login');
		        return;
        }
        
        this.postResponse = {} as PostResponse;
        this.fixedArrayResponse = {} as FixedArrayResponse;
        this.m_Payscale = {} as PayscalDropdownItem;

        this.getStaffResponse = {} as GetStaffResponse;
        this.getStaffResponse.staffItem = {} as StaffItem;
        //-------------------------------
        this.bIsEdit = this.IsEdit();
        //-------------------------------
        if (this.global.CompileType === this.global.TYPE_CHCHEALTHCARE) {
            this.AttendanceSources = ['Biometric', 'Mobile', 'Bio-Mobile', 'MeterBox'];
        } else {
            this.AttendanceSources = ['Biometric', 'Mobile', 'Bio-Mobile', 'Access Manager'];
        }
    }
    ngOnInit() {

        this.reportToAdminChangedSubscription =
            this.ReportToAdminChanged.pipe(
                debounceTime(100), // wait 300ms after the last event before emitting last event
                distinctUntilChanged(),) // only emit if value is different from previous value
                .subscribe(key => {
                    console.log("bounce.." + key);
                    this.filteredReportingHeads = this.searchReportToHeads(key);
                });

        //-------------------------------
        this.reportToFuncChangedSubscription =
            this.ReportToFuncChanged.pipe(
                debounceTime(100), // wait 300ms after the last event before emitting last event
                distinctUntilChanged(),) // only emit if value is different from previous value
                .subscribe(key => {
                    console.log("bounce.." + key);
                    this.filteredReportingHeads = this.searchReportToHeads(key);
                });

        if (!this.global.sarLoaded) this.LoadFixedArrays();
        this.filteredReportingHeads = this.searchReportToHeads("");
        this.GetStaffResponse();
    }
    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }
    IsSAemail(mail) {
        return getCookie('login_profile') === mail;
    }
    IsEdit() {
        if (!this.enableEdit) return false;
        return this.IsEdit_();
    }
    IsEdit_() {
        if (this.IsSA()) return true;
        if (this.getStaffResponse.staffItem.m_Lock === 1) return false;
        var menukey = getCookie('menukey');
        if (menukey.indexOf("u6-2") > -1) return true;
        return false;
    }
    OnEnableEdit(x) {
        if (this.enableEdit) this.LoadFixedArrays();
        this.bIsEdit = this.IsEdit();
    }
    IniDatas() {
        //if (!this.global.sarLoaded) this.LoadFixedArrays();
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.getStaffResponse.staffItem.m_Email + "&dummy=" + this.global.iImageRandamize;
        this.m_DOB = eval((this.getStaffResponse.staffItem.m_DOB + "").replace(/\/Date\((\d+)\)\//gi, "new Date($1)"));
        this.m_DOJ = eval((this.getStaffResponse.staffItem.m_DOJ + "").replace(/\/Date\((\d+)\)\//gi, "new Date($1)"));
        this.m_DOA = eval((this.getStaffResponse.staffItem.m_DOA + "").replace(/\/Date\((\d+)\)\//gi, "new Date($1)"));
        this.m_LWD = eval((this.getStaffResponse.staffItem.m_LWD + "").replace(/\/Date\((\d+)\)\//gi, "new Date($1)"));
    }
    IsDate(dt: Date) {
        if (typeof dt === 'undefined') return -1;
        return dt.toString().indexOf("Date(-") === -1;
    }
    ClickSex(item: StaffItem) {
        if (this.bIsEdit) {
            if (item.m_Mrs === '0') this.getStaffResponse.staffItem.m_Mrs = "1";
            else if (item.m_Mrs === '1') this.getStaffResponse.staffItem.m_Mrs = "10";
            else if (item.m_Mrs === '10') this.getStaffResponse.staffItem.m_Mrs = "11";
            else this.getStaffResponse.staffItem.m_Mrs = "0";
        }
        this.mrs = this.GetSex(this.getStaffResponse.staffItem.m_Mrs);
    }
    GetSex(mrs) {
        if (mrs === '0') return "Male";
        else if (mrs === '1') return "Female";
        else if (mrs === '10') return "Dr(M)";
        else if (mrs === '11') return "Dr(F)";
        else return "";
    }
    IsMale(mrs) {
        if (mrs === '1' || mrs === '11') return false;
        if (mrs === '0' || mrs === '10') return true;
        return true;
    }


    BandChanged(bnd) {
        this.getStaffResponse.staffItem.m_Band = bnd;
        this.LoadFixedArrays();
    }
    compareFnPayscale(c1: PayscalDropdownItem, c2: PayscalDropdownItem): boolean {
        return c1 && c2 ? c1.m_Name === c2.m_Name : c1 === c2;
    }
    GetStaffResponse() {
        
        this.response = "<span style='color:red;font-weight:bold;'>Please wait...</span>";
        const params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('email', this.data.email);//this.getStaffResponse.staffItem.m_Email);
        
        this.http.post("http://" + APIDomain + "/accessmanager/GetStaffResponse", params
        ).subscribe(result => {
            this.getStaffResponse = result.json() as GetStaffResponse;
            
            this.IniDatas();
            this.response = this.getStaffResponse.result;
            this.m_Payscale.m_Name = this.getStaffResponse.staffItem.m_PayscaleName;
            this.m_Payscale.m_Key = this.getStaffResponse.staffItem.m_PayscaleKey;
            this.oBank = {
                "m_Name": this.getStaffResponse.staffItem.m_Bank,
                "m_AccountNo": this.getStaffResponse.staffItem.m_AccountNo,
                "m_Branch": this.getStaffResponse.staffItem.m_Branch,
                "m_IFSC": this.getStaffResponse.staffItem.m_IFSC
            }
            if (this.getStaffResponse.staffItem.m_FName === "_New" ||
                this.getStaffResponse.staffItem.m_Email.length === 0
            ) {
                if (this.IsEdit_()) {
                    this.enableEdit = true;
                    this.bIsEdit = true;
                }
            }

            if (this.getStaffResponse.staffItem.m_AttendanceSource.length === 0) {
                if (this.global.CompileType === this.global.TYPE_CHCHEALTHCARE) {
                    this.getStaffResponse.staffItem.m_AttendanceSource = "Meterbox";
                } else {
                    this.getStaffResponse.staffItem.m_AttendanceSource = "Bio-Mobile";
                }
            }
            if (this.getStaffResponse.status) {

            } else {
                //this.response = this.fixedArrayResponse.result;
            }
        }, error => {
            alert("Error");
        }
        );
    }
    LoadFixedArrays() {
        this.response = "<span style='color:red;font-weight:bold;'>Please wait...</span>";
        const params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('band', this.getStaffResponse.staffItem.m_Band);
        this.http.post("http://" + APIDomain + "/accessmanager/LoadFixedArrays", params
        ).subscribe(result => {
            this.fixedArrayResponse = result.json() as FixedArrayResponse;
            this.response = this.fixedArrayResponse.result;
            if (this.fixedArrayResponse.status) {
                this.global.sarTitles = this.fixedArrayResponse.sarTitles;
                this.global.sarRolls = this.fixedArrayResponse.sarRolls;
                this.global.sarTeams = this.fixedArrayResponse.sarTeams;
                this.global.sarBases = this.fixedArrayResponse.sarBases;
                this.global.sarBands = this.fixedArrayResponse.sarBands;
                this.global.sarGrades = this.fixedArrayResponse.sarGrades;
                this.global.sarPayscales = this.fixedArrayResponse.sarPayscales;
                this.global.sarBanks = this.fixedArrayResponse.sarBanks;

                this.global.sarLoaded = true;

            } else {
                //this.response = this.fixedArrayResponse.result;
            }
        }, error => {
            alert("Error");
        }
        );
    }
    searchReportToHeads(search: string): Observable<ReportingToItem[]> {
        let apiURL = `${'http://' + APIDomain + '/accessmanager/SearchReportingHeads'}`;
        const params = new URLSearchParams();
        params.set('search', search);
        params.set('profile', getCookie('login_profile'));
        params.set('email', getCookie('login_email'));
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');

        const options = new RequestOptions({
            headers: headers,
            responseType: ResponseContentType.Json,
            params: params,
            withCredentials: false
        });

        return this.http.post(apiURL, {}, options).pipe(
            map(res => {
                return res.json().names.map(item => {
                    return new ReportingToItem(
                        item.Name,
                        item.Roll,
                        item.Base,
                        item.Email
                    );
                });
            }));
    }
    OnReportToAdminKey(value: string) {
        //console.log("KeyPress:"+value);
        this.ReportToAdminChanged.next(value);
    }
    OnReportToFuncKey(value: string) {
        //console.log("KeyPress:"+value);
        this.ReportToFuncChanged.next(value);
    }
    OnDelete() {
        this.response = "";
        Swal.fire({
            title: 'Are you sure?',
            text: "Action will delete this staff profile completely",
            
            showCancelButton: true,
           /* 
            * type: 'warning',confirmButtonClass: 'btn btn-success',*/
            /*cancelButtonClass: 'btn btn-danger',*/
            confirmButtonText: 'Yes, Go ahead!',
            buttonsStyling: false
        }).then((result) => {
            if (result.value) {
                this.OnUpdate('delete')
            }
        });
    }
    GetYYYY(dt) {
        try {
            dt = this.datePipe.transform(dt, "yyyy-MM-dd")
        } catch (x) {
            dt = "";
        }
        if (dt === null) return "";
        return dt;
    }

    OnUpdate(mode) {
        if (mode !== "new" && mode !== "delete" &&
            this.getStaffResponse.staffItem.m_Email.length < 4) {
            alert("Email is must");
            return;
        }

        this.lastAction = mode;
        this.response = "<span style='color:red;font-weight:bold;'>Please wait...</span>";
        const params = new URLSearchParams();
        params.append('mode', mode);
        params.append('m_id', this.getStaffResponse.staffItem.m_id + "");
        params.append('m_Name', this.getStaffResponse.staffItem.m_FName);
        params.append('m_StaffID', this.getStaffResponse.staffItem.m_StaffID);
        params.append('m_Username', this.getStaffResponse.staffItem.m_Username);
        params.append('m_Mobile', this.getStaffResponse.staffItem.m_Mobile);
        params.append('m_Email', this.getStaffResponse.staffItem.m_Email);
        params.append('m_Designation', this.getStaffResponse.staffItem.m_Designation);
        params.append('m_Roll', this.getStaffResponse.staffItem.m_Roll);
        params.append('m_Team', this.getStaffResponse.staffItem.m_Team);
        params.append('m_Base', this.getStaffResponse.staffItem.m_Base);
        params.append('m_Type', this.getStaffResponse.staffItem.m_Type);
        params.append('m_ReportToFunctional', this.getStaffResponse.staffItem.m_ReportToFunctional);
        params.append('m_ReportToAdministrative', this.getStaffResponse.staffItem.m_ReportToAdministrative);
        params.append('m_Band', this.getStaffResponse.staffItem.m_Band);
        params.append('m_Grade', this.getStaffResponse.staffItem.m_Grade);
        params.append('profile', getCookie('login_profile'));
        params.append('email', getCookie('login_email'));
        params.append('staffid', getCookie('login_staffid'));
        params.append('m_Mrs', this.getStaffResponse.staffItem.m_Mrs);
        params.append('m_DOB', this.GetYYYY(this.m_DOB));//this.getStaffResponse.staffItem.
        params.append('m_DOJ',  this.GetYYYY(this.m_DOJ));
        params.append('m_DOA', this.GetYYYY(this.m_DOA));
        params.append('m_LWD', this.GetYYYY(this.m_LWD));
        params.append('m_Status', this.getStaffResponse.staffItem.m_Status);
        params.append('m_Payscale', this.m_Payscale.m_Name);
        params.append('m_Key', this.m_Payscale.m_Key.toString());
        params.append('m_Bank', this.oBank.m_Name);
        params.append('m_AccountNo', this.oBank.m_AccountNo);
        params.append('m_Branch', this.oBank.m_Branch);
        params.append('m_IFSC', this.oBank.m_IFSC);
        params.append('m_EPF_UAN', this.getStaffResponse.staffItem.m_EPF_UAN);
        params.append('m_ESICNumber', this.getStaffResponse.staffItem.m_ESICNumber);
        params.append('m_AttendanceMethod', this.getStaffResponse.staffItem.m_AttendanceMethod);
        params.append('m_AttendanceSource', this.getStaffResponse.staffItem.m_AttendanceSource);

        
        if (this.getStaffResponse.staffItem.m_AADHAR_Number.length > 0) {
            if (this.getStaffResponse.staffItem.m_AADHAR_Number.length != 12) {
                this.response = "<span style='color:red;font-weight:bold;'>Invalid AADHAR Number</span>";
                return;
            }
        }
        if (this.getStaffResponse.staffItem.m_PAN_Number.length > 0) {
            if (this.getStaffResponse.staffItem.m_PAN_Number.length != 10) {
                this.response = "<span style='color:red;font-weight:bold;'>Invalid PAN Number</span>";
                return;
            }
        }

        params.append('m_AADHAR_Number', this.getStaffResponse.staffItem.m_AADHAR_Number);
        params.append('m_AADHAR_Name', this.getStaffResponse.staffItem.m_AADHAR_Name);
        params.append('m_AADHAR_FatherName', this.getStaffResponse.staffItem.m_AADHAR_FatherName);

        params.append('m_PAN_Number', this.getStaffResponse.staffItem.m_PAN_Number);
        params.append('m_PAN_Name', this.getStaffResponse.staffItem.m_PAN_Name);
        params.append('m_PAN_FatherName', this.getStaffResponse.staffItem.m_PAN_FatherName);

        params.append('m_CCTNo', this.getStaffResponse.staffItem.m_CCTNo);
        params.append('m_CCTCleardDate', this.getStaffResponse.staffItem.m_CCTCleardDate);
        params.append('m_RetentionBonusEffectiveDate', this.getStaffResponse.staffItem.m_RetentionBonusEffectiveDate);
        params.append('m_RetentionBonusAmount', this.getStaffResponse.staffItem.m_RetentionBonusAmount);

        //console.log("http://" + APIDomain + "/accessmanager/Update_Staffs?" + params);
        this.http.post("http://" + APIDomain + "/accessmanager/Update_Staffs", params
        ).subscribe(result => {
            this.postResponse = result.json() as PostResponse;
            if (this.postResponse.status) {
                this.response = this.postResponse.result;
                if (this.response === "Locked") {
                    this.bIsEdit = false;
                }
                if (this.lastAction === "update" || this.lastAction === "delete") {
                    
                    this.data.pop_Head = this.lastAction;
                    this.dialogRef.close(this.data);
                }
            } else {
                this.response = this.postResponse.result;
            }
        }, error => {
            //console.error(error);
            //this.response = error;
            //alert("FAILED-" + error);
            this.response = "Failed: " + this.postResponse.result;
        }
        );
    }
    pass_status: string = "Change Password";
    onChangePassword() {
        this.pass_status = "Please wait...";
        const params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('email', getCookie('login_email'));
        params.append('pass_new', document.getElementById("pass_new1")["value"]);
        params.append('pass_repeat', document.getElementById("pass_new2")["value"]);
        params.append('pass_old', document.getElementById("pass_old")["value"]);
        //alert(params);
        this.http.post("http://" + APIDomain + "/Tim/onChangePassword", params
        ).subscribe(result => {
            var resp = result.json() as PostResponse;
            this.pass_status = resp.result;
            if (resp.status) {
                document.getElementById("pass_new1")["value"] = "";
                document.getElementById("pass_new2")["value"] = "";
                document.getElementById("pass_old")["value"] = "";
                this.show_pass = false;
            } else {

            }
        }, error => {
            console.error(error);
            this.pass_status = error;
            //alert("FAILED-" + error);
        }
        );
    }
    OnBankChange(e) {
        
    }
    compareFn(c1: Bank, c2: Bank): boolean {
        return c1 && c2 ? c1.m_Name === c2.m_Name : c1 === c2;
    }
    onFileChanged(event) {
        if (event.target.files && event.target.files[0]) {
            this.selectedFile = event.target.files[0]
            if (this.selectedFile.size > 50000) {
                Swal.fire({
                    html:
                        "<u>Kindly ensure that your file size is less than 50Kb</u><br>" +
                        "The current file has the size of " + (this.selectedFile.size / 1000) + "Kb",
                    buttonsStyling: false,
                /*confirmButtonClass: "btn btn-success"*/
                });
                return;
            }
            //_____________________________________Try to show
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => { // called once readAsDataURL is completed
                this.myphoto = reader.result;//event.target.result;
                //this.myphoto = this.selectedFile.name;
            }
            this.onUpload();
        }
    }
    onUpload() {
        this.response = "<span style='color:red;font-weight:bold;'>Please wait...</span>";
        var m_StaffID = this.selected_row;//document.getElementById("m_StaffID")["value"];

        if (this.getStaffResponse.staffItem.m_Email.length === 0) {
            this.response = "<span style='color:red;'>Staff Email is empty</span>";
            return;
        }
        if (typeof this.selectedFile === 'undefined') {
            this.response = "<span style='color:red;'>Photo not selected</span>";
            return;
        }
        const uploadData = new FormData();
        uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
        this.http.post("http://" + APIDomain + '/accessmanager/UploadPhoto?m_StaffID=' +
            getCookie('login_profile') + "_" + 
            this.getStaffResponse.staffItem.m_Email,
            uploadData)
            .subscribe(event => {
                this.response = "<span style='color:blue;'>Photo uploaded</span>";
                this.global.iImageRandamize++;
                this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
                    getCookie('login_profile') + "_" +
                    this.getStaffResponse.staffItem.m_Email +
                    "&dummy=" + this.global.iImageRandamize;
                if ((<HTMLImageElement>document.getElementById("MyPhoto" + m_StaffID))!==null)
                    (<HTMLImageElement>document.getElementById("MyPhoto" + m_StaffID)).src = this.myphoto;

                //this.Merge();
                console.log(event); // handle event here
            }, error => {
                this.response = "<span style='color:blue;'>Upload Failed</span>";
                    //"<span style='font-size:small;color:black;'>" + error + "</span>";
            });
    }
    type_: string = "";
    OnFileChangedImg(event, type) {
        this.type_ = type;
        if (event.target.files && event.target.files[0]) {
            this.selectedFile = event.target.files[0]
            this.selectedFileType = type;
            if (this.selectedFile.size > 100000) {
                Swal.fire({
                    html:
                        "<u>Kindly ensure that your file size is less than 100Kb</u><br>" +
                        "The current file has the size of " + (this.selectedFile.size / 1000) + "Kb",
                    buttonsStyling: false,
                /*confirmButtonClass: "btn btn-success"*/
                });
                return;
            }
            //_____________________________________Try to show
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => { // called once readAsDataURL is completed
                //this.myphoto = reader.result;//event.target.result;
                //this.myphoto = this.selectedFile.name;
            }
            this.OnUploadImg(type);
        }
    }
    OnUploadImg(type) {
        this.response = "<span style='color:red;font-weight:bold;'>Please wait...</span>";
        if (this.getStaffResponse.staffItem.m_StaffID.length === 0) {
            this.response = "<span style='color:red;'>StaffID is empty</span>";
            return;
        }
        if (typeof this.selectedFile === 'undefined') {
            this.response = "<span style='color:red;'>Image not selected</span>";
            return;
        }
        const uploadData = new FormData();
        uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
        this.http.post("http://" + APIDomain + "/accessmanager/UploadImage?" +
            "profile=" + getCookie('login_profile') +
            "&user=" + getCookie('login_staffid') +
            "&type=" + type +
            "&staffid=" + this.getStaffResponse.staffItem.m_StaffID,
            uploadData)
            .subscribe(event => {
                this.response = "<span style='color:blue;'>Image uploaded</span>";
                this.ShowImage(this.type_);
                this.ShowScan = true;
                if (this.type_ === "aadhar") this.getStaffResponse.staffItem.m_AADHAR_Uploaded = "AADHAR Just uploaded";
                if (this.type_ === "pan") this.getStaffResponse.staffItem.m_PAN_Uploaded = "PAN Just uploaded";
            }, error => {
                this.response = "<span style='color:red;'>Upload Failed</span>";
                    //"<span style='font-size:small;color:black;'>"+error+"</span>";
            });
    }
    ShowImage(type) {
        this.srcScanImg = "http://" + APIDomain + "/data/scans/" +
            getCookie('login_profile')
            + "_" + type + "_" +
            this.getStaffResponse.staffItem.m_StaffID + ".jpg?random=" + Date.now();
        this.ShowScan = true;
    }
    transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    onNoClick(dataOut: DialogData): void {
        dataOut.pop_Head = this.lastAction;
        this.dialogRef.close(dataOut);
    }
    onYesClick(dataIn: DialogData): void {
        if (dataIn.pop_Head === 'Shift') {
            if (!this.IsValidTime(dataIn.pop_StartTime)) {
                this.response = "<span style='color:red;'>Invalid Start Time (HH:SS)</span>";
                return;
            }
            if (!this.IsValidTime(dataIn.pop_EndTime)) {
                this.response = "<span style='color:red;'>Invalid End Time (HH:SS)</span>";
                return;
            }
        }
        this.dialogRef.close(dataIn);
    }
    IsValidTime(timeString: string): boolean {
        //var pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;   // HH:MM:SS
        var pattern = /^([0-9]|[012][0-9]):[0-5][0-9]$/;   // HH:MM:SS
        if (!timeString.match(pattern)) return false;
        return true;
    }
    ngOnDestroy() {
    
        if (this.reportToAdminChangedSubscription != null) this.reportToAdminChangedSubscription.unsubscribe();
        if (this.reportToFuncChangedSubscription != null) this.reportToFuncChangedSubscription.unsubscribe();
    }
    PaySlip(email) {
        const dialogRef = this.dialog.open(PayslipComponent, {
            minWidth: '50em',
            width: '50%',
            data: {
                email: email,
                staffid: this.getStaffResponse.staffItem.m_StaffID,
                preview: "",
                year: "", month: ""
            }
        });
        dialogRef.afterClosed().subscribe((result) => {

            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {

                }
            }
        });
    }
    AttendanceMethod() {
        Swal.fire({
            html:
                "<u>Attendance Method</u><br><br>" +
                "<table style='text-align:left;'>" +
                "<tr style='border-bottom:1px solid #ccc;'><td><b>Functional</b></td><td>►</td><td>8 Hours of work</td></tr>" +
                "<tr style='border-bottom:1px solid #ccc;'><td><b>Administrative</b></td><td>►</td><td>8 Hours of work & 9 Hours of In/Out time span</td></tr>" +
                "</table>",
            buttonsStyling: false,
        /*confirmButtonClass: "btn btn-success"*/
        });
    }
    AttendanceSource() {
        Swal.fire({
            html:
                "<u>Attendance Source</u><br><br>" +
                "<table style='text-align:left;'>" +
                "<tr style='border-bottom:1px solid #ccc;'><td><b>Biometric</b></td><td>►</td><td>Bio Finger Print/Face Recognition like devices</td></tr>" +
                "<tr style='border-bottom:1px solid #ccc;'><td><b>Mobile</b></td><td>►</td><td>Mobile App based</td></tr>" +
                "<tr style='border-bottom:1px solid #ccc;'><td><b>Bio-Mobile</b></td><td>►</td><td>Combination of Biometric & Mobile</td></tr>" +
                "<tr style='border-bottom:1px solid #ccc;'><td style='white-space:nowrap;'><b>Access Manager</b></td><td>►</td><td>Custom windows application</td></tr>" +
                "</table>",
            buttonsStyling: false,
        /*confirmButtonClass: "btn btn-success"*/
        });
    }
    IsAllowed(key1, key2) {
        var menuKey = getCookie('menukey');
        if (menuKey.indexOf(key1) > -1) return true;
        if (menuKey.indexOf(key2) > -1) return true;
        return false;
    }
    PayscaleEffectiveDates() {
        
        if (!this.IsAllowed('u9-1', 'u9-2')) {
            Swal.fire({
                html:
                    "Sorry. You have no permission",
                buttonsStyling: false,
            /*confirmButtonClass: "btn btn-success"*/
            });
            return;
        }
        if (this.IsAllowed('u9-2', 'u9-2')) {
            if (!this.global.sarLoaded) this.LoadFixedArrays();
        }
        const dialogRef = this.dialog.open(PayscaleEffectiveDatesComponent, {
            width: '50%',
            data: {
                staffid: this.getStaffResponse.staffItem.m_StaffID,
                name: this.getStaffResponse.staffItem.m_FName,
                profile: getCookie('login_profile'),
                email:this.getStaffResponse.staffItem.m_Email
            }
        });
        dialogRef.afterClosed().subscribe((result: DialogData) => {
            this.response = "";
            if (result === null) {
                
            } else if (typeof result === "undefined") {
                
            } else {
                if (result.pop_Head === "delete") {
                    //this.Merge();
                }
            }
        });
    }
    GetStaffID() {
        if (!this.bIsEdit) {
            Swal.fire({
                html:
                    "Edit is diabled",
                buttonsStyling: false,
            /*confirmButtonClass: "btn btn-success"*/
            });
            return;
        }
        this.response = "<span style='color:red;font-weight:bold;'>Please wait...</span>";
        this.http.post("http://" + APIDomain + "/accessmanager/GetFreeStaffID",
            {
                "profile": getCookie('login_profile')
            }
        ).subscribe(result => {
            this.response = "";
            var response = result.json() as PostResponse;
            if (response.status) {
                this.getStaffResponse.staffItem.m_StaffID = response.sParam1;
            } else {
                alert("Sorry. Unable to get a staffID");
            }
        }, error => {
            this.response = "<span style='color:red;'>Error</span>";
        }
        );
    }
    ShowMasterLog(staff) {
        
        const dialogRef = this.dialog.open(MasterlogComponent, {
            width: '90%',
            data: {
                staffconcern: staff
            }
        });
        dialogRef.afterClosed().subscribe((result: DialogData) => {

        });
    }

    IsAdmin() {
        if (this.IsSA()) return true;
        return getCookie("menukey").indexOf("a0-1") > -1;
    }
    navigateTo(key) {
        this.router.navigateByUrl('/master/master?key=' + key);
        this.dialogRef.close();
    }
}
export interface PostResponse {
    status: boolean;
    result: string;
    sParam1: string;
}
export interface GetStaffResponse {
    status: boolean;
    result: string;
    //total_count { get; set; }
    staffItem: StaffItem
}
export interface DialogData {
    pop_Head: string;
    pop_Roster: string;
    pop_InputName: string;
    pop_PlaceHolder: string;
    pop_StartTime: string;
    pop_EndTime: string;
    staffItem: StaffItem;
    email: string;
}
export interface StaffItem {
    m_id: number;
    m_StaffID: string;
    m_FName: string;
    m_Activity: string;
    m_ActivityTime: string;
    m_IP: string;
    m_Lat: number;
    m_Lng: number;
    m_WorkTime: string;
    m_Username: string;
    m_ReasonHead: string;
    m_ReasonNote: string;
    m_Email: string;
    m_Mobile: string;
    m_Designation: string;
    m_Roll: string;
    m_Team: string;
    m_Base: string;
    m_Type: string;
    m_ReportToFunctional: string;
    m_ReportToAdministrative: string;
    m_MenuKey: string;
    m_Band: string;
    m_Grade: string;
    m_Mrs: string;
    m_ViewSelected: number;
    m_DOB: Date;
    m_DOJ: Date;
    m_DOA: Date;
    m_LWD: Date;
    m_Status: string;
    m_Lock: number;
    m_AttendanceMethod: string;
    m_Bank: string;
    m_AccountNo: string;
    m_Branch: string;
    m_IFSC: string;
    m_EPF_UAN: string;
    m_PayscaleName: string;
    m_PayscaleKey: number;
    m_PayscaleStartDate: number;
    m_ESICNumber: string;
    m_AttendanceSource: string;

    m_AADHAR_Uploaded: string;
    m_AADHAR_Number: string;
    m_AADHAR_Name: string;
    m_AADHAR_FatherName: string;
    m_PAN_Uploaded: string;
    m_PAN_Number: string;
    m_PAN_Name: string;
    m_PAN_FatherName: string;

    m_CCTNo: string;
    m_CCTCleardDate: string;
    m_RetentionBonusEffectiveDate: string;
    m_RetentionBonusAmount: string;
    
}
export interface FixedArrayResponse {
    status: boolean;
    result: string;
    sarTitles: string[];
    sarRolls: string[];
    sarTeams: string[];
    sarBases: string[];
    sarBands: string[];
    sarGrades: string[];
    sarPayscales: PayscalDropdownItem[];
    sarBanks: Bank[];
    sarRosterOptions: string[];
}
export interface PayscalDropdownItem {
    m_Name: string,
    m_Key: number,
    m_StartDate: number,
    m_Amount: number,
}
export interface Bank {
    m_Name: string,
    m_AccountNo:string,
    m_Branch: string,
    m_IFSC: string
}
class ReportingToItem {
    constructor(
        public Name: string,
        public Roll: string,
        public Base: string,
        public Email: string,
    ) { }
}