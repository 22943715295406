import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';


import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';
import {
    MatDialog, MatDialogRef, MAT_DIALOG_DATA,
} from '@angular/material/dialog';


import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { Payscale_MasterComponent } from '../../payroll/payscale_master/payscale_master.component';

@Component({
    selector: 'app-payscaleeffectivedates',
    templateUrl: 'payscaleeffectivedates.component.html',
    styleUrls: ['payscaleeffectivedates.component.css']
})
export class PayscaleEffectiveDatesComponent {
    //status: string = "";
    myphoto: any;
    leaveEditChk: boolean = false;
    selectedFile: File;
    selected_row: string;
    response: string;
    staffTypes: string[] = ["Trainee", "OnRole"];
    lastAction: string = "";
    public payscalesAssignedResponse: PayscalesAssignedResponse;
    //selected_row_email: string;
    bIsEdit: boolean = false;
    m_MenuKey: string = "";
    selectedgrp: string = "All";
    updatestatus: string = "";
    bViewSelected: boolean = false;
    leaveDays: string = "";
    leaveDescription: string = "";
    m_Payscale: PayscalDropdownItem;
    m_DtFrom: string = "";
    m_DtTo: string = "";
    CurrentTime: number;
    constructor(
        public dialogRef: MatDialogRef<PayscaleEffectiveDatesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public http: Http,
        public dialog: MatDialog,
         
        public global: GlobalProvider) {
        this.payscalesAssignedResponse = {} as PayscalesAssignedResponse;
        this.m_MenuKey = getCookie('menukey');
        this.bIsEdit = this.IsEdit();
        this.CurrentTime = ((new Date().getTime()) + 19800000)/1000;
        //-------------------------------
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.data.email + "&dummy=" + this.global.iImageRandamize;
    }
    ngOnInit() {
        //this.LoadLeaveData();
        this.CreateEffectiveDateSpan("",0);
    }
    GetPhoto() {
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.data.email + "&dummy=" + this.global.iImageRandamize;//Date.now().toString();
    }
    IsEdit() {
        if (this.IsSA()) return true;
        var menukey = getCookie('menukey');
        //if (menukey.indexOf("a0-1") > -1) return true;
        if (menukey.indexOf("u9-2") > -1) return true;
        return false;
    }
    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }
    IsSAemail(mail) {
        return getCookie('login_profile') === mail;
    }
    IsMale(mrs) {
        if (mrs === '1' || mrs === '11') return false;
        if (mrs === '0' || mrs === '10') return true;
        return true;
    }

    onNoClick(dataOut: DialogData): void {
        //dataOut.pop_Head = this.lastAction;
        this.dialogRef.close(dataOut);
    }
    onYesClick(dataIn: DialogData): void {
        /*
        if (dataIn.pop_Head === 'Shift') {
            if (!this.IsValidTime(dataIn.pop_StartTime)) {
                this.response = "Invalid Start Time (HH:SS)";
                return;
            }
            if (!this.IsValidTime(dataIn.pop_EndTime)) {
                this.response = "Invalid End Time (HH:SS)";
                return;
            }
        }
        */
        this.dialogRef.close(dataIn);
    }
    IsValidTime(timeString: string): boolean {
        //var pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;   // HH:MM:SS
        var pattern = /^([0-9]|[012][0-9]):[0-5][0-9]$/;   // HH:MM:SS
        if (!timeString.match(pattern)) return false;
        return true;
    }
    ngOnDestroy() {

    }
    compareFn(c1: PayscalDropdownItem, c2: PayscalDropdownItem): boolean {
        return c1 && c2 ? c1.m_Name === c2.m_Name : c1 === c2;
    }
    CreateEffectiveDateSpan(mode,m_id) {
        var dtFrom = 0, dtTo = 0;
        if (mode === "delete") {
            this.global.sarLoaded = false;
        }else
        if (mode === "insert") {
            this.global.sarLoaded = false;
            var ret = this.global.IsDate(this.m_DtFrom);
            if (ret === null) {
                Swal.fire({
                    html:
                        "Invalid Date. [dd-mm-yyyy]",
                    buttonsStyling: false,
                    /*   confirmButtonClass: "btn btn-success"*/
                });//.catch(swal.noop)
                return;
            }
            
            dtFrom = (ret.getTime() / 1000);

            if (dtFrom < this.m_Payscale.m_Key) {
                Swal.fire({
                    html:
                        "Sorry. Start date is smaller than Payscale Date",
                    buttonsStyling: false,
                    /*   confirmButtonClass: "btn btn-success"*/
                });//.catch(swal.noop)
                return;
            }
            /*
            if (this.m_DtTo.length > 0) {
                ret = this.global.IsDate(this.m_DtTo);
                if (ret === null) {
                    this.response = "Invalid 'to' date. Leave empty for indefinite";
                    return;
                }
                dtTo = ret.getTime() / 1000;
            }
            */
        }
        this.response = "Please wait...";
        const params = new URLSearchParams();
        params.append('m_id', m_id+"");
        params.append('profile', this.data.profile);
        params.append('email', getCookie('login_email'));
        params.append('staffid_loggedin', getCookie('login_staffid'));
        params.append('staffid', this.data.staffid);
        params.append('dtFrom', dtFrom.toString());
        //params.append('dtTo', dtTo.toString());
        if (this.m_Payscale) {
            params.append('payscale', this.m_Payscale.m_Name);
            params.append('key', this.m_Payscale.m_Key + "");
        }
        
        params.append('mode', mode);
        console.log(params);
        this.http.post("http://" + APIDomain + "/accessmanager/CreateEffectiveDateSpan", params
        ).subscribe(result => {
            this.payscalesAssignedResponse = result.json() as PayscalesAssignedResponse;
            this.response = this.payscalesAssignedResponse.result;
            this.m_DtFrom = "";
        }, error => {
            this.response = "Failed: " + this.payscalesAssignedResponse.result;
        }
        );
    }
    IsAllowed(key1, key2) {

        if (this.m_MenuKey.indexOf(key1) > -1) return true;
        if (this.m_MenuKey.indexOf(key2) > -1) return true;
        return false;
    }
    PaySlipMaster(name, key) {
        const dialogRef = this.dialog.open(Payscale_MasterComponent, {
            minWidth: '50em',
            width: '90%',
            data: {
                pop_Head: "", pop_InputName: '', pop_PlaceHolder: "",
                pop_StartTime: '', pop_EndTime: '', pop_Roster: "",
                name: name,key:key
            }
        });
        dialogRef.afterClosed().subscribe((result) => {

            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {

                }
            }
        });

    }
    GetScaleColor(val:number) {
        if (this.CurrentTime < val) return { 'color': 'orangered' }
        return { 'color': 'black' }
    }
}
export interface DialogData {
    email: string;
    profile: string;
    staffid: string;
    name: string;
}
export interface PayscalDropdownItem {
    m_Name: string,
    m_Key: number,
    m_StartDate: number,
    m_Amount: number,
}
interface PayscalesAssignedResponse {
    status: boolean;
    result: string;
    rows: PayscalesAssignedRow[];
}
interface PayscalesAssignedRow {
    m_id: number;
    name: string;
    effectivedate: number;
    startdate: number;
    allowdelete: number;
    createdby: string;
    ctc: string;
    createdtime: string;
}