import { Routes } from '@angular/router';

import { PayrollComponent } from './payroll.component';
import { PayscalesComponent } from './payscales/payscales.component';
import { MyPayslipsComponent } from './my_payslips/my_payslips.component';

export const PayrollRoutes: Routes = [

    {
        path: '',
        children: [
            {
                path: '',
                component: PayrollComponent
            }, {
                path: 'payscales',
                component: PayscalesComponent
            }, {
                path: 'my_payslips',
                component: MyPayslipsComponent
            }
        ]
    }
];
