import { Injectable, NgZone, ViewChild } from '@angular/core';
import { Subject ,  BehaviorSubject } from "rxjs";
import * as signalR from "@aspnet/signalr"
import { set as setCookie, get as getCookie } from 'es-cookie';
import { APIDomain, JobMessage, HubObject } from './providers/global/global';
import { Dashboard_AccessManagerComponent } from './dashboard_accessmanager/dashboard_accessmanager.component';
declare var $: any;
declare var require: any

@Injectable()
export class SignalrService {
    public dashboard_AccessManagerComponent: Dashboard_AccessManagerComponent;
    name: any;
    public borrowerChanged = new BehaviorSubject<object>({ "comm": 0, "str": "", "obj": {}});
    borrowerChangedObservable = this.borrowerChanged.asObservable();

    public hubObjectChanged = new BehaviorSubject<HubObject>({ "Mode": "", "sData": "", "sMess": "","lData":0});
    hubObjectChangedObservable = this.hubObjectChanged.asObservable();

    private connection: any=null;
    private proxy: any;
    private ulr: any;
    info: Info = { data: "Jack" };
    constructor(private zone: NgZone ) {
        this.name = "Jack";
        this.connection = null;
    }

    public startConnection(): void {
        if (this.connection != null) {
            ///alert("Already in connection");

            return;
        }

        if (!getCookie("login_profile")) return;
        //alert("startConnection-" + getCookie("login_email"));

        this.connection = $.hubConnection("http://" + APIDomain + '/signalr',
            {
                qs: 'imei=' + getCookie("login_email") + '&type=browser&profile=' + getCookie("login_profile"),
                logging: false,
                useDefaultPath: false
            });

        this.proxy = this.connection.createHubProxy('ChatHub');
        /*
        this.proxy.on('addNewMessageToPage', (dataLoc: string) => {
            this.zone.run(() => {
                alert("addNewMessageToPage");
                this.borrowerChanged.next(dataLoc);
                this.info.data = dataLoc;
            });
        });  
        */
        this.proxy.on('DataToClient', (gpsdata: string) => {
            this.zone.run(() => {
                //alert("DataToClient>" + gpsdata);
                this.borrowerChanged.next({ "comm": 1, "str": gpsdata, "obj": {} });
            });
        });
        
        this.proxy.on('broadcastMessage', (label: string,json:string) => {
            this.zone.run(() => {
                //this.dashboard_AccessManagerComponent.LoadDesktop();
                //alert("broadcastMessage>" + label + "____" + json);
                this.borrowerChanged.next({ "comm": 1, "str": json, "obj": {} });
                //this.info.data = json;
            });
        });
        
        this.proxy.on('jobMessageToClient', (o: JobMessage) => {
            this.zone.run(() => {
                this.borrowerChanged.next({ "comm": 2, "str": "", "obj": o });
                //this.info.data = json;
            });
        });
        this.proxy.on('HubToBrowser', (o: HubObject) => {
            console.log("SignalR Data Received [" + o.Mode + "]");
            this.zone.run(() => {
                this.hubObjectChanged.next(o);
            });
        });
        this.connection.start().done((data: any) => {
            console.log('Connected to Processing Hub');
            //this.sendMessage("xxx");
        }).catch((error: any) => {
            console.log('Hub error -> ' + error);
        });
        
    }
    public StopConnection(): void {
        if (this.connection == null) {
            alert("Why null?");
        } else {
            this.connection.stop();
            this.connection = null;
        }
        //this.connection.dispose();
    }
    //  {I...}
    public sendMessage(mess: string): void {
        this.proxy.invoke('Broadcast', 'test', mess)
            .then(() => { })
            .catch((error: any) => {
                console.log('SendMessage error -> ' + error);
            });
    }
    public test():void {
        this.proxy.invoke('greymobile', '000000000000001', {"comm":"reload"})
            .then(() => { })
            .catch((error: any) => {
                console.log('SendMessage error -> ' + error);
            });
    }
    public SignalRCommand(imei: string, obj: Object) {
        this.proxy.invoke('greymobile', imei, obj)
            .then(() => { })
            .catch((error: any) => {
                console.log('SendMessage error -> ' + error);
            });
    }
}
export interface Info {
    data: string;
}
