import { Component, OnInit,ViewChild } from '@angular/core';
import { Http, URLSearchParams, RequestOptions } from '@angular/http';

//import { MatCalendar } from '@angular/material/calender';
//import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj } from '../../providers/global/global';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-terminals',
    templateUrl: './terminals.component.html',
    styleUrls: ['./terminals.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TerminalsComponent implements OnInit {
    //public onProfileResponse: OnProfileResponse;
    selectedItem: string;
    m_Name: string;
    selectedFile: File;
    ShowMap: boolean = false;
    myphoto: any;
    m_Lat: number;
    m_Lng: number;
    expandedElement: any;
    showoptions: number; 
    //___________________________Material
    status: string;
    //private _signalrObservable = null;
    private _searchObservable = null;
    timeZoneOffset: string;

    response: string;
    selected_m_ID: number;

    //displayedColumns: string[] = ['m_HardwareID', 'photo', 'm_Name', 'm_Activity', 'm_ActivityStart', 'm_ActivityTime', 'm_WorkTime', 'terminal', 'expandMap'];
    //displayedColumns: string[] = ['photo', 'm_HardwareID','m_StaffID','m_Team', 'm_Version','m_Activity', 'm_ActivityStart', 'm_ActivityTime', 'terminal', 'expandMap'];
    displayedColumns: string[] = ['photo', 'm_HardwareID', 'm_StaffID', 'm_Team', 'm_Version', 'm_Activity', 'm_ActivityTime', 'terminal'];
    exampleDatabase: ExampleHttpDao | null;
    data: TerminalActivityRow[] = [];

    resultsLength = 0;
    page_size = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    iImageRandamize: number = 1000;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    terminalActivityRow = {} as TerminalActivityRow;

    public postResponse: PostResponse;
    globalSearch: string = "";
    bFirstRun: boolean = false;
    //___________________________Material END
    constructor(public http: Http, private router: Router, private httpClient: HttpClient,
        private sanitizer: DomSanitizer,
         public global: GlobalProvider) {

        if (getCookie("login_profile").length === 0) {
            this.router.navigateByUrl('/pages/login');
            return;
        }
        this.expandedElement = "";
        this.m_Lat = 13;
        this.m_Lng = 80;
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx";
    }
    ngAfterViewInit() {
        this.global.globalSearch = "";
        this.Merge();
        this.bFirstRun = true;
    }
    ngOnInit() {
        //this.OnListClk("");
        this._searchObservable = this.global.currentMessage.subscribe(search => {
            if (search != null) {
                if (this.bFirstRun) {
                    this.paginator.pageIndex = 0
                    this.globalSearch = search;
                    this.Merge();
                }
            }
        });
        /*
                this._signalrObservable = this._signalrService.borrowerChangedObservable
                    .subscribe((obj: SubObj) => {
                        if (obj.comm == 1) {
                            if (obj.str.substring(0, 2) === "{G") { // reload
                                //this.LoadTrips();
                            }
                        }
                    });
        */
        //_________________________________
        this.timeZoneOffset = (new Date().getTimezoneOffset() * 60).toString();
        this.status = "";
        //__________________________________Material
        this.exampleDatabase = new ExampleHttpDao(this.httpClient);
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        //__________________________________Material END
    }
    //
    public getSantizeUrl(lat: string, lng: string) {
        var url = "https://www.google.com/maps/embed/v1/view?key=AIzaSyCqIDGJZXbP6ICWEQn69JA0EeuA1E0POzI&center=" + lat + "," + lng + "&zoom=14&maptype=roadmap";
        //var url = "http://maps.google.com/maps?q=" + lat + "," + lng + "&z=15&output=embed&key=AIzaSyCqIDGJZXbP6ICWEQn69JA0EeuA1E0POzI";
        //return this.sanitizer.bypassSecurityTrustUrl(url);
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    Merge() {
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.exampleDatabase!.getRepoIssues(
                        this.sort.active, this.sort.direction, this.paginator.pageIndex,
                        this.globalSearch, getCookie('login_profile'),
                        this.showoptions);
                }),
                map(data => {
                    // Flip flag to show that loading has finished.
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data.total_count;
                    this.page_size = data.page_size;
                    return data.items;
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    // Catch if the GitHub API has reached its rate limit. Return empty data.
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.data = data);
    }
    ngOnDestroy() {
        //if (this._signalrObservable != null) this._signalrObservable.unsubscribe();
        if (this._searchObservable != null) this._searchObservable.unsubscribe();
    }

    GetListSelectedColor(item): any {
        if (item === this.selectedItem)
            return { 'background-color': '#ddd', 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'border': '0.5px solid #b4b4b4', 'white-space': 'nowrap' };
        else return { 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'white-space': 'nowrap' };
    }

    GetPhoto(email) {
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + email + "&dummy=" + this.iImageRandamize;//Date.now().toString();
    }
    OnExpandMap(expandedElementMap, row) {
        if (expandedElementMap === row) return "";
        this.selected_m_ID = row.m_ID;
        this.m_Lat = row.m_Lat;
        this.m_Lng = row.m_Lng;
        /*
        this.terminalActivityRow.m_id = row.m_id;
        this.terminalActivityRow.m_NameCompany = row.m_NameCompany;
        this.terminalActivityRow.m_NameUser = row.m_NameUser;
        this.terminalActivityRow.m_PickTime = row.m_PickTime;
        this.terminalActivityRow.m_PickWeight = row.m_PickWeight;
        */
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.terminalActivityRow.m_Email + "&dummy=" + this.iImageRandamize;//Date.now().toString();
        return row;
    }
    OnExpand(expandedElement, row) {
        if (expandedElement === row) return "";
        this.selected_m_ID = row.m_ID;
        this.m_Lat = row.m_Lat;
        this.m_Lng = row.m_Lng;
        /*
        this.terminalActivityRow.m_id = row.m_id;
        this.terminalActivityRow.m_NameCompany = row.m_NameCompany;
        this.terminalActivityRow.m_NameUser = row.m_NameUser;
        this.terminalActivityRow.m_PickTime = row.m_PickTime;
        this.terminalActivityRow.m_PickWeight = row.m_PickWeight;
        */
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.terminalActivityRow.m_Email + "&dummy=" + this.iImageRandamize;//Date.now().toString();
        return row;
    }
    GetAccess() {
        if (getCookie('login_profile') === getCookie('login_email')) return 2;
        var key = getCookie("menukey");
        //if (key.indexOf("a0-1") > -1) return 2;
        if (key.indexOf("s4-1") > -1) return 1;
        if (key.indexOf("s4-2") > -1) return 2;
        return 0;
    }
    OffTerminal(row: TerminalActivityRow, command) {
        if (row.LiveSince > 300) {
            Swal.fire(
                {
                    title: 'Sorry',
                    text: "Terminal does not seem to be online now",
                    /*type: 'error',
                    confirmButtonClass: "btn btn-info",*/
                    buttonsStyling: false
                }
            )
        } else {
            Swal.fire({
                title: 'Are you sure?',
                html: "This will make the remote computer <span style=\'color:red;font-weight:bold;\'>" + command +"</span>",
                /*type: 'warning',*/
                showCancelButton: true,
               /* confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',*/
                confirmButtonText: 'Yes, Go ahead!',
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    //alert(row.m_HardwareID);
                    this.OffTerminal_Sub(row, command);
                }
            });
        }
    }
    OffTerminal_Sub(row: TerminalActivityRow, command) {
        const params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('hardware', row.m_HardwareID);
        params.append('command', command);//"Lock"  "Sleep"
        this.http.post("http://" + APIDomain + "/accessmanager/SendTerminalCommand", params
        ).subscribe(result => {
            this.postResponse = result.json() as PostResponse;
            if (this.postResponse.status) {
                Swal.fire(
                    {
                        title: 'Success.',
                        text: this.postResponse.result,
                        /*type: 'success',
                        confirmButtonClass: "btn btn-success",*/
                        buttonsStyling: false
                    }
                )
                this.Merge();
            } else {
                Swal.fire(
                    {
                        title: 'Failed',
                        text: this.postResponse.result,
                        /*type: 'error',
                        confirmButtonClass: "btn btn-success",*/
                        buttonsStyling: false
                    }
                )
            }
        }, error => {
            console.error(error);
            this.response = error;
            alert("FAILED-" + error);
        }
        );
    }
    GetMACColor(row: TerminalActivityRow) {
        if (row.LiveSince < 300) {
            if (row.m_Activity === "lockstate" ||
                row.m_Activity === "forcedlock" ||
                row.m_Activity === "lock"
            ) {
                return { 'color': 'blue' };
            } else {
                return { 'color': 'green' };
            }
        } else {
            return { 'color': 'orangered' };
        }
    }
    OnRadio(e) {
        this.Merge();
    }
}

export interface  TerminalActivityResponse {
    items: TerminalActivityRow[];
    total_count: number;
    page_size: number;
}

export interface TerminalActivityRow {
    m_id: number;
    m_HardwareID: string;
    m_HardwareName: string;
    m_IP: string;
    m_Name: string;
    m_Email: string;
    m_StaffID: string;
    m_Activity: string;
    m_ActivityStart: string;
    m_ActivityTime: string;
    m_Lat: number;
    m_Lng: number;
    m_WorkTime: string;
    LiveSince: number;
    SinceActivity: number;
    m_Version: string;
}
export interface PostResponse {
    status: boolean;
    result: string;
}
/** An example database that the data source uses to retrieve data for the table. */
export class ExampleHttpDao {
    constructor(private http: HttpClient) { }

    getRepoIssues(sort: string, order: string, page: number, search: string, profile: string,showoptions:number): Observable<TerminalActivityResponse> {
        const href = 'http://' + APIDomain + '/accessmanager/Terminals';
        const requestUrl =
            `${href}?q=q&sort=${sort}&order=${order}&page=${page + 1}&search=${search}&profile=${profile}&showoptions=${showoptions}`;
        //console.log(requestUrl);
        return this.http.get<TerminalActivityResponse>(requestUrl);
    }
}