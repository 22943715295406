
import {distinctUntilChanged, debounceTime,  catchError, map, startWith, switchMap, filter } from 'rxjs/operators';
/// <reference path="../../shared/navbar/navbar.component.ts" />
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Http, URLSearchParams, RequestOptions, Headers, ResponseContentType } from '@angular/http';
import { SignalrService } from '../../signalr.service';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { GlobalProvider, APIDomain, SubObj } from '../../providers/global/global';


import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';


import 'rxjs/Rx';
//import { privateDecrypt } from 'crypto';
/*
 https://material.angular.io/components/table/examples
 autocomplete
 https://stackblitz.com/edit/angular-material-autocomplete?file=app%2Fautocomplete-overview-example.html
 * */
@Component({
    selector: 'app-usersmobile',
    templateUrl: './usersmobile.component.html',
    styleUrls: ['./usersmobile.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class UsersMobileComponent implements OnInit {
    //___________________________Material
    status: string;
    private _signalrObservable = null;
    private _searchObservable = null;
    timeZoneOffset: string;

    response: string;
    selected_m_id: object;

    //displayedColumns: string[] = ['cars.m_id', 'cars.m_Make', 'cars.m_Model', 'cars.m_DOR', 'assign.m_RegNo', 'assign.m_FleetID', 'assign.m_Group', 'assign.m_DriverID1','assign.m_DeviceIMEI','expand'];
    displayedColumns: string[] = ['user.m_IMEI', 'user.m_Name', 'user.m_Mobile', 'user.m_CreatedTime', 'user.m_Version', 'user.m_Status', 'user.m_LinkedProfile', 'expand'];
    exampleDatabase: ExampleHttpDao | null;
    data: MobileUser[] = [];

    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    expItemBefore: ExpItem;
    expItem: ExpItem;
    public postResponse: PostResponse;
    globalSearch: string="";
    bFirstRun: boolean = false;
    //___________________________Material END
    activation: Group[] = [
        { value: '', viewValue: '' },
        { value: 'Verified', viewValue: 'Verified' },
        { value: 'Deactivated', viewValue: 'Deactivated' }
    ];


    stateCtrl: FormControl;
    filteredDrivers: Observable<any[]>;
    filteredLinkedProfiles: Observable<any[]>;
    states: State[] = [
        {
            m_id: 'Arkansas',
            m_FName: '2.978M',
            // https://commons.wikimedia.org/wiki/File:Flag_of_Arkansas.svg
            m_StaffID: 'https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Arkansas.svg'
        },
        {
            m_id: 'California',
            m_FName: '39.14M',
            // https://commons.wikimedia.org/wiki/File:Flag_of_California.svg
            m_StaffID: 'https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_California.svg'
        },
        {
            m_id: 'Florida',
            m_FName: '20.27M',
            // https://commons.wikimedia.org/wiki/File:Flag_of_Florida.svg
            m_StaffID: 'https://upload.wikimedia.org/wikipedia/commons/f/f7/Flag_of_Florida.svg'
        },
        {
            m_id: 'Texas',
            m_FName: '27.47M',
            // https://commons.wikimedia.org/wiki/File:Flag_of_Texas.svg
            m_StaffID: 'https://upload.wikimedia.org/wikipedia/commons/f/f7/Flag_of_Texas.svg'
        }
    ];
    //model: string;
    driverChanged: Subject<string> = new Subject<string>();
    deviceChanged: Subject<string> = new Subject<string>();
    linkedProfileChanged: Subject<string> = new Subject<string>();
    public driversResponse: DriversResponse;
    driverChangedSubscription: Subscription = null;
    linkedProfileChangedSubscription; Subscription = null;
    //______________________________________________
    constructor(private http: Http, private httpClient: HttpClient,private _signalrService: SignalrService, private router: Router,
         private datePipe: DatePipe,
        private global: GlobalProvider)
    {
        if (getCookie("login_profile").length === 0) {
            this.router.navigateByUrl('/pages/login');
            return;
        }
        this.expItem = new ExpItem();
        this.expItemBefore = new ExpItem();
        this.stateCtrl = new FormControl();
        this.driverChangedSubscription =
        this.driverChanged.pipe(
            debounceTime(300), // wait 300ms after the last event before emitting last event
            distinctUntilChanged(),) // only emit if value is different from previous value
            .subscribe(model => {
                //this.model = model;
                this.filteredDrivers = this.searchDrivers(model, getCookie('login_profile'));
                });
        this.filteredDrivers = this.searchDrivers("", getCookie('login_profile'));

        this.linkedProfileChangedSubscription =
        this.linkedProfileChanged.pipe(
            debounceTime(300), // wait 300ms after the last event before emitting last event
            distinctUntilChanged(),) // only emit if value is different from previous value
            .subscribe(model => {
                //this.model = model;
                this.filteredLinkedProfiles = this.searchLinkedProfiles(model, getCookie('login_profile'));
                });

        this.filteredLinkedProfiles = this.searchLinkedProfiles("",getCookie('login_profile'));
    }

    searchDrivers(search: string,profile:string): Observable<SearchDriverItem[]> {
        let apiURL = `${'http://' + APIDomain + '/Tim/SearchDrivers'}`; //&media=music&limit=20&callback=JSONP_CALLBACK
        const params = new URLSearchParams();
        params.set('search', search);
        params.set('profile', profile);
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');

        const options = new RequestOptions({
            headers: headers,
            responseType: ResponseContentType.Json,
            params: params,
            withCredentials: false
        });

        return this.http.post(apiURL, {}, options).pipe(
            map(res => {
                return res.json().items.map(item => {
                    return new SearchDriverItem(
                        item.m_id,
                        item.m_FName,
                        item.m_StaffID
                    );
                });
            }));
    }
    searchLinkedProfiles(search: string, profile: string): Observable<SearchLinkedProfileItem[]> {
        let apiURL = `${'http://' + APIDomain + '/Tim/searchLinkedProfiles'}`; //&media=music&limit=20&callback=JSONP_CALLBACK
        const params = new URLSearchParams();
        params.set('search', search);
        params.set('profile', profile);
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');

        const options = new RequestOptions({
            headers: headers,
            responseType: ResponseContentType.Json,
            params: params,
            withCredentials: false
        });

        return this.http.post(apiURL, {}, options).pipe(
            map(res => {
                return res.json().items.map(item => {
                    return new SearchLinkedProfileItem(
                        item.m_id,
                        item.m_Email
                    );
                });
            }));
    }
    GetPhoto(staffid) {
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" + getCookie('login_profile') + "_" + staffid + "&dummy=";// + this.iImageRandamize;//Date.now().toString();
    }
    KeyPressDriver(value: string) {
        //console.log("KeyPress:"+value);
        this.driverChanged.next(value);
    }
    KeyPressDevice(value: string) {
        //console.log("KeyPress:"+value);
        this.linkedProfileChanged.next(value);
    }
    onEnter(evt: any) {
        //if (evt.source.selected) {
            alert(evt);
        //}
    } 
    ngAfterViewInit() {
        this.global.globalSearch = "";
        this.Merge();
        this.bFirstRun = true;
    }
    ngOnInit() {
        this._searchObservable = this.global.currentMessage.subscribe(search => {
            if (search != null) {
                if (this.bFirstRun) {
                    this.paginator.pageIndex = 0
                    this.globalSearch = search;
                    this.Merge();
                }
            }
        });
        this._signalrObservable = this._signalrService.borrowerChangedObservable
            .subscribe((obj: SubObj) => {
                if (obj.comm == 1) {
                    if (obj.str.substring(0, 2) === "{G") { // reload
                        //this.LoadTrips();
                    }
                }
            });
        //_________________________________
        this.timeZoneOffset = (new Date().getTimezoneOffset() * 60).toString();
        this.status = "";
        //__________________________________Material
        this.exampleDatabase = new ExampleHttpDao(this.httpClient);
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        //__________________________________Material END
    }
    Merge() {
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.exampleDatabase!.getRepoIssues(
                        this.sort.active, this.sort.direction, this.paginator.pageIndex,
                        this.globalSearch, getCookie('login_profile'));
                }),
            map((data: MobileUsersResponse) => {
                    // Flip flag to show that loading has finished.
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data.total_count;

                    return data.items;
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    // Catch if the GitHub API has reached its rate limit. Return empty data.
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
        ).subscribe((data: MobileUser[]) => this.data = data);
    }
    ngOnDestroy() {
        if (this._signalrObservable != null) this._signalrObservable.unsubscribe();
        if (this._searchObservable != null) this._searchObservable.unsubscribe();
        if (this.driverChangedSubscription != null) this.driverChangedSubscription.unsubscribe();
        if (this.linkedProfileChangedSubscription != null) this.linkedProfileChangedSubscription.unsubscribe();
    }


    GetHHMMSS(tme) {
        var date = new Date(tme * 1000);
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        var hours = "0" + date.getHours();
        var minutes = "0" + date.getMinutes();
        var seconds = "0" + date.getSeconds();
        return hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    }
    FormatDtTime(tme) {
        var date = new Date(tme * 1000);
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        var year = date.getFullYear();
        var month = "0" + date.getMonth();
        var dat = "0" + date.getDate();
        var hours = "0" + date.getHours();
        var minutes = "0" + date.getMinutes();
        var seconds = "0" + date.getSeconds();
        return year + "-" + month.substr(-2) + "-" + dat.substr(-2) + " " + hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    }
    FormatAmount(amt) {
        return (Number(amt) / 100).toFixed(2);;
    }
    GetJob(id) {
        if (id.length != 0) {
            return "(Job " + id+")";
        } else {
            return "";
        }
    }
    GetDvrPhoto(staffid) {
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" + staffid;// + "&dummy=" + Date.now().toString();
    }
    OnUpdate_LinkedProfile(m_id,mode) {
        this.response = "Please wait...";
        var bChanged = 0;
        /*
        var dt = "";
        try {
            dt = this.datePipe.transform(this.expItem.vehicle.m_DOR, "yyyy-MM-dd")
        } catch (x) {
            alert(x);
        }
        */
        //alert(this.expItemBefore.vehicle.m_Make + "____" + this.expItem.vehicle.m_Make)
        const params = new URLSearchParams();
        params.append('mode', mode);
        params.append('m_id', this.expItem.linkedProfile.m_id);
        params.append('profile', getCookie('login_profile'));
        if (this.expItemBefore.linkedProfile.m_Name !== this.expItem.linkedProfile.m_Name) {
            params.append('m_Name', this.expItem.linkedProfile.m_Name); bChanged = 1;
        }
        if (this.expItemBefore.linkedProfile.m_Mobile !== this.expItem.linkedProfile.m_Mobile) {
            params.append('m_Mobile', this.expItem.linkedProfile.m_Mobile); bChanged = 1;
        }
        if (this.expItemBefore.linkedProfile.m_Status !== this.expItem.linkedProfile.m_Status) {
            params.append('m_Status', this.expItem.linkedProfile.m_Status); bChanged = 1;
        }
        

        if (this.expItemBefore.linkedProfile.m_LinkedProfile !== $("#autoDevice_" + m_id).val().toString()) {
            params.append('m_LinkedProfile', $("#autoDevice_" + m_id).val().toString()); bChanged = 1;
        }
        //alert(params);
        if (bChanged == 1)
        {
            this.http.post("http://" + APIDomain + "/Tim/Update_LinkedProfile", params
            ).subscribe(result => {
                this.postResponse = result.json() as PostResponse;
                if (this.postResponse.status) {
                    this.Merge();
                    //alert(this.postResponse.result)
                } else {
                    console.log("false-" + this.postResponse.result);
                    alert("false-" + this.postResponse.result);
                }
                this.response = this.postResponse.result;
            }, error => {
                console.error(error);
                this.response = error;
                alert("FAILED-" + error);
            }
            );
        } else {
            this.response = "Nothing is changed";
        }
    }
    OnExpand(expandedElement, row) {
        if (expandedElement === row) return "";

        this.selected_m_id = row.m_id;

        //- - - - - - - - - - - - - - - - - -m_LinkedProfile
        this.expItem.linkedProfile.m_id = row.m_id;
        this.expItem.linkedProfile.m_Name = row.m_Name;
        this.expItem.linkedProfile.m_Mobile = row.m_Mobile;
        this.expItem.linkedProfile.m_Status = row.m_Status;
        this.expItem.linkedProfile.m_LinkedProfile = row.m_LinkedProfile;
        //this.GetVehicleExpandInfo(this.expItem.assign.m_DriverID1, this.expItem.assign.m_DeviceIMEI);
        return row;
    }
    NewEntry()
    {
        this.OnUpdate_LinkedProfile('',"new");
    }
    GetVehicleExpandInfo(m_DriverID1, m_DeviceIMEI) {
        var res: MobileUser;
        const params = new URLSearchParams();
        params.append('m_StaffID', m_DriverID1);
        params.append('m_DeviceIMEI', m_DeviceIMEI);
        params.append('profile', getCookie('login_profile'));
        this.http.post("http://" + APIDomain + "/Tim/GetVehicleExpandInfo", params
        ).subscribe(result => {
            
            res = result.json() as MobileUser;
            //this.expItem.driver.m_FName = res.m_FName;
            //this.expItem.driver.m_Mobile = res.m_Mobile;
            //this.expItem.device.m_Make_device = res.m_Make;
            //this.expItem.device.m_Model_device = res.m_Model;
            //this.expItem.device.m_SIMMobileNo = res.m_SIMMobileNo;
            //this.groups = res.groupList;
        }, error => {
            console.error(error);
            this.response = error;
            alert("FAILED-" + error);
        }
        );
    }
}


//export interface VehiclesResponse {
export interface MobileUsersResponse {
    items: MobileUser[];
    total_count: number;
}

//export interface VehicleItem {
export interface MobileUser {
    m_id: number;
    m_IMEI: string;
    m_Name: string;
    m_Mobile: string;
    m_CreatedTime: string;
    m_Version: string;
    m_Status: string;
    m_LinkedProfile: string;
}
export interface PostResponse {
    status: boolean;
    result: string;
}
//____________________________________Search Drivers
export interface DriversResponse {
    status: boolean;
    result: string;
    items: DriverItem[];
}
export interface DriverItem {
    m_id: number;
    m_FName: string;
    m_StaffID: string;
    m_DriverID1: string;
    m_DeviceIMEI: string;
    m_Mobile: string;
}
//____________________________________Search Drivers END
/*
export class State {
    constructor(public name: string, public population: string, public flag: string) { }
}
*/
export class State {
    constructor(public m_id: string, public m_FName: string, public m_StaffID: string) { }
}
class SearchDriverItem {
    constructor(
        public m_id: string,
        public m_FName: string,
        public m_StaffID: string
    ) { }
}
class SearchDeviceItem {
    constructor(
        public m_id: string,
        public m_IMEI: string,
        public m_SIMMobileNo: string,
        public m_Time: string,
        public m_Make: string,
        public m_Model: string
    ) { }
}
class SearchLinkedProfileItem {
    constructor(
        public m_id: string,
        public m_Email: string,
    ) { }
}
class ExpItem {
    public vehicle: ExpItem_Vehicle;
    public driver: ExpItem_Driver;
    public device: ExpItem_Device;
    public assign: ExpItem_Assign;
    public linkedProfile: ExpItem_LinkedProfile;
    constructor() {
        this.vehicle = new ExpItem_Vehicle();
        this.driver = new ExpItem_Driver();
        this.device = new ExpItem_Device();
        this.assign = new ExpItem_Assign();
        this.linkedProfile = new ExpItem_LinkedProfile();
    }
}
class ExpItem_LinkedProfile {
    public m_id: string = "";
    public m_Name: string = "";
    public m_Mobile: string = "";
    public m_Status: string = "";
    public m_LinkedProfile: string = "";
}
class ExpItem_Vehicle {
    public m_id: string = "";
    public m_Make: string = "";
    public m_Model: string = "";
    public m_DOR: string = "";
    constructor() { }
}
class ExpItem_Driver {
    public m_FName: string = "";
    public m_Mobile: string = "";
    constructor() { }
}
class ExpItem_Device {
    public m_IMEI: string="";
    public m_Make_device: string = "";
    public m_Model_device: string = "";
    public m_SIMMobileNo: string = "";
    constructor() { }
}
class ExpItem_Assign {
    public m_DriverID1: string = "";
    public m_DeviceIMEI: string = "";
    public m_RegNo: string = "";
    public m_FleetID: string = "";
    public m_Group: string = "";
    constructor( ) { }
}
export interface Group {
    value: string;
    viewValue: string;
}
/** An example database that the data source uses to retrieve data for the table. */
export class ExampleHttpDao {
    constructor(private http: HttpClient) { }

    getRepoIssues(sort: string, order: string, page: number, search: string, profile: string): Observable<MobileUsersResponse> {
        const href = "http://" + APIDomain + '/tim/getMobileUsers';
        const requestUrl =
            `${href}?q=q&sort=${sort}&order=${order}&page=${page + 1}&search=${search}&profile=${profile}`;
        //console.log(requestUrl);
        return this.http.get<MobileUsersResponse>(requestUrl);
    }
}