import { Routes,RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { NgModule } from '@angular/core';
import { PulseIssuesComponent } from './documents/pulseissues/pulseissues.component';
//loadChildren: './dashboard/dashboard.module#DashboardModule'


export const AppRoutes: Routes =
    [
        {
          path: '',
          redirectTo: 'pages',
          pathMatch: 'full',
        },
        {
          path: '',
          component: AdminLayoutComponent,
            children:
                [
                    {
                        path: '',
                        loadChildren: './dashboard/dashboard.module#DashboardModule'
                    }, {
                        path: 'dashboard_accessmanager',
                        loadChildren: './dashboard_accessmanager/dashboard_accessmanager.module#Dashboard_AccessManagerModule'
                    }, /*robin{
                        path: 'map',
                        loadChildren: './map/map.module#MapModule'
                    },*/ {
                        path: 'users',
                        loadChildren: './users/users.module#UsersModule'
                    }, {
                        path: 'rostermanager',
                        loadChildren: './rostermanager/rostermanager.module#RosterManagerModule'
                    }, {
                        path: 'payroll',
                        loadChildren: './payroll/payroll.module#PayrollModule'
                    }, {
                        path: 'hr_reports',
                        loadChildren: './hr_reports/hr_reports.module#HR_ReportsModule'
                    }, {
                        path: 'onboarding',
                        loadChildren: './hr_reports/hr_reports.module#HR_ReportsModule'
                    }, {
                        path: 'accounts',
                        loadChildren: './accounts/accounts.module#AccountsModule'
                    }, {
                        path: 'documents',
                        loadChildren: './documents/documents.module#DocumentsModule'
                    }, {
                        path: 'master',
                        loadChildren: './master/master.module#MasterModule'
                    }, {
                        path: 'mis',
                        loadChildren: './mis/mis.module#MISModule'
                    }, {
                        path: 'fleet',
                        loadChildren: './fleet/fleet.module#FleetModule'
                    }, {
                        path: 'fleetreports',
                        loadChildren: './fleetreports/fleetreports.module#FleetReportsModule'
                    }, {
                        path: 'logistics',
                        loadChildren: './logistics/logistics.module#LogisticsModule'
                    }, {
                        path: 'components',
                        loadChildren: './components/components.module#ComponentsModule'
                    }, {
                        path: 'forms',
                        loadChildren: './forms/forms.module#Forms'
                    }, {
                        path: 'tables',
                        loadChildren: './tables/tables.module#TablesModule'
                    }, {
                        path: 'maps',
                        loadChildren: './maps/maps.module#MapsModule'
                    }, {
                        path: 'downloads',
                        loadChildren: './downloads/downloads.module#DownloadsModule'
                    }, {
                        path: 'widgets',
                        loadChildren: './widgets/widgets.module#WidgetsModule'
                    }, {
                        path: 'charts',
                        loadChildren: './charts/charts.module#ChartsModule'
                    }, {
                        path: 'calendar',
                        loadChildren: './calendar/calendar.module#CalendarModule'
                    }, {
                        path: 'messages',
                        loadChildren: './messages/messages.module#MessagesModule'
                    }, {
                        path: 'leave_ot',
                        loadChildren: './leave_ot/leave_ot.module#Leave_OTModule'
                    }, {
                        path: 'logs',
                        loadChildren: './logs/logs.module#LogsModule'
                    }, {
                        path: '',
                        loadChildren: './userpage/user.module#UserModule'
                    }, {
                        path: '',
                        loadChildren: './timeline/timeline.module#TimelineModule'
                    },
                ]
        }, {
            path: '',
            component: AuthLayoutComponent,
            children: [{
                path: 'pages',
                loadChildren: './pages/pages.module#PagesModule'
            }]
        }
    ];
