import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';


import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCalendar } from '@angular/material/datepicker';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-allowedlist',
    templateUrl: 'allowedlist.component.html',
    styleUrls: ['allowedlist.component.css']
})
export class AllowedListComponent {
    //status: string = "";
    myphoto: any;
    selectedFile: File;
    selected_row: string;
    response: string;
    staffTypes: string[] = ["Trainee", "OnRole"];
    lastAction: string = "";
    public postResponse: PostResponse;
    //selected_row_email: string;
    bIsEdit: boolean = false;
    public allowedListResponse: AllowedListResponse;
    selectedgrp: string = "All";
    updatestatus: string = "";
    bViewSelected: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<AllowedListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public http: Http,
         
        public global: GlobalProvider) {
        this.postResponse = {} as PostResponse;
        this.allowedListResponse = {} as AllowedListResponse;

        //-------------------------------
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + this.data.staffItem.m_Email + "&dummy=" + this.global.iImageRandamize;
    }
    ngOnInit() {
        this.bIsEdit = this.IsEdit();
        this.GetAllowList(this.data.staffItem.m_Email, "", "");
    }
    IsEdit() {
        if (this.IsSA()) return true;
        var menukey = getCookie('menukey');
        //if (menukey.indexOf("a0-1") > -1) return true;
        if (menukey.indexOf("s3-2") > -1) return true;
        return false;
    }

    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }
    IsSAemail(mail) {
        return getCookie('login_profile') === mail;
    }

    onNoClick(dataOut: DialogData): void {
        dataOut.pop_Head = this.lastAction;
        this.dialogRef.close(dataOut);
    }
    onYesClick(dataIn: DialogData): void {
        if (dataIn.pop_Head === 'Shift') {
            if (!this.IsValidTime(dataIn.pop_StartTime)) {
                this.response = "Invalid Start Time (HH:SS)";
                return;
            }
            if (!this.IsValidTime(dataIn.pop_EndTime)) {
                this.response = "Invalid End Time (HH:SS)";
                return;
            }
        }
        this.dialogRef.close(dataIn);
    }
    IsValidTime(timeString: string): boolean {
        //var pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;   // HH:MM:SS
        var pattern = /^([0-9]|[012][0-9]):[0-5][0-9]$/;   // HH:MM:SS
        if (!timeString.match(pattern)) return false;
        return true;
    }
    ngOnDestroy() {
        if (this.bViewSelected) this.data.staffItem.m_ViewSelected = 1;
        else this.data.staffItem.m_ViewSelected = 0;
    }

    OnGrpChange(grp) {
        this.GetAllowList(this.data.staffItem.m_Email, grp, "");
    }

    ChkSelectAll(email) {
        //alert((<HTMLInputElement>document.getElementById("MyCheck")).checked)
        this.GetAllowList(
            this.data.staffItem.m_Email,
            this.selectedgrp,
            (<HTMLInputElement>document.getElementById("MyCheck" + email)).checked ? "1" : "0"
        );
    }
    GetAllowList(email, grp, update: string) {
        this.response = "Please wait...";
        const params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('email', email);
        params.append('group', grp);
        params.append('update', update);
        //alert(params);
        this.http.post("http://" + APIDomain + "/Tim/GetAllowedList", params
        ).subscribe(result => {
            this.allowedListResponse = result.json() as AllowedListResponse;
            this.bViewSelected = this.data.staffItem.m_ViewSelected ? true : false;
            this.response = this.allowedListResponse.result;
            if (this.allowedListResponse.status) {
                
            } else {
                //alert("Failed-" + this.allowedListResponse.result);
            }
            }, error => {
            console.error(error);
            this.response = error;
            //alert("FAILED-" + error);
        }
        );
    }
    ViewSelected(email) {
        this.http.post("http://" + APIDomain + "/Tim/ViewSelected",
            {
                profile: getCookie('login_profile'),
                email: email,
                check: this.bViewSelected ? 1 : 0
            }
        ).subscribe(result => {
            var response = result.json() as PostResponse;
            this.response = response.result;
        }, error => {
            console.error(error);
            this.response = error;
            alert("FAILED-" + error);
        }
        );
    }
    ChkChange(email: string, imei: string) {
        //alert((<HTMLInputElement>document.getElementById("MyCheck" + imei)).checked)
        this.updatestatus = "Please wait...";
        const params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('email', 'this.staffItem.m_Email');
        params.append('group', this.selectedgrp);
        params.append('imei', imei);
        params.append('state', (<HTMLInputElement>document.getElementById("MyCheck" + email + imei)).checked ? "1" : "0");
        //alert(params);
        this.http.post("http://" + APIDomain + "/Tim/UpdateAllowedList", params
        ).subscribe(result => {
            this.postResponse = result.json() as PostResponse;
            if (this.postResponse.status) {
                this.updatestatus = "Updated";
            } else {
                this.updatestatus = this.postResponse.result;
            }
        }, error => {
            console.error(error);
            this.updatestatus = error;
            //this.response = error;
            //alert("FAILED-" + error);
        }
        );

    }
}
export interface DialogData {
    pop_Head: string;
    pop_Roster: string;
    pop_InputName: string;
    pop_PlaceHolder: string;
    pop_StartTime: string;
    pop_EndTime: string;
    staffItem: StaffItem;
}
export interface AllowedListResponse {
    status: boolean;
    result: string;
    groups: string[];
    rows: ListRow[];
}
export interface ListRow {
    imei: string;
    regno: string;
    group: string;
    check: boolean;
}
export interface GetStaffsResponse {
    status: boolean;
    result: string;
    total_count: number;
    items: StaffItem[];
}

export interface StaffItem {
    m_id: number;
    m_StaffID: string;
    m_FName: string;
    m_Activity: string;
    m_ActivityTime: string;
    m_IP: string;
    m_Lat: number;
    m_Lng: number;
    m_WorkTime: string;
    m_Username: string;
    m_ReasonHead: string;
    m_ReasonNote: string;
    m_Email: string;
    m_Mobile: string;
    m_Designation: string;
    m_Roll: string;
    m_Team: string;
    m_Base: string;
    m_Type: string;
    m_ReportToFunctional: string;
    m_ReportToAdministrative: string;
    m_MenuKey: string;
    m_Band: string;
    m_Grade: string;
    m_Mrs: string;
    m_ViewSelected: number;
    m_PayscaleName: string;
    m_PayscaleKey: number;
    m_PayscaleStartDate: number;
}

export interface PostResponse {
    status: boolean;
    result: string;
}
