// IMPORTANT: this is a plugin which requires jQuery for initialisation and data manipulation

import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import PerfectScrollbar from 'perfect-scrollbar';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { GlobalProvider, APIDomain, HubObject } from '../../providers/global/global';
import { Http, URLSearchParams, RequestOptions, Headers, ResponseContentType } from '@angular/http';
import * as moment from 'moment'
import { DomSanitizer } from '@angular/platform-browser'
import { SignalrService } from '../../signalr.service';

declare const $: any;
const C_NONE = 0;
const C_PENDING = 1;
const C_REJECTED = 2;
const C_ACCEPTED = 4;
const C_ReqAPPROVAL = 5;
const C_REVOKE_PENDING = 7;
const C_APPROVED = 9;

@Component({
    selector: 'app-calendar-cmp',
    templateUrl: 'leaves.component.html',
    styleUrls: ['leaves.component.css']
})

//  https://fullcalendar.io/docs/timeFormat
export class LeavesComponent implements OnInit {
    private _signalrHubObjectObservable = null;
    private _searchObservable = null;
    public loadMessagesResponse: LoadMessagesResponse;
    public loadChatResponse: LoadChatResponse;
    listItems: number[] = [];
    myphoto: string = "";
    status: string = "";
    //newMessage: string = "";
    selectedItem: ListItem = null;
    selectedName: string = "";
    ReqMins: number = 0;
    page: number = 1;
    pagesize: number = 8;
    selectedEmailFrom: string = null;
    selectedEmailTo: string = null;
    selectedSession: string = "";
    selectedIsAdmin: number = 0;
    //selectedStatus: number = 0;
    selectedOTStatus: number = C_NONE;
    selectedLeaveStatus: number = C_NONE;
    selectedStaffID: string = "";
    chkApprove: boolean = false;
    chkAccept: boolean = false;
    chkReject: boolean = false;
    chkRevoke: boolean = false;
    chkShowAll: boolean = false;
    adminView: boolean = false;
    ShowSearchBar: boolean = false;
    staffShiftsResponse: StaffShiftsResponse;
    TodayMorning: number = 0;
    bFirstRun: boolean = false;
    
    constructor(
         public http: Http,
        private sanitizer: DomSanitizer,
        private _signalrService: SignalrService,
        private global: GlobalProvider) {
        this.listItems = new Array(6);
        this.loadMessagesResponse = {} as LoadMessagesResponse;
        this.loadChatResponse = {} as LoadChatResponse;
        //this.loadMessagesResponse.listItems = { ["IsAdmin": 1 ] };// as ListItem[];
        this.loadMessagesResponse.listItems = [] as Array<ListItem>;
        this.staffShiftsResponse = {} as StaffShiftsResponse;
    }
    ngOnInit() {
        this.global.messageLeavesCount = 0;
        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + getCookie('login_email') + "&dummy=" + Date.now().toString();
        //--------------------
        this._signalrHubObjectObservable = this._signalrService.hubObjectChangedObservable
            .subscribe((obj: HubObject) => {
                if (obj.Mode === "leaves") {
                    this.global.messageLeavesCount = 0;
                    this.LoadMessages('', '');
                }
            });
        //--------------------
        this._searchObservable = this.global.currentMessage.subscribe(search => {
            if (search != null) {
                if (this.bFirstRun) {
                    this.LoadMessages('', '');
                }
            }
        });
        //--------------------
        var start = new Date();
        start.setHours(0, 0, 0, 0);
        this.TodayMorning = (start.getTime() / 1000) + 19800;
        this.LoadMessages('','');
    }
    IsToday(unix) {
        return (unix >= this.TodayMorning)
    }
    GetPhotoE(email) {
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + email + "&dummy=";// + Date.now().toString();
    }
    GetPhoto(email) {
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            email + "&dummy=";// + Date.now().toString();
        /*
        return "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx?staffid=" +
            getCookie('login_profile') + "_" + email + "&dummy=";// + Date.now().toString();
            */
    }
    transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    SetStatus(mess) {
        this.status = mess;
        setTimeout(() => { this.status = ""; }, 5000);
    }
    OnLeft() {
        if (this.page > 1) this.page--;
        this.LoadMessages('', '');
    }
    OnRight() {
        this.page++;
        this.LoadMessages('', '');
    }
    LoadMessages(mode,mess) {
        let params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('email', getCookie('login_email'));
        params.append('mode', mode);
        params.append('mess', mess);
        params.append('page', "" + (this.page - 1));
        params.append('pagesize', "" + this.pagesize);
        params.append('selectedemailfrom', this.selectedEmailFrom);
        params.append('selectedemailto', this.selectedEmailTo);
        params.append('session', this.selectedSession);
        params.append('leavestatus', this.selectedLeaveStatus+"");
        params.append('showall', this.chkShowAll + "");
        params.append('mins', this.ReqMins + "");
        params.append('adminview', this.adminView ? this.global.StaffSelected : '');
        params.append('search', this.global.globalSearch);

        this.status="Please wait...";
        this.http.post("http://" + APIDomain + "/accessmanager/LoadMessagesLeaves", params
        ).subscribe(
            result => {
                this.loadMessagesResponse = result.json() as LoadMessagesResponse;
                this.SetStatus(this.loadMessagesResponse.result);
                if (this.loadMessagesResponse.listItems.length === 0) {
                    this.loadMessagesResponse.messages = null;
                }
                if (this.loadMessagesResponse.status) {
                    this.chkAccept = false;
                    this.chkApprove = false;
                    this.chkReject = false;
                    this.chkRevoke = false;
                    if (this.loadMessagesResponse.selectedOTStatus > C_NONE) {
                        this.selectedOTStatus = this.loadMessagesResponse.selectedOTStatus;
                        /*
                        this.chkApprove = (this.loadMessagesResponse.selectedOTStatus === C_ACCEPTED);
                        this.chkReject = (this.loadMessagesResponse.selectedOTStatus === C_PENDING ||
                            this.loadMessagesResponse.selectedOTStatus === C_ACCEPTED);
                        this.chkAccept = (this.loadMessagesResponse.selectedOTStatus === C_PENDING);
                        */
                    } else {
                        this.chkApprove = (this.loadMessagesResponse.selectedLeaveStatus === C_APPROVED);
                        this.chkReject = (this.loadMessagesResponse.selectedLeaveStatus === C_REJECTED);
                        this.chkRevoke = (this.loadMessagesResponse.selectedLeaveStatus === C_APPROVED);
                    }
                }
                document.getElementById("NewMessage").focus();
                $("#NewMessage").val("");
            }, error => {
                this.SetStatus("Err2-" + error);
            }
        );
    }
    LoadChat(mode,session,mess) {
        let params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('email', getCookie('login_email'));
        params.append('mode', mode);
        params.append('mess', mess);
        params.append('leavestatus', this.selectedLeaveStatus + "");
        params.append('selectedemailfrom', this.selectedEmailFrom);
        params.append('selectedemailto', this.selectedEmailTo);
        params.append('session', session);
        params.append('mins', this.ReqMins + "");
        params.append('adminview', this.adminView ? this.global.StaffSelected : '');
        params.append('reqfrom', "leave");
        //console.log("http://" + APIDomain + "/accessmanager/LoadChat" + params);
        this.status = "Please wait...";
        this.http.post("http://" + APIDomain + "/accessmanager/LoadChat", params
        ).subscribe(
            result => {
                this.loadChatResponse = result.json() as LoadChatResponse;
                this.SetStatus(this.loadChatResponse.result);

                document.getElementById("NewMessage").focus();
                $("#NewMessage").val("");
                if (this.loadChatResponse.bReload)
                    this.LoadMessages('', '');
            }, error => {
                this.SetStatus("Err2-" + error);
            }
        );
    }
    SendButton() {
        if($("#NewMessage").val().length===0){
            this.SetStatus("Please enter your message");
            return;
        }
        this.LoadChat("newmessage", this.selectedSession, $("#NewMessage").val());
        $("#NewMessage").val("");
    }
    ClickOnItem(listItem: ListItem, idx) {
        this.status = "Please wait...";
        this.chkApprove = false;
        this.chkAccept = false;
        this.chkReject = false;
        this.chkRevoke = false;
        this.selectedItem = listItem;
        this.ReqMins = Math.round(this.selectedItem.mins / 60);
        this.selectedEmailFrom = listItem.EmailFrom;
        this.selectedEmailTo = listItem.EmailTo;
        this.selectedSession = listItem.session;
        this.selectedIsAdmin = listItem.IsAdmin;
        this.selectedLeaveStatus = listItem.leavestatus;
        
        this.selectedOTStatus = listItem.otStatus;
        this.selectedStaffID = listItem.staffidFrom;
        //this.LoadMessages('', '');

        this.loadMessagesResponse.listItems[idx].counts = 0;
        this.LoadChat('', listItem.session, '');

    }
    onKeydown(event) {
        if (event.keyCode == 13) {
            this.SendButton();
        }
    }
    OnApprove(x) {
        this.LoadChat("approve", this.selectedSession,'');
    }
    OnAccept(x) {
        this.LoadChat("accept", this.selectedSession, '');
    }
    OnReject(x) {
        this.LoadChat("reject", this.selectedSession, '');
    }
    OnRevoke(x) {
        this.LoadChat("revoke", this.selectedSession, '');
    }
    OnShowAll(x) {
        this.LoadMessages("", "");
    }
    GetOTStyle(listItem: ListItem) {
        return "material-icons OT_" + listItem.otStatus;
    }
    /*
     chkReject
     chkApprove
     chkAccept
     1-Pending
     2-Rejected
     4-Accepted
     9-Approved
     */
    /* With ACCEPT blocked after kamal
    ShowCheck(type) {
        if (type === "accept") {
            if (this.selectedOTStatus === C_PENDING) return true;
        } else if (type === "reject") {
            if (this.selectedOTStatus === C_PENDING) return true;
            if (this.selectedOTStatus === C_ACCEPTED) return true;
            if (this.selectedOTStatus === C_ReqAPPROVAL) return true;

            if (this.selectedLeaveStatus === C_PENDING) return true;
        } else if (type === "approve") {
            if (this.selectedOTStatus === C_ACCEPTED) return true;
            if (this.selectedOTStatus === C_ReqAPPROVAL) return true;
            if (this.selectedLeaveStatus === C_PENDING) return true;
        } else if (type === "revoke") {
            if (this.selectedLeaveStatus === C_APPROVED) return true;
        }
        return false;
    }
    */
    ShowCheck(type) {
        /*if (type === "accept") {
            if (this.selectedOTStatus === C_PENDING) return true;
        } else */if (type === "reject") {
            if (this.selectedOTStatus === C_PENDING) return true;
            if (this.selectedOTStatus === C_ACCEPTED) return true;
            if (this.selectedOTStatus === C_ReqAPPROVAL) return true;

            if (this.selectedLeaveStatus === C_PENDING) return true;
        } else if (type === "approve") {
            if (this.selectedOTStatus === C_ACCEPTED) return true;
            if (this.selectedOTStatus === C_ReqAPPROVAL) return true;
            if (this.selectedOTStatus === C_PENDING) return true;

            if (this.selectedLeaveStatus === C_PENDING) return true;
        } else if (type === "revoke") {
            if (this.selectedOTStatus === C_REVOKE_PENDING) return true;
            //if (this.selectedLeaveStatus === C_APPROVED) return true;
        }
        return false;
    }
    ObjColor(stat) {
        if (stat === C_PENDING) return { 'background-color': 'orange', 'color': 'white' };
        if (stat === C_REJECTED) return { 'background-color': '#c33', 'color': '#d4d4d4' };
        if (stat === C_ACCEPTED) return { 'background-color': '#33f', 'color': '#d4d4d4' };
        if (stat === C_APPROVED) return { 'background-color': 'darkgreen', 'color': 'white' };
    }
    ngAfterViewInit() {
        this.global.globalSearch = "";
        this.bFirstRun = true;
    }
    ngOnDestroy() {
        if (this._signalrHubObjectObservable != null) this._signalrHubObjectObservable.unsubscribe();
        if (this._searchObservable != null) this._searchObservable.unsubscribe();
    }
    GetMin(sec) {
        return Math.round(sec / 60);
    }
    IsAdmin() {
        return getCookie("menukey").indexOf("a0-1") > -1;
    }
    onSearchChange(searchValue: string) {
        console.log(searchValue);
        //this.searchValue = searchValue;
        //this.subject.next();
        this.ShowSearchBar = true;
        this.GetLastShiftsOfStaff(searchValue);
    }
    GetLastShiftsOfStaff(staffid: string) {
        this.status = "Please wait...";
        const params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        params.append('staffid', staffid);
        this.http.post("http://" + APIDomain + "/accessmanager/StaffSearch", params
        ).subscribe(result => {
            this.status = "";
            this.staffShiftsResponse = result.json() as StaffShiftsResponse;

            if (this.staffShiftsResponse.status) {
                //this.shift_vs_staffs = postResponse.result;
            } else {
                alert("Status failed");
            }
        }, error => {
            this.status = "";
            alert("Error");
        }
        );
    }
    OnSelect(row: StaffShiftsRow) {
        this.global.StaffSelected = row.id;
        this.selectedName = row.name;
        this.ShowSearchBar = false;
        this.chkShowAll = true;
        this.LoadMessages('', '');
    }
    ClrAdmin() {
        this.adminView = false;
        this.global.StaffSelected = '';
        this.selectedName = '';
        this.LoadMessages('', '');
    }
    ToggleAdmin() {
        this.adminView = !this.adminView;
        if (!this.adminView) this.ClrAdmin();
    }
}
interface LoadChatResponse {
    status: boolean;
    result: string;
    bReload: boolean;
    selectedSession: string;
    SenderDetails: string;
    messages: Message[];
}
interface LoadMessagesResponse {
    status: boolean;
    result: string;
    selectedEmailFrom: string;
    selectedLeaveStatus: number;
    selectedOTStatus: number;
    selectedSession: string;
    SenderDetails: string;
    listItems: ListItem[];
    messages: Message[];
}
interface ListItem {
    IsAdmin: number;
    NameFrom: string;
    NameTo: string;
    staffidFrom: string;
    staffidTo: string;
    tmCreated: string;
    tmUpdated: number;
    EmailFrom: string;
    EmailTo: string;
    session: string;
    leavestatus: number;
    counts: number;
    time: string;
    otStatus: number;
    mins: number;
    param1: string;
    param2: string;
    param3: string;
}
interface Message {
    MySelf: number;
    sMessage: string;
    sTime: string;
    image: string;
}
interface StaffShiftsResponse {
    status: boolean;
    result: string;
    staffID: string;
    rows: Array<StaffShiftsRow>;
}
interface StaffShiftsRow {
    id: string;
    name: string;
    /*
    roster: string;
    shift: string;
    day5: string;
    lShiftStart: number;
    lShiftEnd: number;
    email: string;
    */
}