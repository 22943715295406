import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Http, URLSearchParams, Headers, RequestOptions, ResponseContentType } from '@angular/http';

import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj, Head_Name } from '../../providers/global/global';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatCalendar } from '@angular/material/datepicker';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { merge, Observable, of as observableOf, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser'
import { Payscale_MasterComponent } from '../payscale_master/payscale_master.component';

@Component({
    selector: 'app-payscale',
    templateUrl: 'payscale.component.html',
    styleUrls: ['payscale.component.css']
})
export class PayscaleComponent {
    //status: string = "";
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    displayedColumns: string[] = ['m_Name', 'm_Key','m_CreatedBy','m_CreatedTime','m_UpdatedBy','m_UpdatedTime', 'm_Delete', 'm_Edit'];
    payscaleItem: PayscaleItem;
    dataRows: PayscaleItem[] = [];
    exampleDatabase: ExampleHttpDao | null;
    private _searchObservable = null;
    myphoto: any;
    leaveEditChk: boolean = false;
    selectedFile: File;
    selected_row: string;
    response: string="";
    staffTypes: string[] = ["Trainee", "OnRole"];
    lastAction: string = "";
    resultsLength: string = "";
    public postResponse: PostResponse;
    //selected_row_email: string;
    bIsEdit: boolean = false;
    public payscalesResponse: PayscalesResponse;
    globalSearch: string = "";
    bFirstRun: boolean = false;
    showoptions: string=""; 
    selectedgrp: string = "All";
    updatestatus: string = "";
    bViewSelected: boolean = false;
    leaveDays: string = "";
    leaveDescription: string = "";

    constructor(
        public dialogRef: MatDialogRef<PayscaleComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public http: Http, private sanitizer: DomSanitizer,
        private httpClient: HttpClient,
        public dialog: MatDialog,
         
        public global: GlobalProvider) {
            this.postResponse = {} as PostResponse;
            this.payscalesResponse = {} as PayscalesResponse;
            //this.getLeaveHistoryResponse = {} as GetLeaveHistoryResponse;
            this.bIsEdit = this.IsEdit();
            //-------------------------------
        this.payscaleItem = {} as any;

    }
    ngOnInit() {
        this._searchObservable = this.global.currentMessage.subscribe(search => {
            if (search != null) {
                if (this.bFirstRun) {
                    this.showoptions = "3";
                    this.globalSearch = search;
                    this.paginator.pageIndex = 0
                    this.Merge("", "");
                }
            }
        });
        //__________________________________Material

        this.exampleDatabase = new ExampleHttpDao(this.httpClient);
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        //__________________________________Material END
        this.Merge("", "");
        //this.LoadLeaveData();
    }
    ngOnDestroy() {
        //if (this._signalrObservable != null) this._signalrObservable.unsubscribe();
        if (this._searchObservable != null) this._searchObservable.unsubscribe();
    }
    ngAfterViewInit() {
        this.global.globalSearch = "";

        this.Merge("", "");
        this.bFirstRun = true;
    }
    transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    IsEdit() {
        if (this.IsSA()) return true;
        var menukey = getCookie('menukey');
        //if (menukey.indexOf("a0-1") > -1) return true;
        if (menukey.indexOf("p1-2") > -1) return true;
        return false;
    }

    IsSA() {
        return getCookie('login_profile') === getCookie('login_email');
    }
    IsSAemail(mail) {
        return getCookie('login_profile') === mail;
    }

    LoadLeaveData() {
        this.response = "Please wait...";
        const today = new Date();
        var iYear = today.getFullYear();
        var iMonth = today.getMonth() + 1;
        var iDay = today.getDate();

        var timeZoneOffset = (new Date().getTimezoneOffset() * 60).toString();

        let params = new URLSearchParams();
        params.append('timezone', timeZoneOffset);
        params.append('profile', getCookie('login_profile'));
        params.append('year', iYear + "");
        params.append('month', iMonth + "");
        //params.append('staffid', this.data.staffItem.m_StaffID);

        this.http.post("http://" + APIDomain + "/accessmanager/LoadLeaveData", params
        ).subscribe(
            result => {
                this.response = "";
                //this.loadLeaveDataResponse = result.json() as LoadLeaveDataResponse;
                
            }, error => {
                this.response = error;
            }
        );
    }
    OnLeaveEdit(x) {
    }
    SelectedLeave: string = "";
    GetLeaveHistory(key: string) {
        this.SelectedLeave = key;
        const today = new Date();
        var iYear = today.getFullYear();
        const params = new URLSearchParams();
        params.append('profile', getCookie('login_profile'));
        //params.append('staffid', this.data.staffItem.m_StaffID);
        params.append('year', iYear + "");
        params.append('key', key + "");

        this.http.post("http://" + APIDomain + "/accessmanager/GetLeaveHistory", params
        ).subscribe(result => {
            //this.getLeaveHistoryResponse = result.json() as GetLeaveHistoryResponse;

            //if (this.onLoadShiftDetailsResponse.status) {
            //} else {
            //}
        }, error => {

            //alert("GetShiftActivityDetails Error-" + this.shiftActivityDetailsResponse.result);
        }
        );
    }

    onNoClick(dataOut: DialogData): void {
        dataOut.response = this.lastAction;
        this.dialogRef.close(dataOut);
    }
    onYesClick(dataIn: DialogData): void {
        if (dataIn.response === 'Shift') {
            
        }
        this.dialogRef.close(dataIn);
    }
    IsValidTime(timeString: string): boolean {
        //var pattern = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/;   // HH:MM:SS
        var pattern = /^([0-9]|[012][0-9]):[0-5][0-9]$/;   // HH:MM:SS
        if (!timeString.match(pattern)) return false;
        return true;
    }

    Update_LeaveAccounts(mode) {
        this.response = "Please wait...";
        const params = new URLSearchParams();
        params.append('mode', mode);
        params.append('profile', getCookie('login_profile'));
        params.append('email', getCookie('login_email'));
        params.append('description', this.leaveDescription);
        params.append('days', this.leaveDays);
        params.append('type', this.SelectedLeave);
        //params.append('staffid', this.data.staffItem.m_StaffID);

        this.http.post("http://" + APIDomain + "/accessmanager/Update_LeaveAccounts", params
        ).subscribe(result => {
            this.postResponse = result.json() as PostResponse;
            if (this.postResponse.status) {
                this.LoadLeaveData();
                this.GetLeaveHistory(this.SelectedLeave);
                this.response = this.postResponse.result;
                this.leaveDescription = "";
                this.leaveDays = "";
            } else {
                this.response = this.postResponse.result;
            }
        }, error => {
            this.response = "Failed: " + this.postResponse.result;
        }
        );
    }
    merge_mode: string = "";
    merge_value: string = "";
    Merge(mode, value) {
        this.response = "Please wait...";
        if (mode === "delete") this.lastAction = "reload";
        this.merge_mode = mode;
        this.merge_value = value;
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
            switchMap(() => {
                var mode_ = this.merge_mode;
                var value_ = this.merge_value;
                this.merge_mode = "";
                this.merge_value = "";

                    return this.exampleDatabase!.getRepoIssues(
                        this.sort.active, this.sort.direction, this.paginator.pageIndex,
                        this.globalSearch, getCookie('login_profile'),
                        getCookie('login_staffid'),
                        this.showoptions, mode_, value_,
                        this.data.payscaleItem.m_Name, this.data.payscaleItem.m_Key
                    );
                }),
                map((data: PayscalesResponse) => {
                    // Flip flag to show that loading has finished.
                    this.resultsLength = data.total_count;
                    this.response = data.result;
                    
                    if (data.reload) {
                        setTimeout(() => {    //<<<---    using ()=> syntax
                            this.Merge('', '');
                        }, 500);
                    }
                    
                    return data.items;
                }),
                catchError((err) => {
                    alert(err.message)
                    // Catch if the GitHub API has reached its rate limit. Return empty data.
                    return observableOf([]);
                })
        ).subscribe((data: PayscaleItem[]) => this.dataRows = data);
    }
    PaySlipMaster(row: PayscaleItem) {
        const dialogRef = this.dialog.open(Payscale_MasterComponent, {
            minWidth: '50em',
            width: '90%',
            data: {
                pop_Head: "", pop_InputName: '', pop_PlaceHolder: "",
                pop_StartTime: '', pop_EndTime: '', pop_Roster: "",
                name: row.m_Name, key: row.m_Key, startdate: "",
                allowdelete: row.allowdelete
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.Merge('', '');
            if (result === null) {
                //this.onCreateRosterResponse.result = "";
            } else if (typeof result === "undefined") {
                //this.onCreateRosterResponse.result = "";
            } else {
                if (result.pop_Head === "delete") {

                }
            }
        });

    }
}
export class ExampleHttpDao {
    constructor(private http: HttpClient) { }

    getRepoIssues(sort: string, order: string, page: number, search: string,
        profile: string, staffid: string, showoptions: string, mode: string, value: string, payscaleName: string, key: number): Observable<PayscalesResponse> {
        const href = "http://" + APIDomain + '/accessmanager/GetPayscaleKeys';
        const requestUrl =
            `${href}?q=q&sort=${sort}&order=${order}&page=${page + 1}&search=${search}&profile=${profile}&showoptions=${showoptions}&mode=${mode}&value=${value}&payscalename=${payscaleName}&key=${key}&staffid=${staffid}`;
        //console.log(requestUrl);
        return this.http.get<PayscalesResponse>(requestUrl);
    }
}

export interface DialogData {
    payscaleItem: PayscaleItem;
    response: string;
}
export interface AllowedListResponse {
    status: boolean;
    result: string;
    groups: string[];
    rows: ListRow[];
}
export interface ListRow {
    imei: string;
    regno: string;
    group: string;
    check: boolean;
}
export interface PayscalesResponse {
    status: boolean;
    result: string;
    total_count: string;
    reload : boolean;
    items: PayscaleItem;
}

export interface PayscaleItem {
    m_id: number;
    m_Name: string;
    m_Key: number;
    m_CreatedBy: string;
    m_CreatedTime: number;
    allowdelete: number;
    m_UpdatedBy: string;
    m_UpdatedTime: number;
}

export interface PostResponse {
    status: boolean;
    result: string;
}

interface LeaveItem {
    Code: string;
    Status: number;
}
