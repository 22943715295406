import { Routes } from '@angular/router';

import { LeavesComponent } from './leaves/leaves.component';
import { LateLoginsComponent } from './latelogins/latelogins.component';
import { MessageComponent } from './message/message.component';
import { TimesComponent } from './times/times.component';
export const MessagesRoutes: Routes = [
    {

      path: '',
      children: [ {
          path: 'leaves',
          component: LeavesComponent
      }, {
              path: 'times',
              component: TimesComponent
          }, {
              path: 'latelogins',
              component: LateLoginsComponent
          }]
}
];
