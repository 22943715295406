import { Component, OnInit,ViewChild } from '@angular/core';
import { Http, URLSearchParams, RequestOptions } from '@angular/http';

import { MatCalendar } from '@angular/material/datepicker';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { set as setCookie, get as getCookie } from 'es-cookie';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { GlobalProvider, APIDomain, SubObj } from '../../providers/global/global';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';


import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import {
    DateAdapter, MAT_DATE_FORMATS, MatDateFormats, ThemePalette, MAT_DATE_LOCALE
} from '@angular/material/core';
@Component({
    selector: 'app-terminalActivities',
    templateUrl: './loginactivities.component.html',
    styleUrls: ['./loginactivities.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    providers: [
        // The locale would typically be provided on the root module of your application. We do it at
        // the component level here, due to limitations of our example generation script.
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

        // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
        // `MatMomentDateModule` in your applications root module. We provide it at the component level
        // here, due to limitations of our example generation script.
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class LoginActivitiesComponent implements OnInit {
    //public onProfileResponse: OnProfileResponse;
    selectedItem: string;
    m_Name: string;
    selectedFile: File;
    myphoto: any;
    m_DtFrom: Date;
    m_DtTo: Date;
    //___________________________Material
    status: string;
    //private _signalrObservable = null;
    private _searchObservable = null;
    timeZoneOffset: string;

    response: string;
    selected_m_ID: number;

    displayedColumns: string[] = ['m_User', 'm_Name', 'm_StaffID', 'm_Time', 'm_Activity', 'm_Status', 'm_IP','m_Browser'];
    exampleDatabase: ExampleHttpDao | null;
    data: LoginActivityRow[] = [];

    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    iImageRandamize: number = 1000;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    loginActivityRow = {} as LoginActivityRow;
    public postResponse: PostResponse;
    globalSearch: string="";
    bFirstRun: boolean = false;
    //___________________________Material END
    constructor(public http: Http, private router: Router, private httpClient: HttpClient,
        private sanitizer: DomSanitizer,
         public global: GlobalProvider) {
        
        if (getCookie("login_profile").length === 0) {
            this.router.navigateByUrl('/pages/login');
            return;
        }

        this.myphoto = "http://" + APIDomain + "/handlers/GetDvrPhoto.ashx";
    }
    ngAfterViewInit() {
        this.global.globalSearch = "";
        this.Merge();
        this.bFirstRun = true;
    }
    ngOnInit() {
        //this.OnListClk("");
        this._searchObservable = this.global.currentMessage.subscribe(search => {
            if (search != null) {
                if (this.bFirstRun) {
                    this.paginator.pageIndex = 0
                    this.globalSearch = search;
                    this.Merge();
                }
            }
        });

        //_________________________________
        this.status = "";
        //__________________________________Material
        this.exampleDatabase = new ExampleHttpDao(this.httpClient);
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        //__________________________________Material END
    }
    //
    public getSantizeUrl(lat: string, lng: string) {
        var url = "https://www.google.com/maps/embed/v1/view?key=AIzaSyCqIDGJZXbP6ICWEQn69JA0EeuA1E0POzI&center=" + lat + "," + lng + "&zoom=14&maptype=roadmap";
        //var url = "http://maps.google.com/maps?q=" + lat + "," + lng + "&z=15&output=embed&key=AIzaSyCqIDGJZXbP6ICWEQn69JA0EeuA1E0POzI";
        //return this.sanitizer.bypassSecurityTrustUrl(url);
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    Merge() {

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.exampleDatabase!.getRepoIssues(
                        this.sort.active, this.sort.direction, this.paginator.pageIndex,
                        this.globalSearch, getCookie('login_profile'));
                }),
                map(data => {
                    // Flip flag to show that loading has finished.
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data.total_count;

                    return data.items;
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    // Catch if the GitHub API has reached its rate limit. Return empty data.
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.data = data);
    }
    ngOnDestroy() {
        //if (this._signalrObservable != null) this._signalrObservable.unsubscribe();
        if (this._searchObservable != null) this._searchObservable.unsubscribe();
    }

    GetListSelectedColor(item): any {
        if (item === this.selectedItem)
            return { 'background-color': '#ddd', 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'border': '0.5px solid #b4b4b4', 'white-space':'nowrap' };
        else return { 'padding': '3px', 'font-weight': 'bold', 'cursor': 'pointer', 'white-space': 'nowrap'};
    }


    GetAccess() {
        if (getCookie('login_profile') === getCookie('login_email')) return 2;
        var key = getCookie("menukey");
        //if (key.indexOf("a0-1") > -1) return 2;
        if (key.indexOf("s5-1") > -1) return 1;
        if (key.indexOf("s5-2") > -1) return 2;
        return 0;
    }
}

export interface  LoginActivitiesResponse {
    items: LoginActivityRow[];
    total_count: number;
}

export interface LoginActivityRow {
    m_id: number;
    m_User: string;
    m_Name: string;
    m_StaffID: string;
    m_Time: string;
    m_Activity: string;
    m_Status: string;
    m_IP: string;
    m_Browser: string;
}
export interface PostResponse {
    status: boolean;
    result: string;
}
/** An example database that the data source uses to retrieve data for the table. */
export class ExampleHttpDao {
    constructor(private http: HttpClient) { }

    getRepoIssues(sort: string, order: string, page: number, search: string, profile: string): Observable<LoginActivitiesResponse> {
        const href = 'http://' + APIDomain + '/accessmanager/GetLoginActivities';
        const requestUrl =
            `${href}?q=q&sort=${sort}&order=${order}&page=${page + 1}&search=${search}&profile=${profile}`;
        //console.log(requestUrl);
        return this.http.get<LoginActivitiesResponse>(requestUrl);
    }
}